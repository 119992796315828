import axios from 'axios';
import React, { useState } from 'react'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { CONTIGO_API_URL } from '../BaseURL';


const ForgetPassword = () => {

    const [email, setEmail] = useState('');
    const [err, setErr] = useState('');
    const [disable, setDisable] = useState(false);
    const nav = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (email) {
            setDisable(true)
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('email', email);
            axios({
                method: 'post',
                url: `${CONTIGO_API_URL}api/nursing-user/forgot-password`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
            }).then((response) => {
                console.log("@@", response?.data);
                if (response.data.success === true) {
                    // setMinutes(1);
                    // setSeconds(59);
                    // setErr('');
                    toast.success(response?.data?.message)
                    setTimeout(() => {
                        nav('/otp-verification', { state: { email: email, type: 'forgot' } })
                    }, 1500)
                    setDisable(false)
                }
            }).catch((err) => {
                console.log(err, "error")
                setErr(err?.response?.data?.message);
                setDisable(false)
                if (err.response.success === 401) {
                    localStorage.removeItem('CONTIGO_TOKEN_BUSINESS')
                    nav('/login')
                }
            })
        } else {
            setErr('Please enter email')
        }
    }

    return (
        <div className='cu-site'>
            <section className="cu-login-section">
                <div className="cu-login-section-content">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-12 col-xl-6">
                            <div className="cu-login-content">
                                <a className="cu-login-top-logo" href="/">
                                    <img src="assets/images/white-logo.png" alt="Logo" className="img-fluid" />
                                </a>
                                <div className="cu-login-content-area">
                                    <h2 className="cu-login-content-title">Patients are Waiting for you Login Now</h2>
                                    <div className="cu-login-logo">
                                        <img src="assets/images/login-logo.png" alt="login-logo" className="img-fluid" />
                                    </div>
                                    <div className="cu-login-left-img">
                                        <img src="assets/images/login-old-img.png" alt="login-old-img" className="img-fluid" />
                                    </div>
                                    <div className="cu-login-right-img">
                                        <img src="assets/images/login-boy-img.png" alt="login-boy-img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-12 col-xl-6">
                            <div className="cu-login-form-area">
                                <div className="cu-login-form-area__inner">
                                    <div className="cu-login-form-heading">
                                        <h1 className="cu-login-title">Forget Password</h1>
                                        <p className="cu-login-description">Enter your email, and we'll send you a link to reset
                                            your password.
                                        </p>
                                    </div>
                                    <div className="cu-login-form">
                                        <form className="row" action="">
                                            <div className="col-12 mb-4">
                                                <label htmlFor="inputEmail" className="form-label">Email</label>
                                                <input type="text" className="form-control" id="inputEmail" name='email' onChange={(e) => setEmail(e.target.value?.trimStart())} />
                                                <span htmlFor="inputEmail" className="error">{err}</span>
                                            </div>
                                            <div className="col-12">
                                                <button type="button" onClick={handleSubmit} className="btn btn-info w-100" disabled={disable}>{disable ? "Processing..." : "Send reset link"}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ForgetPassword