import React from 'react'
import { Modal } from 'react-bootstrap'

const Notification = ({ activeModal, setActiveModal, notifications, handleRead }) => {
    return (
        // <Modal
        //     className='view-notification'
        //     show={activeModal}
        //     onHide={setActiveModal}
        //     keyboard={false}
        // >
        //     <Modal.Header>
        //         <div className='notification-header'>
        //             <h2>Notifications
        //             </h2>
        //             <h2>Mark as read</h2>
        //         </div>
        //     </Modal.Header>
        //     <Modal.Body>
        //         <div className='notification-body'>
        //             <div className='title'>
        //                 <span>PS</span>
        //                 <p><strong>Dennis Jap</strong> Booked an Appointment with you</p>
        //             </div>
        //             <div className='date'>
        //                 <span>16th jan 2023</span>
        //             </div>
        //         </div>
        //     </Modal.Body>
        // </Modal>
        <div className={`cu-notification-modal ${activeModal ? 'active' : ''}`}>
            <div className="cu-notification-modal-head">
                <h3 className="cu-notification-title">Notifications</h3>
                <a style={{ cursor: 'pointer' }} onClick={handleRead} className="cu-notification-mark-link">Mark As Read</a>
            </div>
            {console.log('aa', notifications)}
            <div className="cu-notification-modal-body">
                {notifications?.map(elem =>
                    <div className="cu-notification-list">
                        <div className={`cu-notification-item ${elem?.readed ? 'active' : ''}`}>
                            <div className="cu-notification-content">
                                <div className="cu-notification-profile-img">
                                    <img src="assets/images/no-user.png" alt="Img" className="img-fluid" />
                                </div>
                                <p className="cu-notification-text"><span>{elem?.patient_details?.first_name}</span> {elem?.title}</p>
                            </div>
                            <p className="cu-notification-time">{new Date(elem?.createdAt).toDateString()}</p>
                        </div>
                        {/* <div className="cu-notification-item">
                        <div className="cu-notification-content">
                            <div className="cu-notification-profile-img">
                                <img src="assets/images/notification-img2.png" alt="Img" className="img-fluid" />
                            </div>
                            <p className="cu-notification-text"><span>Tom Holly</span> Booked an Appointment with you</p>
                        </div>
                        <p className="cu-notification-time">16th jan 2023</p>
                    </div>
                    <div className="cu-notification-item">
                        <div className="cu-notification-content">
                            <div className="cu-notification-profile-img">
                                <img src="assets/images/notification-img3.png" alt="Img" className="img-fluid" />
                            </div>
                            <p className="cu-notification-text"><span>Tom Holly</span> Booked an Appointment with you</p>
                        </div>
                        <p className="cu-notification-time">16th jan 2023</p>
                    </div>
                    <div className="cu-notification-item">
                        <div className="cu-notification-content">
                            <div className="cu-notification-profile-img">
                                <img src="assets/images/notification-img4.png" alt="Img" className="img-fluid" />
                            </div>
                            <p className="cu-notification-text"><span>Tom Holly</span> Booked an Appointment with you</p>
                        </div>
                        <p className="cu-notification-time">16th jan 2023</p>
                    </div> */}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Notification