import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Header from './Header'
import Sidebar from './Sidebar'
import axios from 'axios'
import { toast, Toaster } from 'react-hot-toast';
import { CONTIGO_API_URL } from '../BaseURL'
import parse from 'html-react-parser';

const MyProfile = () => {

    const [profile, setProfile] = useState([])
    const [error, setError] = useState({})
    const [update, setUpdate] = useState({ oldPassword: "", newPassword: "", cpassword: "", opwdShow: true, pwdShow: true, cpwdShow: true })
    const [disable, setDisable] = useState(false);

    const nav = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('CONTIGO_TOKEN_BUSINESS') === null) {
            nav('/login')
        }

        getProfile();
    }, [])

    // useEffect(() => {
    //     if (localStorage.getItem('CONTIGO_TOKEN_BUSINESS') === null) {
    //         nav('/login')
    //     }

    //     // getWidgets();
    //     checkSubscription()
    // }, [])

    // const checkSubscription = () => {

    //     axios({
    //         method: 'post',
    //         url: `${CONTIGO_API_URL}api/nursing-user/check-subscription`,
    //         headers: { 'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_BUSINESS')}` }
    //     }).then((response) => {
    //         if (response.data.status === true) {
    //             getProfile();
    //         } else {
    //             nav('/subscription-plans')
    //         }
    //     }).catch((error) => {
    //         toast.error("Something went wrong");
    //         console?.log("Errors", error);
    //         if (error.response.status === 401) {
    //             localStorage.removeItem('CONTIGO_TOKEN_BUSINESS');
    //             nav('/login')
    //         }
    //     });
    // }


    const getProfile = () => {
        axios({
            method: 'get',
            url: `${CONTIGO_API_URL}api/nursing-user/get-profile`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded ', 'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_BUSINESS')}` }
        }).then((response) => {
            console.log("@@", response?.data);
            if (response?.data?.success === true) {
                setProfile(response?.data?.data);
            }
        }).catch((err) => {
            console.log(err);
            toast.error(err?.response?.data?.message || "Something went wrong");
            if (error?.response?.status === 401) {
                localStorage.removeItem('CONTIGO_TOKEN_BUSINESS');
                nav('/login')
            }
        })
    }

    const handleChange = (e) => {
        setUpdate({ ...update, [e.target.name]: e.target.value?.trimStart() })
        console.log(update, "update datat")
    }

    const updatePassoword = () => {
        if (validation()) {
            setDisable(true)
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('old_password', update.oldPassword);
            bodyFormData.append('new_password', update.newPassword);

            axios({
                method: 'post',
                url: `${CONTIGO_API_URL}api/nursing-user/update-password`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded ', 'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_BUSINESS')}` }
            }).then((response) => {
                console.log("@@", response.data);
                if (response.data.success === true) {
                    setTimeout(() => {
                        nav('/dashboard')
                    }, 1500)
                    toast.success(response?.data?.message);
                    setDisable(false);
                }
            }).catch((err) => {
                console.log(err?.response);
                toast.error(err?.response?.data?.message)
                setDisable(false);
            })
        }
    }

    const validation = () => {
        let isValid = true;
        let err = {};

        if (!update.oldPassword) {
            isValid = false;
            err['oldpassword_err'] = "Please enter old password"
        }

        if (!update.newPassword) {
            isValid = false;
            err['newpassword_err'] = "Please enter new password"
        } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}/.test(update.newPassword)) {
            isValid = false;
            err['newpassword_err'] = "Password must be at least 8 characters long, contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character";
        }

        if (!update.cpassword) {
            isValid = false;
            err['cpassword_err'] = "Please enter confirm password"
        }
        if (update.newPassword && update.cpassword) {
            if (update.newPassword !== update.cpassword) {
                isValid = false;
                err['cpassword_err'] = "Password doesn't match";
            }
        }


        setError(err);
        return isValid;
    }

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <div className='cu-site cu-dashboard-site'>
            <Header />
            <main className="cu-dashboard-content">

                <Sidebar />
                <div className="cu-dashboard-content-area" data-scrollbar>
                    <div className="cu-service-content">
                        <div className="cu-dashboard-inner-head">
                            <h1 className="cu-dashboard-head-title">My Profile</h1>
                        </div>
                        <div className="cu-profile-content">
                            <div className="cu-profile-sidebar">
                                <div className="cu-profile-head">
                                    <div className="cu-profile-head-img">
                                        {/* <img src="assets/images/profile-img.png" alt="Profile Image" className="img-fluid" /> */}
                                        <div style={{ display: 'flex', justifyContent: "center" }}>
                                            <span style={{ backgroundColor: '#F6F7F9', color: 'black', borderRadius: '50%', width: '60px', height: '60px', fontSize: '25px', textAlign: 'center', paddingTop: '11px' }}>{profile.first_name?.charAt(0).toUpperCase()}{profile.last_name?.charAt(0).toUpperCase()}</span>
                                        </div>
                                    </div>
                                    <h3 className="cu-profile-head-title">{profile.first_name} {profile.last_name}</h3>
                                </div>
                                <div className="cu-profile-sidebar-menu">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                                                data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                                aria-selected="true">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M15.625 6.66699V8.41699C15.2583 8.36699 14.8417 8.34199 14.375 8.33366V6.66699C14.375 4.04199 13.6333 2.29199 10 2.29199C6.36667 2.29199 5.625 4.04199 5.625 6.66699V8.33366C5.15833 8.34199 4.74167 8.36699 4.375 8.41699V6.66699C4.375 4.25033 4.95833 1.04199 10 1.04199C15.0417 1.04199 15.625 4.25033 15.625 6.66699Z"
                                                        fill="#7D7D7D" />
                                                    <path opacity="0.4"
                                                        d="M18.3337 12.4997V14.1663C18.3337 17.4997 17.5003 18.333 14.167 18.333H5.83366C2.50033 18.333 1.66699 17.4997 1.66699 14.1663V12.4997C1.66699 9.71634 2.25033 8.67467 4.37533 8.41634C4.74199 8.36634 5.15866 8.34134 5.62533 8.33301H14.3753C14.842 8.34134 15.2587 8.36634 15.6253 8.41634C17.7503 8.67467 18.3337 9.71634 18.3337 12.4997Z"
                                                        fill="#7D7D7D" />
                                                    <path
                                                        d="M6.66634 14.1657C6.55801 14.1657 6.44967 14.1407 6.34967 14.0991C6.24134 14.0574 6.15801 13.9991 6.07467 13.9241C5.92467 13.7657 5.83301 13.5491 5.83301 13.3324C5.83301 13.2241 5.85801 13.1157 5.89967 13.0157C5.94134 12.9157 5.99967 12.8241 6.07467 12.7407C6.15801 12.6657 6.24134 12.6074 6.34967 12.5657C6.65801 12.4324 7.02467 12.5074 7.25801 12.7407C7.33301 12.8241 7.39134 12.9157 7.43301 13.0157C7.47467 13.1157 7.49967 13.2241 7.49967 13.3324C7.49967 13.5491 7.40801 13.7657 7.25801 13.9241C7.09967 14.0741 6.88301 14.1657 6.66634 14.1657ZM9.99967 14.1666C9.77467 14.1666 9.56634 14.0749 9.40801 13.9249C9.33301 13.8416 9.27467 13.7499 9.23301 13.6499C9.1902 13.5498 9.16755 13.4422 9.16634 13.3332C9.16634 13.1082 9.25801 12.8999 9.40801 12.7416C9.71634 12.4332 10.2747 12.4332 10.5913 12.7416C10.7413 12.8999 10.833 13.1082 10.833 13.3332C10.833 13.4416 10.808 13.5499 10.7663 13.6499C10.7247 13.7499 10.6663 13.8416 10.5913 13.9249C10.433 14.0749 10.2163 14.1666 9.99967 14.1666ZM13.333 14.1666C13.1163 14.1666 12.8997 14.0749 12.7413 13.9249C12.5913 13.7666 12.4997 13.5582 12.4997 13.3332C12.4997 13.1082 12.5913 12.8999 12.7413 12.7416C13.058 12.4332 13.6163 12.4332 13.9247 12.7416C13.958 12.7832 13.9913 12.8249 14.0247 12.8749C14.058 12.9166 14.083 12.9666 14.0997 13.0166C14.1247 13.0666 14.1413 13.1166 14.1497 13.1666C14.158 13.2249 14.1663 13.2832 14.1663 13.3332C14.1663 13.5499 14.0747 13.7666 13.9247 13.9249C13.7663 14.0749 13.5497 14.1666 13.333 14.1666Z"
                                                        fill="#7D7D7D" />
                                                </svg>
                                                <span>Update Password</span>
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link " id="profile-tab" data-bs-toggle="tab"
                                                data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                                aria-selected="false">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.4"
                                                        d="M14.0166 1.5918H5.98327C4.2166 1.5918 2.7666 3.0418 2.7666 4.80846V16.5501C2.7666 18.0501 3.8416 18.6835 5.15827 17.9585L9.22493 15.7001C9.65827 15.4585 10.3583 15.4585 10.7833 15.7001L14.8499 17.9585C16.1666 18.6918 17.2416 18.0585 17.2416 16.5501V4.80846C17.2333 3.0418 15.7916 1.5918 14.0166 1.5918Z"
                                                        fill="#7D7D7D" />
                                                    <path
                                                        d="M9.24125 11.0422C9.08292 11.0422 8.92458 10.9839 8.79958 10.8589L7.54958 9.60885C7.43335 9.49124 7.36816 9.33255 7.36816 9.16719C7.36816 9.00183 7.43335 8.84314 7.54958 8.72552C7.79125 8.48385 8.19125 8.48385 8.43292 8.72552L9.24125 9.53385L12.1329 6.64219C12.3746 6.40052 12.7746 6.40052 13.0162 6.64219C13.2579 6.88385 13.2579 7.28385 13.0162 7.52552L9.68292 10.8589C9.55792 10.9839 9.39958 11.0422 9.24125 11.0422Z"
                                                        fill="#7D7D7D" />
                                                </svg>
                                                <span>My Plan</span>
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="settings-tab" data-bs-toggle="tab"
                                                data-bs-target="#settings" type="button" role="tab" aria-controls="settings"
                                                aria-selected="false">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.4"
                                                        d="M18.3254 5.01647C18.3421 5.21647 18.3254 5.4248 18.2754 5.63314L15.4671 16.9081C15.3698 17.3159 15.1372 17.6788 14.8074 17.9375C14.4775 18.1963 14.0697 18.3357 13.6504 18.3331H2.70045C1.44211 18.3331 0.550446 17.0998 0.917113 15.8915L4.42545 4.61647C4.66711 3.83314 5.39211 3.2998 6.20878 3.2998H16.4588C17.2588 3.2998 17.9088 3.7748 18.1838 4.44147C18.2649 4.62314 18.3129 4.81788 18.3254 5.01647Z"
                                                        fill="#7D7D7D" />
                                                    <path
                                                        d="M19.158 16.3501C19.1776 16.6036 19.1444 16.8584 19.0607 17.0985C18.9769 17.3385 18.8444 17.5586 18.6714 17.7449C18.4984 17.9313 18.2886 18.0797 18.0554 18.181C17.8222 18.2823 17.5706 18.3342 17.3163 18.3334H13.6497C14.5163 18.3334 15.2663 17.7501 15.4663 16.9084L18.2747 5.63344C18.3247 5.42511 18.3413 5.21677 18.3247 5.01677L18.333 5.00011L19.158 16.3501ZM8.06634 5.94261C8.01634 5.94261 7.96634 5.93427 7.91634 5.92594C7.83635 5.90643 7.761 5.8713 7.69463 5.82258C7.62825 5.77386 7.57216 5.7125 7.52957 5.64203C7.48698 5.57157 7.45873 5.49338 7.44645 5.41196C7.43417 5.33054 7.4381 5.2475 7.45801 5.16761L8.32467 1.56761C8.40801 1.23427 8.74134 1.03427 9.08301 1.10927C9.41634 1.19261 9.62467 1.52594 9.54134 1.86761L8.67467 5.46761C8.60801 5.75094 8.34967 5.94261 8.06634 5.94261ZM13.6497 5.95177C13.608 5.95177 13.558 5.95177 13.5163 5.93511C13.3554 5.89815 13.2151 5.80018 13.125 5.66185C13.0348 5.52351 13.0018 5.35559 13.033 5.19344L13.8163 1.57677C13.8913 1.23511 14.2247 1.02677 14.558 1.09344C14.8913 1.16844 15.108 1.50177 15.0413 1.83511L14.258 5.45177C14.1997 5.75177 13.9413 5.95177 13.6497 5.95177ZM13.083 10.6251H6.41634C6.07467 10.6251 5.79134 10.3418 5.79134 10.0001C5.79134 9.65844 6.07467 9.37511 6.41634 9.37511H13.083C13.4247 9.37511 13.708 9.65844 13.708 10.0001C13.708 10.3418 13.4247 10.6251 13.083 10.6251ZM12.2497 13.9584H5.58301C5.24134 13.9584 4.95801 13.6751 4.95801 13.3334C4.95801 12.9918 5.24134 12.7084 5.58301 12.7084H12.2497C12.5913 12.7084 12.8747 12.9918 12.8747 13.3334C12.8747 13.6751 12.5913 13.9584 12.2497 13.9584Z"
                                                        fill="#7D7D7D" />
                                                </svg>
                                                <span>About US</span>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="cu-profile-content-area">
                                <div className="tab-content">
                                    <div className="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div className="cu-change-pass-content">
                                            <form className="row">
                                                <div className="col-12 mb-3">
                                                    <label htmlFor="inputOldPassword" className="form-label">Old Password</label>
                                                    <div className="cu-input-icon-group cu-input-right-icon">
                                                        <input type={update.opwdShow ? "password" : "text"} className="form-control" id="inputOldPassword" value={update.oldPassword} name='oldPassword' onChange={handleChange} />
                                                        {update.opwdShow ? <i className='fa fa-eye cu-input-icon' onClick={() => { setUpdate({ ...update, opwdShow: false }) }} /> : <i className='fa fa-eye-slash cu-input-icon' onClick={() => setUpdate({ ...update, opwdShow: true })} />}
                                                        {/* <span className="cu-input-icon">
                                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M13.3671 9.99993C13.3671 11.6499 12.0337 12.9833 10.3837 12.9833C8.73372 12.9833 7.40039 11.6499 7.40039 9.99993C7.40039 8.34993 8.73372 7.0166 10.3837 7.0166C12.0337 7.0166 13.3671 8.34993 13.3671 9.99993Z"
                                                                    stroke="#B2B2B2" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" />
                                                                <path
                                                                    d="M10.3827 16.8913C13.3243 16.8913 16.066 15.1579 17.9743 12.1579C18.7243 10.9829 18.7243 9.00794 17.9743 7.83294C16.066 4.83294 13.3243 3.09961 10.3827 3.09961C7.44102 3.09961 4.69935 4.83294 2.79102 7.83294C2.04102 9.00794 2.04102 10.9829 2.79102 12.1579C4.69935 15.1579 7.44102 16.8913 10.3827 16.8913Z"
                                                                    stroke="#B2B2B2" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" />
                                                            </svg>
                                                        </span> */}
                                                        <span className='error'>{error.oldpassword_err}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <label htmlFor="inputNewPassword" className="form-label">Enter New Password</label>
                                                    <div className="cu-input-icon-group cu-input-right-icon">
                                                        <input type={update.pwdShow ? "password" : "text"} className="form-control" id="inputNewPassword" onChange={handleChange} name='newPassword' value={update.newPassword} />
                                                        {update.pwdShow ? <i className='fa fa-eye cu-input-icon' onClick={() => { setUpdate({ ...update, pwdShow: false }) }} /> : <i className='fa fa-eye-slash cu-input-icon' onClick={() => setUpdate({ ...update, pwdShow: true })} />}

                                                        {/* <span className="cu-input-icon">
                                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M13.3671 9.99993C13.3671 11.6499 12.0337 12.9833 10.3837 12.9833C8.73372 12.9833 7.40039 11.6499 7.40039 9.99993C7.40039 8.34993 8.73372 7.0166 10.3837 7.0166C12.0337 7.0166 13.3671 8.34993 13.3671 9.99993Z"
                                                                    stroke="#B2B2B2" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" />
                                                                <path
                                                                    d="M10.3827 16.8913C13.3243 16.8913 16.066 15.1579 17.9743 12.1579C18.7243 10.9829 18.7243 9.00794 17.9743 7.83294C16.066 4.83294 13.3243 3.09961 10.3827 3.09961C7.44102 3.09961 4.69935 4.83294 2.79102 7.83294C2.04102 9.00794 2.04102 10.9829 2.79102 12.1579C4.69935 15.1579 7.44102 16.8913 10.3827 16.8913Z"
                                                                    stroke="#B2B2B2" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" />
                                                            </svg>
                                                        </span> */}
                                                        <span className='error'>{error.newpassword_err}</span>

                                                    </div>
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <label htmlFor="inputReNewPassword" className="form-label">Re-Enter New
                                                        Password</label>
                                                    <div className="cu-input-icon-group cu-input-right-icon">
                                                        <input type={update.cpwdShow ? "password" : "text"} className="form-control" id="inputReNewPassword" onChange={handleChange} value={update.cpassword} name='cpassword' />
                                                        {update.cpwdShow ? <i className='fa fa-eye cu-input-icon' onClick={() => { setUpdate({ ...update, cpwdShow: false }) }} /> : <i className='fa fa-eye-slash cu-input-icon' onClick={() => setUpdate({ ...update, cpwdShow: true })} />}

                                                        {/* <span className="cu-input-icon">
                                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M13.3671 9.99993C13.3671 11.6499 12.0337 12.9833 10.3837 12.9833C8.73372 12.9833 7.40039 11.6499 7.40039 9.99993C7.40039 8.34993 8.73372 7.0166 10.3837 7.0166C12.0337 7.0166 13.3671 8.34993 13.3671 9.99993Z"
                                                                    stroke="#B2B2B2" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" />
                                                                <path
                                                                    d="M10.3827 16.8913C13.3243 16.8913 16.066 15.1579 17.9743 12.1579C18.7243 10.9829 18.7243 9.00794 17.9743 7.83294C16.066 4.83294 13.3243 3.09961 10.3827 3.09961C7.44102 3.09961 4.69935 4.83294 2.79102 7.83294C2.04102 9.00794 2.04102 10.9829 2.79102 12.1579C4.69935 15.1579 7.44102 16.8913 10.3827 16.8913Z"
                                                                    stroke="#B2B2B2" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" />
                                                            </svg>
                                                        </span> */}
                                                        <span className='error'>{error.cpassword_err}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="cu-inqury-form-btn">
                                                        <button type="button" className="btn btn-info" onClick={updatePassoword} disabled={disable}>{disable ? "Processing..." : "Update Password"}</button>
                                                        <a href="#" className="btn btn-outline-info">Cancel</a>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                        <div className="cu-plan-content">

                                            {
                                                profile?.active_plan == null ?
                                                    <h2 style={{ textAlign: "center", width: "100%" }}>You have not purchased any subscription yet.</h2>
                                                    :
                                                    <div className="cu-plan-card">
                                                        <div className="cu-plan-card-head">
                                                            <span className="cu-plan-card-icon">
                                                                <svg width="47" height="47" viewBox="0 0 47 47" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="23.7987" cy="23.7987" r="19.9706" stroke="#FF7800"
                                                                        stroke-width="6" />
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                        d="M31.3452 16.6927C31.4126 16.6978 31.4662 16.7514 31.4714 16.8188L31.5988 18.4753C31.8295 21.4752 30.7379 24.4261 28.6104 26.5536C27.8497 27.3143 26.9747 27.9512 26.017 28.4412L19.0512 32.005C18.9386 32.0627 18.8018 32.0411 18.7124 31.9517L16.2124 29.4517C16.1229 29.3623 16.1014 29.2254 16.159 29.1128L19.7229 22.1471C20.2128 21.1894 20.8497 20.3143 21.6104 19.5536C23.7379 17.4261 26.6889 16.3345 29.6888 16.5652L31.3452 16.6927ZM28.2368 21.2262C28.067 20.5923 27.5718 20.0971 26.9378 19.9272C26.3038 19.7573 25.6274 19.9386 25.1633 20.4027C24.6992 20.8668 24.5179 21.5432 24.6878 22.1772C24.8577 22.8112 25.3529 23.3064 25.9868 23.4762C26.6208 23.6461 27.2972 23.4649 27.7613 23.0008C28.2254 22.5367 28.4067 21.8602 28.2368 21.2262Z"
                                                                        fill="#FF7800" />
                                                                    <path
                                                                        d="M18.8404 21.5416L16.3975 21.5415C15.3113 21.5414 14.321 22.1637 13.8498 23.1424L12.2145 26.5388C12.0674 26.8442 12.29 27.1983 12.6289 27.1982L15.9453 27.1983L18.7768 21.664C18.7977 21.6231 18.8189 21.5822 18.8404 21.5416Z"
                                                                        fill="#FF7800" />
                                                                    <path
                                                                        d="M20.8006 32.3009L20.8006 35.37C20.8006 35.7093 21.1551 35.932 21.4608 35.7849L24.8561 34.1501C25.8351 33.6787 26.4575 32.6882 26.4575 31.6017V29.4067L20.8006 32.3009Z"
                                                                        fill="#FF7800" />
                                                                </svg>
                                                            </span>
                                                            <h4 className="cu-plan-card-title">{profile?.active_plan?.plan_name}</h4>
                                                        </div>
                                                        <div className="cu-plan-card-body">
                                                            {/* <ul className="cu-plan-card-feature-list">
                                                                <li className="cu-plan-card-feature-item">Access To the ContiguoU Web Panel
                                                                </li>
                                                                <li className="cu-plan-card-feature-item">5 Services</li>
                                                                <li className="cu-plan-card-feature-item">Upto 100 Beds</li>
                                                                <li className="cu-plan-card-feature-item">Business profile Management</li>
                                                                <li className="cu-plan-card-feature-item">Enquiry Management & Access to
                                                                    customer contact information and
                                                                    their details.</li>
                                                                <li className="cu-plan-card-feature-item">Premium Customer Support</li>
                                                            </ul> */}
                                                            {parse(profile?.active_plan?.description)}
                                                            <div className="cu-plan-expire">

                                                                <label className="cu-plan-expire-label">Expires</label>
                                                                <h3 className="cu-plan-expire-date" style={{ color: profile?.is_plan_active ? "" : "Red" }}>{profile?.active_plan_expire_at}</h3>

                                                                <label className="cu-plan-expire-label">Pays</label>
                                                                <h3 className="cu-plan-expire-date cu-plan-amount">${profile?.active_plan?.amount}/{(profile?.active_plan?.duration.includes("1") ? Capitalize(profile?.active_plan?.duration.split(' ')[1]) : (profile?.active_plan?.duration))}</h3>
                                                            </div>
                                                        </div>
                                                        <div className="cu-plan-card-footer">
                                                            <Link to="/subscription-plans" className="btn btn-primary">Upgrade Plan</Link>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                                        <div className="cu-about-content">
                                            <h2 className="cu-about-page-title">About US</h2>
                                            <div className="cu-about-description">
                                                <p>On a mission to make healthcare more efficient and connected. Giving people
                                                    better health care & choices. The first step to improvement is understanding
                                                    the problem.</p>
                                                <p>All have very strong vested interests in getting people connected the right
                                                    way, and to build out a product that can make a difference in the lives of
                                                    people. When we first started this project we were told this was impossible.
                                                    Well here we are making the impossible needle move. By the simple fact that
                                                    we knew healthcare and how to ask the right questions, roll up our sleeves
                                                    and do the work. ContigoU means “with you” and we are. We hope you will join
                                                    us on our exciting journey to move the world forward</p>
                                            </div>
                                            <h2 className="cu-about-page-title">Our founding story</h2>
                                            <div className="cu-story-content">
                                                <div className="row">
                                                    <div className="col col-12 col-md-6 col-lg-6 col-xl-4">
                                                        <div className="cu-story-card">
                                                            <div className="cu-story-card-img">
                                                                <img src="assets/images/story1.png" alt="story"
                                                                    className="img-fluid" />
                                                            </div>
                                                            <div className="cu-story-card-body">
                                                                <label className="cu-story-card-label">Renee</label>
                                                                <p className="cu-story-card-description">RN over 28 yrs experience
                                                                    in healthcare, served in military Hospital Corpsman and
                                                                    continues to volunteer with veterans and community</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col col-12 col-md-6 col-lg-6 col-xl-4">
                                                        <div className="cu-story-card">
                                                            <div className="cu-story-card-img">
                                                                <img src="assets/images/story1.png" alt="story"
                                                                    className="img-fluid" />
                                                            </div>
                                                            <div className="cu-story-card-body">
                                                                <label className="cu-story-card-label">Julio</label>
                                                                <p className="cu-story-card-description">Navy Master Chief served
                                                                    our Nation, continues to help our soldiers and their
                                                                    families and in a wide range of community programs.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col col-12 col-md-6 col-lg-6 col-xl-4">
                                                        <div className="cu-story-card">
                                                            <div className="cu-story-card-img">
                                                                <img src="assets/images/story1.png" alt="story"
                                                                    className="img-fluid" />
                                                            </div>
                                                            <div className="cu-story-card-body">
                                                                <label className="cu-story-card-label">Tom</label>
                                                                <p className="cu-story-card-description">ver 10 yrs working with
                                                                    people, serving in the community and helping people get
                                                                    connected and volunteering services.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col col-12 col-md-6 col-lg-6 col-xl-4">
                                                        <div className="cu-story-card">
                                                            <div className="cu-story-card-img">
                                                                <img src="assets/images/story1.png" alt="story"
                                                                    className="img-fluid" />
                                                            </div>
                                                            <div className="cu-story-card-body">
                                                                <label className="cu-story-card-label">Bill</label>
                                                                <p className="cu-story-card-description">Military established new
                                                                    naval ship nonprofit museums, worked with Federal Reserve
                                                                    Bank and Dept of the Navy, and volunteer at the long-term
                                                                    care facility at the San Francisco VA</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {/* <!-- Delete Modal --> */}
            <div className="modal fade cu-delete-modal" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content ">
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="cu-thank-you-content">
                                <div className="cu-delete-icon">
                                    <svg width="62" height="62" viewBox="0 0 62 62" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M43.607 58.1251H18.3937C17.1354 58.1268 15.9246 57.6453 15.0112 56.78C14.0978 55.9146 13.5516 54.7316 13.4853 53.4751L11.2637 13.7434H50.737L48.5153 53.4751C48.4491 54.7316 47.9029 55.9146 46.9895 56.78C46.0761 57.6453 44.8652 58.1268 43.607 58.1251Z"
                                            stroke="#C90303" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path d="M6.33008 13.7434H55.6717" stroke="#C90303" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                        <path
                                            d="M26.065 3.875H35.9334C37.242 3.875 38.497 4.39485 39.4223 5.32018C40.3477 6.24552 40.8675 7.50054 40.8675 8.80917V13.7433H21.1309V8.80917C21.1309 7.50054 21.6507 6.24552 22.576 5.32018C23.5014 4.39485 24.7564 3.875 26.065 3.875Z"
                                            stroke="#C90303" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path d="M31 21.1316V50.7366" stroke="#C90303" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                        <path d="M40.8691 21.1316V50.7366" stroke="#C90303" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                        <path d="M21.1309 21.1316V50.7366" stroke="#C90303" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                    </svg>
                                </div>
                                <h3 className="cu-thank-you-title">Are You Sure you want to delete this ?</h3>
                                <div className="cu-modal-btn">
                                    <button className="btn btn-danger" type="button" data-bs-dismiss="modal"
                                        aria-label="Close">Delete </button>
                                    <button className="btn btn-info" type="button" data-bs-dismiss="modal" aria-label="Close">Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Notification Modal --> */}

        </div>
    )
}

export default MyProfile