import React from 'react'
import { Modal } from 'react-bootstrap'

const DeleteInquiry = ({ activeModal, setActiveModal, handleDelete }) => {
    return (
        <Modal
            className="modal fade cu-delete-modal"
            id="deleteModal"
            show={activeModal}
            onHide={setActiveModal}
            centered={true}
        >
            <Modal.Body>
                <button type="button" className="btn-close" onClick={setActiveModal}></button>
                <div className="cu-thank-you-content">
                    <div className="cu-delete-icon">
                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M43.607 58.1251H18.3937C17.1354 58.1268 15.9246 57.6453 15.0112 56.78C14.0978 55.9146 13.5516 54.7316 13.4853 53.4751L11.2637 13.7434H50.737L48.5153 53.4751C48.4491 54.7316 47.9029 55.9146 46.9895 56.78C46.0761 57.6453 44.8652 58.1268 43.607 58.1251Z"
                                stroke="#C90303" stroke-width="1.5" stroke-miterlimit="10" />
                            <path d="M6.33008 13.7434H55.6717" stroke="#C90303" stroke-width="1.5"
                                stroke-miterlimit="10" />
                            <path
                                d="M26.065 3.875H35.9334C37.242 3.875 38.497 4.39485 39.4223 5.32018C40.3477 6.24552 40.8675 7.50054 40.8675 8.80917V13.7433H21.1309V8.80917C21.1309 7.50054 21.6507 6.24552 22.576 5.32018C23.5014 4.39485 24.7564 3.875 26.065 3.875Z"
                                stroke="#C90303" stroke-width="1.5" stroke-miterlimit="10" />
                            <path d="M31 21.1316V50.7366" stroke="#C90303" stroke-width="1.5"
                                stroke-miterlimit="10" />
                            <path d="M40.8691 21.1316V50.7366" stroke="#C90303" stroke-width="1.5"
                                stroke-miterlimit="10" />
                            <path d="M21.1309 21.1316V50.7366" stroke="#C90303" stroke-width="1.5"
                                stroke-miterlimit="10" />
                        </svg>
                    </div>
                    <h3 className="cu-thank-you-title">Are you sure you want to delete this inquiry?</h3>
                    <div className="cu-modal-btn">
                        <button className="btn btn-danger" type="button" onClick={handleDelete} data-bs-dismiss="modal"
                            aria-label="Close">Delete </button>
                        <button className="btn btn-info" type="button" onClick={setActiveModal} data-bs-dismiss="modal" aria-label="Close">Cancel
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteInquiry
