import React, { useEffect, useRef, useState } from 'react'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CONTIGO_API_URL } from '../BaseURL'
import { toast, Toaster } from 'react-hot-toast';
import parse from 'html-react-parser';

import $ from 'jquery';
import { Link } from 'react-router-dom';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import ContactUs from './contactUs';
const Index = () => {

    const aboutRef = useRef(null);
    const howRef = useRef(null);
    const subscriptionRef = useRef(null);
    const contactRef = useRef(null);
    const homeRef = useRef(null);
    const [planshow, setPlanShow] = useState("a-month");
    const nav = useNavigate();


    useEffect(() => {

        if (localStorage.getItem('CONTIGO_TOKEN_BUSINESS')) {
            toast.success("Already Logged-In")
            setTimeout(() => {
                nav('/dashboard')
            }, 1500)
        }
        else {
            getSubscriptionPlan();
        }
        // eslint-disable-next-line
    }, [])


    const [plans, setPlans] = useState([]);

    const getSubscriptionPlan = () => {
        axios({
            method: 'get',
            url: `${CONTIGO_API_URL}api/nursing-user/get-subscription-plans`,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.data.status === true) {
                setPlans(response.data.data)

            }
        }).catch((error) => {
            toast.error(error?.response?.data?.message || "Something went wrong");
            console?.log("Errors", error);
            if (error.response.status === 401) {
                localStorage.removeItem('CONTIGO_TOKEN_BUSINESS');
                nav('/login')
            }
        });
    }

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const smoothScrollToRef = (ref) => {
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };


    useEffect(() => {
        $('.navbar-toggler').on('click', function () {
            $('body').addClass('menu-open');
        });

        const getRef = localStorage.getItem("INPUT_DATA")
        if (getRef) {
            setTimeout(() => {
                if (getRef == "contactRef") {
                    smoothScrollToRef(contactRef)
                }
                if (getRef == "howRef") {
                    smoothScrollToRef(howRef)
                }

                localStorage.removeItem("INPUT_DATA")
            }, 100);
        }
    }, []);

    return (
        <div className='cu-site'>
            <Header />

            <main className="cu-site-content">
                {/* <!-- Landing Section Start --> */}
                <section className="cu-landing-section">
                    <div className="container">
                        <h1 className="cu-section-title">Connecting Patients to Post Acute Providers</h1>
                        <p className="cu-section-description text-center">ContigoU’s software engages patients and care providers in the care transition process,
                            saving hospitals time and improving patient outcomes.</p>
                        <div className="text-center">
                            <a href="/register" className="btn btn-primary">Get Started</a>
                        </div>
                    </div>
                </section>
                {/* <!-- Landing Section End --> */}

                {/* <!-- Our Services Section Start --> */}
                <section className="cu-we-are-section">
                    <div className="container">
                        <div className="cu-service-heading">
                            <h3 className="cu-section-subtitle text-start">Who we are</h3>
                        </div>
                        <div className="cu-we-are-content">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-3">
                                    <div className="cu-we-are-card">
                                        <div className="cu-we-are-card-icon">
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M24.9999 20.8315L26.3094 23.5814L29.2557 24.0179L27.1169 26.1567L27.6189 29.1685L24.9999 27.7499L22.381 29.1685L22.8829 26.1567L20.7441 24.0179L23.6905 23.5814L24.9999 20.8315Z"
                                                    stroke="white" stroke-width="2.00087" stroke-miterlimit="10" />
                                                <path d="M16.663 39.5789H33.3369" stroke="white" stroke-width="2.00087"
                                                    stroke-miterlimit="10" />
                                                <path d="M43.7472 2.08423H6.25269V47.9157H43.7472V2.08423Z" stroke="white"
                                                    stroke-width="2.00087" stroke-miterlimit="10" />
                                                <path
                                                    d="M16.663 12.4945C19.2479 11.1777 22.0993 10.4685 24.9999 10.4211C27.9005 10.4685 30.752 11.1777 33.3369 12.4945"
                                                    stroke="white" stroke-width="2.00087" stroke-miterlimit="10" />
                                            </svg>
                                        </div>
                                        <div className="cu-we-are-card-body">
                                            <h2 className="cu-we-are-card-title">Connections to Patients</h2>
                                            <p className="cu-we-are-card-description">At ContigoU, we create bridges, linking patients directly to the healthcare they need. Our platform ensures that every connection is a step towards better health.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-3">
                                    <div className="cu-we-are-card">
                                        <div className="cu-we-are-card-icon">
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M28.9792 5.10409C29.6001 4.45675 30.3434 3.93923 31.166 3.58159C31.9886 3.22395 32.874 3.03331 33.7709 3.02075H34.125C35.0219 3.03331 35.9073 3.22395 36.7299 3.58159C37.5525 3.93923 38.2958 4.45675 38.9167 5.10409L44.8959 11.0833C45.5244 11.7128 46.0226 12.46 46.3622 13.2822C46.7018 14.1043 46.8761 14.9854 46.875 15.8749V16.2291C46.8761 17.1186 46.7018 17.9997 46.3622 18.8218C46.0226 19.644 45.5244 20.3912 44.8959 21.0208L21.0209 44.8957C20.3913 45.5242 19.6441 46.0225 18.822 46.3621C17.9998 46.7017 17.1188 46.876 16.2292 46.8749H15.875C14.9855 46.876 14.1045 46.7017 13.2823 46.3621C12.4601 46.0225 11.7129 45.5242 11.0834 44.8957L5.10421 38.9166C4.45687 38.2957 3.93936 37.5524 3.58171 36.7298C3.22407 35.9072 3.03344 35.0218 3.02087 34.1249V33.7707C3.03344 32.8739 3.22407 31.9884 3.58171 31.1659C3.93936 30.3433 4.45687 29.6 5.10421 28.9791L27.0834 7.10409L28.9792 5.10409Z"
                                                    stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                                <path
                                                    d="M40.9167 25L42.8958 26.9792L44.9792 29.0625C45.6077 29.692 46.1059 30.4392 46.4455 31.2614C46.7851 32.0836 46.9594 32.9646 46.9583 33.8542V34.2083C46.9594 35.0979 46.7851 35.9789 46.4455 36.8011C46.1059 37.6233 45.6077 38.3705 44.9792 39L39 44.9792C38.3705 45.6077 37.6233 46.1059 36.8011 46.4455C35.9789 46.7851 35.0979 46.9594 34.2083 46.9583H33.8542C32.9646 46.9594 32.0836 46.7851 31.2614 46.4455C30.4392 46.1059 29.692 45.6077 29.0625 44.9792L25 40.9167"
                                                    stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                                <path
                                                    d="M9.08337 24.9999L5.10421 21.0208C4.45687 20.3999 3.93936 19.6566 3.58171 18.834C3.22407 18.0114 3.03344 17.126 3.02087 16.2291V15.8749C3.03344 14.978 3.22407 14.0926 3.58171 13.27C3.93936 12.4475 4.45687 11.7041 5.10421 11.0833L11.0834 5.10409C11.7043 4.45675 12.4476 3.93923 13.2702 3.58159C14.0927 3.22395 14.9782 3.03331 15.875 3.02075H16.2292C17.1261 3.03331 18.0115 3.22395 18.8341 3.58159C19.6567 3.93923 20.4 4.45675 21.0209 5.10409L25 9.08325"
                                                    stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                                <path
                                                    d="M24.0001 31.9582L18.0417 25.9998L26.0001 18.0415L28.5209 20.5623L31.9584 23.9998L24.0001 31.9582Z"
                                                    stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                                <path d="M9.08337 32.9583H13.0625" stroke="white" stroke-width="1.5"
                                                    stroke-miterlimit="10" />
                                                <path d="M15.0625 38.9165H19.0417" stroke="white" stroke-width="1.5"
                                                    stroke-miterlimit="10" />
                                                <path d="M30.9584 11.0833H34.9375" stroke="white" stroke-width="1.5"
                                                    stroke-miterlimit="10" />
                                                <path d="M36.9375 17.0415H40.9167" stroke="white" stroke-width="1.5"
                                                    stroke-miterlimit="10" />
                                            </svg>
                                        </div>
                                        <div className="cu-we-are-card-body">
                                            <h2 className="cu-we-are-card-title">Marketing</h2>
                                            <p className="cu-we-are-card-description">We specialize in targeted marketing, bringing your facility to the forefront for those in need. Our strategies are designed to shine a spotlight on your services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-3">
                                    <div className="cu-we-are-card">
                                        <div className="cu-we-are-card-icon">
                                            <svg width="60" height="60" viewBox="0 0 60 60" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M6.60938 49.7812C6.60938 49.9883 6.77727 50.1562 6.98438 50.1562H34.7954C39.5213 50.1562 44.1065 48.5479 47.7968 45.5956L52.2413 42.04C53.4542 41.0696 53.6509 39.2997 52.6806 38.0868C51.7103 36.8739 49.9403 36.6773 48.7274 37.6475L44.2829 41.2032C43.6803 41.6853 42.9176 41.9062 42.1459 41.9062H26.1093C26.0058 41.9062 25.9219 41.8223 25.9219 41.7188C25.9219 41.6172 26.0029 41.534 26.1045 41.5326C26.1728 41.5317 26.241 41.5312 26.3093 41.5312H33.6094C35.1627 41.5312 36.4219 40.2721 36.4219 38.7188C36.4219 37.1654 35.1627 35.9062 33.6094 35.9062H26.3093C22.8697 35.9062 19.4944 36.7585 16.4805 38.3733C15.4553 38.9226 14.3281 39.2812 13.1651 39.2812H6.98438C6.77727 39.2812 6.60938 39.4492 6.60938 39.6562V49.7812Z"
                                                    stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path
                                                    d="M24.6094 24.4429V24.6252C24.6094 27.0415 26.5682 29.0002 28.9844 29.0002H30.8803C33.2159 29.0002 35.1094 27.1068 35.1094 24.7712C35.1094 22.9002 33.8799 21.2515 32.0866 20.7178L30.2094 20.1591L27.9554 19.533C26.1847 19.0411 24.9594 17.429 24.9594 15.5913C24.9594 13.3318 26.791 11.5002 29.0503 11.5002H30.3844C32.8007 11.5002 34.7593 13.459 34.7593 15.8752V16.0575"
                                                    stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path d="M29.8594 11.5V9.75" stroke="white" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M29.8594 30.7497V28.9998" stroke="white" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <div className="cu-we-are-card-body">
                                            <h2 className="cu-we-are-card-title">Appointments</h2>
                                            <p className="cu-we-are-card-description">Efficiency in appointment setting is key. We streamline this process, ensuring patients can easily find and book the care they need with you.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-3">
                                    <div className="cu-we-are-card">
                                        <div className="cu-we-are-card-icon">
                                            <svg width="60" height="60" viewBox="0 0 60 60" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 52.5V28.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path d="M9 47.9287H51" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path d="M51 47.9287V52.5001" stroke="white" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round" />
                                                <path
                                                    d="M25.619 47.9287H50.7142C50.872 47.9287 51 47.8008 51 47.643V46.2144C51 39.587 45.6273 34.2144 39 34.2144H26.4761C25.845 34.2144 25.3333 34.7261 25.3333 35.3573V47.643C25.3333 47.8008 25.4612 47.9287 25.619 47.9287Z"
                                                    stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path
                                                    d="M15.3229 37.6252C16.5574 36.927 18.0675 36.927 19.302 37.6252L19.3644 37.6604C20.5938 38.3558 21.3539 39.659 21.3539 41.0714C21.3539 42.4838 20.5938 43.787 19.3644 44.4824L19.302 44.5176C18.0675 45.2158 16.5574 45.2158 15.3229 44.5176L15.2606 44.4824C14.0311 43.787 13.271 42.4838 13.271 41.0714C13.271 39.659 14.0311 38.3558 15.2606 37.6604L15.3229 37.6252Z"
                                                    stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path
                                                    d="M14.3366 8.75742C14.618 8.22409 15.3819 8.22409 15.6633 8.75742L16.891 11.0844C16.9995 11.29 17.1973 11.4338 17.4265 11.4734L20.0189 11.922C20.6131 12.0248 20.8491 12.7512 20.4289 13.1837L18.5952 15.0704C18.4331 15.2371 18.3575 15.4697 18.3907 15.6999L18.7652 18.304C18.851 18.9009 18.233 19.3499 17.6918 19.0838L15.3309 17.9229C15.1222 17.8203 14.8777 17.8203 14.669 17.9229L12.308 19.0838C11.7669 19.3499 11.1489 18.9009 11.2348 18.304L11.6093 15.6999C11.6423 15.4697 11.5668 15.2371 11.4047 15.0704L9.57106 13.1837C9.15076 12.7512 9.38679 12.0248 9.98101 11.922L12.5735 11.4734C12.8026 11.4338 13.0004 11.29 13.1089 11.0844L14.3366 8.75742Z"
                                                    stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path
                                                    d="M29.3366 8.75742C29.618 8.22409 30.3819 8.22409 30.6633 8.75742L31.891 11.0844C31.9995 11.29 32.1973 11.4338 32.4265 11.4734L35.0189 11.922C35.6131 12.0248 35.8491 12.7512 35.4289 13.1837L33.5952 15.0704C33.4331 15.2371 33.3575 15.4697 33.3907 15.6999L33.7652 18.304C33.851 18.9009 33.233 19.3499 32.6918 19.0838L30.3309 17.9229C30.1222 17.8203 29.8777 17.8203 29.669 17.9229L27.308 19.0838C26.7669 19.3499 26.1489 18.9009 26.2348 18.304L26.6093 15.6999C26.6423 15.4697 26.5668 15.2371 26.4047 15.0704L24.5711 13.1837C24.1508 12.7512 24.3868 12.0248 24.981 11.922L27.5735 11.4734C27.8026 11.4338 28.0004 11.29 28.1089 11.0844L29.3366 8.75742Z"
                                                    stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path
                                                    d="M44.3366 8.75742C44.618 8.22409 45.3819 8.22409 45.6633 8.75742L46.891 11.0844C46.9995 11.29 47.1973 11.4338 47.4265 11.4734L50.0189 11.922C50.6131 12.0248 50.8491 12.7512 50.4289 13.1837L48.5952 15.0704C48.4331 15.2371 48.3575 15.4697 48.3907 15.6999L48.7652 18.304C48.851 18.9009 48.233 19.3499 47.6918 19.0838L45.3309 17.9229C45.1222 17.8203 44.8777 17.8203 44.669 17.9229L42.308 19.0838C41.7669 19.3499 41.1489 18.9009 41.2348 18.304L41.6093 15.6999C41.6423 15.4697 41.5668 15.2371 41.4047 15.0704L39.5711 13.1837C39.1508 12.7512 39.3868 12.0248 39.981 11.922L42.5735 11.4734C42.8026 11.4338 43.0004 11.29 43.1089 11.0844L44.3366 8.75742Z"
                                                    stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <div className="cu-we-are-card-body">
                                            <h2 className="cu-we-are-card-title">Bed Management</h2>
                                            <p className="cu-we-are-card-description">Our innovative approach to bed management optimizes facility occupancy, ensuring efficient use of resources while aiding those in urgent need.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Our Services Section End --> */}

                {/* <!-- About Section Start --> */}
                <section className="cu-about-section" id="about_us" ref={aboutRef}>
                    <div className="container">
                        <div className="row">
                            <div className="col col-12 col-md-4 col-lg-4">
                                <div className="cu-about-heading">
                                    <h3 className="cu-section-subtitle text-start">About US</h3>
                                </div>
                            </div>
                            <div className="col col-12 col-md-8 col-lg-8">
                                <div className="cu-about-content">
                                    <h2 className="cu-section-title">ContigoU (WithU) we care about you and our communities and help by connecting people to healthcare and resources.</h2>
                                    {/* <div className="cu-section-description">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua Ut enim ad minim nostrud Lorem ipsum
                                            dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                            labore et dolore magna aliqua Ut enim ad minim nostrud</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua Ut enim ad minim nostrud Lorem ipsum
                                            dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                            labore et dolore magna aliqua Ut enim ad minim nostrud</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="cu-about-img-content">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-8">
                                    <div className="cu-about-img-content__inner">
                                        <div className="row">
                                            <div className="col col-12 col-md-6 col-lg-6">
                                                <div className="cu-about-img-card">
                                                    <img src="assets/images/about-img1.png" alt="about-img" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col col-12 col-md-6 col-lg-6">
                                                <div className="cu-about-description-card">
                                                    <h3 className="cu-about-img-description">Our platform allows nursing homes to
                                                        register and connect with patients</h3>
                                                </div>
                                            </div>
                                            <div className="col col-12 col-md-12 col-lg-12">
                                                <div className="cu-about-img-card">
                                                    <img src="assets/images/about-img2.png" alt="about-img" className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-4">
                                    <div className="cu-about-img-card">
                                        <img src="assets/images/about-img3.png" alt="about-img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- About Section End --> */}

                {/* <!-- Choose Section Start --> */}
                <section className="cu-choose-section">
                    <div className="container">
                        <div className="row">
                            <div className="col col-12 col-md-6 col-lg-4 mx-auto">
                                <div className="cu-choose-img-area">
                                    <img src="assets/images/choose-img.png" alt="choose-img" />
                                </div>
                            </div>
                            <div className="col col-12 col-md-12 col-lg-8">
                                <div className="cu-choose-content-area">
                                    <div className="row">
                                        <div className="col col-12 col-md-6 col-lg-6">
                                            <div className="cu-service-card">
                                                <div className="cu-service-card-icon">
                                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M16.1716 32.6511C15.5859 33.2369 14.6361 33.2369 14.0503 32.6511L11.9245 30.5253C7.82398 26.4248 7.82398 19.7763 11.9245 15.6757C16.0252 11.5751 22.6736 11.5751 26.7742 15.6757L28.2914 17.1929C28.3105 17.2109 28.3294 17.2292 28.3482 17.248L34.489 23.3888C35.5014 24.4012 35.5014 26.0424 34.489 27.0548C33.4768 28.067 31.8355 28.067 30.8231 27.0548L27.3562 23.5878C26.7704 23.0021 25.8207 23.0021 25.2349 23.5878"
                                                            stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                        <path
                                                            d="M25.2358 23.5876L16.8104 32.0131C15.9296 33.0315 15.9727 34.5727 16.9399 35.5398C17.9522 36.5521 19.5935 36.5521 20.6058 35.5398L27.4906 28.655"
                                                            stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                        <path
                                                            d="M27.5207 28.6857L27.5513 28.7162L20.6665 35.601C19.6541 36.6134 19.6541 38.2546 20.6665 39.267C21.6788 40.2792 23.3201 40.2792 24.3324 39.267L31.2172 32.3821L31.2476 32.4126"
                                                            stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                        <path
                                                            d="M31.2778 32.443L24.393 39.3278C23.3807 40.3401 23.3807 41.9814 24.393 42.9938C25.4053 44.006 27.0466 44.006 28.0589 42.9938L34.9438 36.1089L34.9742 36.1394"
                                                            stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                        <path
                                                            d="M29.9566 18.8563L33.1401 15.6728C37.2398 11.5731 43.8869 11.5731 47.9865 15.6728C52.0862 19.7725 52.0862 26.4195 47.9865 30.5192L46.3399 32.1658C46.331 32.175 46.322 32.1841 46.313 32.1932L31.7855 46.7206C30.7732 47.733 29.1319 47.733 28.1196 46.7206C27.1073 45.7084 27.1073 44.0671 28.1196 43.0547L35.0044 36.1699"
                                                            stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                </div>
                                                <div className="cu-service-card-body">
                                                    <h3 className="cu-service-card-title">Find Leads</h3>
                                                    <p className="cu-service-card-description">Discover a stream of potential patients actively seeking your services. Our platform connects you with those in need, matching your capabilities with their requirements.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-12 col-md-6 col-lg-6">
                                            <div className="cu-service-card">
                                                <div className="cu-service-card-icon cu-assisted-icon">
                                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M45 52.5001H15C13.3432 52.5001 12 51.157 12 49.5001V48.4979C12 44.1385 14.7046 40.2364 18.7867 38.7065C26.0167 35.9968 33.9833 35.9968 41.2133 38.7065C45.2954 40.2364 48 44.1385 48 48.4979V49.5001C48 51.157 46.6568 52.5001 45 52.5001Z"
                                                            stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                        <path
                                                            d="M25.4258 29.1505C28.2968 30.6252 31.7027 30.6252 34.5736 29.1505C37.2455 27.778 39.1598 25.2781 39.7884 22.3408L39.9561 21.5569C40.5989 18.5531 39.9123 15.4182 38.073 12.958L37.8037 12.5977C35.9641 10.1372 33.0719 8.68835 29.9997 8.68835C26.9276 8.68835 24.0353 10.1372 22.1957 12.5977L21.9264 12.958C20.087 15.4182 19.4006 18.5531 20.0432 21.5569L20.211 22.3408C20.8395 25.2781 22.7539 27.778 25.4258 29.1505Z"
                                                            stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                </div>
                                                <div className="cu-service-card-body">
                                                    <h3 className="cu-service-card-title">Manage Account</h3>
                                                    <p className="cu-service-card-description">Stay in control with our intuitive account management. Track leads, monitor your services' performance, and adapt with real-time analytics for optimal results.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-12 col-md-6 col-lg-6">
                                            <div className="cu-service-card">
                                                <div className="cu-service-card-icon cu-board-icon">
                                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M24 24C24 24.0001 24.0001 24 24 24ZM24 24L24.0001 12.75C24.0001 11.5073 25.0075 10.5 26.2501 10.5H33.7501C34.9927 10.5 36.0001 11.5074 36.0001 12.75V23.8125C36.0001 23.9161 36.084 24 36.1876 24H47.25C48.4927 24 49.5 25.0073 49.5 26.25V33.75C49.5 34.9927 48.4927 36 47.25 36H36.1876C36.084 36 36.0001 36.0839 36.0001 36.1875V47.25C36.0001 48.4927 34.9927 49.5 33.7501 49.5H26.2501C25.0075 49.5 24.0001 48.4927 24.0001 47.25L24 36M24 24H12.75C11.5073 24 10.5 25.0074 10.5 26.25V33.75C10.5 34.9927 11.5073 36 12.75 36H24M24 36C24 35.9999 24.0001 36 24 36Z"
                                                            stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                </div>
                                                <div className="cu-service-card-body">
                                                    <h3 className="cu-service-card-title">Manage Beds</h3>
                                                    <p className="cu-service-card-description">Efficient bed management is key in healthcare. Our system helps you utilize your capacity to the fullest, ensuring timely care for patients while optimizing your resources.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-12 col-md-6 col-lg-6">
                                            <div className="cu-service-card">
                                                <div className="cu-service-card-icon cu-communication-icon">
                                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M46.875 39.1042L40.875 45.1042C36.7083 49.2708 23.8333 46.0625 13.8958 36.1042C3.95829 26.1458 0.74996 13.2708 4.89579 9.125L10.8958 3.125L21.3125 13.5417L16.1458 18.875C14.0625 20.9583 14.9166 25.125 19.875 30.125C24.8333 35.125 29.0625 35.9375 31.125 33.8542L36.375 28.625L46.875 39.1042Z"
                                                            stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                                        <path d="M27 7.10416H34.9375V13.0625H28.9792V19.0417H36.9375"
                                                            stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                                        <path d="M46.8751 5.125V13.0625H40.9167V5.125" stroke="white"
                                                            stroke-width="1.5" stroke-miterlimit="10" />
                                                        <path d="M46.875 21.0208V13.0625" stroke="white" stroke-width="1.5"
                                                            stroke-miterlimit="10" />
                                                    </svg>
                                                </div>
                                                <div className="cu-service-card-body">
                                                    <h3 className="cu-service-card-title">Direct Communication</h3>
                                                    <p className="cu-service-card-description">We make talking easy. Our platform connects skilled nursing centers directly with potential patients. We want you to communicate quickly and clearly, so you can provide better care.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Choose Section End --> */}

                {/* <!-- How Works Section Start --> */}
                <section className="cu-how-work-section" id="how_works" ref={howRef}>
                    <div className="container">
                        <h3 className="cu-section-subtitle">How it works</h3>
                        <h3 className="cu-section-title">Want to know how we help? Visit our homepage for a simple guide on how ContigoU makes connecting easy.</h3>
                        <div className="cu-how-work-content">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-4">
                                    <div className="cu-how-work-card">
                                        <div className="cu-how-work-card-img">
                                            <img src="assets/images/help-img1.png" alt="How Work" className="img-fluid" />
                                        </div>
                                        <div className="cu-how-work-card-body">
                                            <div className="cu-how-work-step">
                                                <p className="cu-how-work-step-number"><span>1</span></p>
                                            </div>
                                            <div className="cu-how-work-detail">
                                                <h4 className="cu-how-work-card-title">Choose the Plan</h4>
                                                <p className="cu-how-work-card-description">Begin by selecting a plan tailored to your facility's needs. Our range of options ensures there's a perfect fit for every type of healthcare provider.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-4">
                                    <div className="cu-how-work-card">
                                        <div className="cu-how-work-card-img">
                                            <img src="assets/images/help-img2.png" alt="How Work" className="img-fluid" />
                                        </div>
                                        <div className="cu-how-work-card-body">
                                            <div className="cu-how-work-step">
                                                <p className="cu-how-work-step-number"><span>2</span></p>
                                            </div>
                                            <div className="cu-how-work-detail">
                                                <h4 className="cu-how-work-card-title">Compare</h4>
                                                <p className="cu-how-work-card-description">Easily compare different plans and services on our platform. We provide clear, detailed comparisons to help you make an informed decision.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-4">
                                    <div className="cu-how-work-card">
                                        <div className="cu-how-work-card-img">
                                            <img src="assets/images/help-img3.png" alt="How Work" className="img-fluid" />
                                        </div>
                                        <div className="cu-how-work-card-body">
                                            <div className="cu-how-work-step">
                                                <p className="cu-how-work-step-number"><span>3</span></p>
                                            </div>
                                            <div className="cu-how-work-detail">
                                                <h4 className="cu-how-work-card-title">Select the Best One</h4>
                                                <p className="cu-how-work-card-description">Choose the best plan that aligns with your facility's goals and patient care standards. With ContigoU, making the optimal choice is straightforward and efficient.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <Link to="/register" className="btn btn-info">Get Strated</Link>
                        </div>
                    </div>
                </section>
                {/* <!-- How Works Section End --> */}

                {/* <!-- Subscriptions Section Start --> */}
                <section className="cu-subscription-section" id="subscriptions" ref={subscriptionRef}>
                    <div className="container cu-service-content">
                        <h3 className="cu-section-subtitle">Subscriptions</h3>
                        <h3 className="cu-section-title">Find best inpatient care facility for you after hospitalization</h3>
                        {plans && <div class="switch-toggle switch-3 switch-candy">

                            <input id="on" name="state-d" type="radio" checked={planshow === "a-month" ? true : false} onChange={() => setPlanShow("a-month")} />
                            <label for="on" onclick="">1 Month</label>

                            <input id="na" name="state-d" type="radio" checked={planshow === "months" ? true : false} onChange={() => setPlanShow("months")} />
                            <label for="na" class="disabled" onclick="">6 Months</label>

                            <input id="off" name="state-d" type="radio" checked={planshow === "a-year" ? true : false} onChange={() => setPlanShow("a-year")} />
                            <label for="off" onclick="">1 Year</label>

                        </div>}
                        <div className="cu-subscription-plan-content">
                            {plans.map((plan, index) => {
                                return (
                                    <div
                                        // className={plan.plan_name.toLowerCase().includes('basic') ? "cu-plan-card" : "cu-plan-card cu-advance-plan-card"}
                                        className={index === 0 ? "cu-plan-card cu-basic-plan-card" : index === 1 ? "cu-plan-card cu-advance-plan-card" : "cu-plan-card cu-premium-plan-card"}

                                    >
                                        <div className="cu-plan-card-head">
                                            <span className="cu-plan-card-icon">
                                                {/* {plan.plan_name.toLowerCase().includes('basic') ? */}
                                                {index === 0 ?
                                                    <svg width="73" height="73" viewBox="0 0 73 73" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M36.4602 70C54.7845 70 69.676 55.2456 69.676 37C69.676 18.7544 54.7845 4 36.4602 4C18.1359 4 3.24438 18.7544 3.24438 37C3.24438 55.2456 18.1359 70 36.4602 70Z"
                                                            stroke="url(#paint0_linear_526_2835)" stroke-width="6" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M50.7764 25.7672C50.7764 23.6961 49.0853 22.0172 46.9994 22.0172L32.7555 22.0172C31.7538 22.0171 30.7931 22.4123 30.0848 23.1156L16.9472 36.1593C15.4722 37.6238 15.4722 39.9982 16.9471 41.4626L31.191 55.6048C32.666 57.0692 35.0575 57.0692 36.5325 55.6048L49.6701 42.561C50.3784 41.8577 50.7764 40.9039 50.7764 39.9093L50.7764 25.7672ZM44.0995 31.7399C44.0995 30.0314 42.7045 28.6463 40.9837 28.6463C39.2629 28.6463 37.8678 30.0314 37.8678 31.7399C37.8678 33.4485 39.2629 34.8335 40.9837 34.8335C42.7045 34.8335 44.0995 33.4485 44.0995 31.7399Z"
                                                            fill="#FF7800" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_526_2835" x1="21.3523" y1="23" x2="72.9087"
                                                                y2="67.3651" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#FF7800" />
                                                                <stop offset="1" stop-color="#FFE6D1" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                    : index === 1 ?
                                                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="35.9998" cy="36" r="33" stroke="#923EF5" stroke-width="6" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M49.3382 23.4377C49.4574 23.4469 49.5521 23.5416 49.5613 23.6608L49.7865 26.5889C50.1944 31.892 48.2647 37.1086 44.5038 40.8696C43.1591 42.2143 41.6121 43.3401 39.9191 44.2064L27.6053 50.5064C27.4063 50.6083 27.1644 50.5701 27.0063 50.4121L22.5869 45.9927C22.4288 45.8346 22.3907 45.5927 22.4925 45.3936L28.7926 33.0799C29.6588 31.3868 30.7847 29.8399 32.1294 28.4952C35.8904 24.7343 41.1069 22.8045 46.41 23.2124L49.3382 23.4377ZM43.8433 31.452C43.5431 30.3313 42.6677 29.4559 41.5469 29.1556C40.4262 28.8553 39.2304 29.1757 38.41 29.9962C37.5896 30.8166 37.2692 32.0124 37.5695 33.1331C37.8698 34.2538 38.7452 35.1292 39.8658 35.4295C40.9866 35.7298 42.1824 35.4094 43.0028 34.589C43.8232 33.7685 44.1436 32.5727 43.8433 31.452Z"
                                                                fill="#6C1AFC" />
                                                            <path
                                                                d="M27.2357 32.0099L22.9173 32.0097C20.9971 32.0096 19.2466 33.1096 18.4135 34.8397L15.5226 40.8439C15.2627 41.3837 15.6562 42.0097 16.2553 42.0094L22.1179 42.0097L27.1233 32.2263C27.1603 32.154 27.1978 32.0817 27.2357 32.0099Z"
                                                                fill="#6C1AFC" />
                                                            <path
                                                                d="M30.7008 51.0292V56.4547C30.7007 57.0544 31.3274 57.4482 31.8678 57.188L37.8698 54.2981C39.6005 53.4649 40.7008 51.7139 40.7008 49.7931V45.9129L30.7008 51.0292Z"
                                                                fill="#6C1AFC" />
                                                        </svg> :
                                                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M54.9932 26.9949L49.5245 43.8258H23.0742L17.6055 26.9949L28.395 33.0782L36.2993 18.1229L44.2037 33.0782L54.9932 26.9949Z" fill="#00657B"></path>
                                                            <path d="M54.9932 26.9949L56.182 27.3812C56.3413 26.8912 56.1835 26.3538 55.7848 26.0275C55.3861 25.7013 54.8281 25.653 54.3793 25.906L54.9932 26.9949ZM49.5245 43.8258V45.0758C50.0661 45.0758 50.546 44.7271 50.7133 44.212L49.5245 43.8258ZM23.0742 43.8258L21.8854 44.212C22.0527 44.7271 22.5327 45.0758 23.0742 45.0758V43.8258ZM17.6055 26.9949L18.2195 25.906C17.7707 25.653 17.2126 25.7013 16.8139 26.0275C16.4152 26.3538 16.2575 26.8912 16.4167 27.3812L17.6055 26.9949ZM28.395 33.0782L27.7811 34.167C28.075 34.3327 28.4235 34.3726 28.7472 34.2775C29.0709 34.1825 29.3425 33.9606 29.5002 33.6622L28.395 33.0782ZM36.2994 18.1229L37.4045 17.5388C37.188 17.1292 36.7627 16.8729 36.2994 16.8729C35.836 16.8729 35.4107 17.1292 35.1942 17.5388L36.2994 18.1229ZM44.2037 33.0782L43.0986 33.6622C43.2563 33.9606 43.5278 34.1825 43.8516 34.2775C44.1753 34.3726 44.5237 34.3327 44.8177 34.167L44.2037 33.0782ZM53.8044 26.6087L48.3357 43.4395L50.7133 44.212L56.182 27.3812L53.8044 26.6087ZM49.5245 42.5758H23.0742V45.0758H49.5245V42.5758ZM24.263 43.4395L18.7943 26.6087L16.4167 27.3812L21.8854 44.212L24.263 43.4395ZM16.9916 28.0838L27.7811 34.167L29.0089 31.9893L18.2195 25.906L16.9916 28.0838ZM29.5002 33.6622L37.4045 18.707L35.1942 17.5388L27.2899 32.4941L29.5002 33.6622ZM35.1942 18.707L43.0986 33.6622L45.3088 32.4941L37.4045 17.5388L35.1942 18.707ZM44.8177 34.167L55.6072 28.0838L54.3793 25.906L43.5898 31.9893L44.8177 34.167Z" fill="#00657B"></path>
                                                            <path d="M48.7993 49.3729H23.7993" stroke="#00657B" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"></path>
                                                            <circle cx="36" cy="36" r="33" stroke="url(#paint0_linear_773_525)" stroke-width="6"></circle>
                                                            <defs>
                                                                <linearGradient id="paint0_linear_773_525" x1="21" y1="22" x2="72.5" y2="66" gradientUnits="userSpaceOnUse">
                                                                    <stop stop-color="#00657B"></stop>
                                                                    <stop offset="1" stop-color="#C4F4FF"></stop>
                                                                </linearGradient>
                                                            </defs>
                                                        </svg>
                                                }
                                            </span>
                                            <h4 className="cu-plan-card-title">{plan.plan_name}</h4>
                                        </div>
                                        <div className="cu-plan-card-body">
                                            {/* <ul className="cu-plan-card-feature-list"> */}
                                            {
                                                parse(plan.description.replace('<ul', "<ul class='cu-plan-card-feature-list'")
                                                    .replaceAll('<li', "<li class='cu-plan-card-feature-item'")
                                                )}
                                            {/* <li className="cu-plan-card-fea ture-item">Access To the ContigoU Web Panel</li>
                                            <li className="cu-plan-card-feature-item">Unlimited Services</li>
                                            <li className="cu-plan-card-feature-item">Unlimited Beds</li>
                                            <li className="cu-plan-card-feature-item">Business profile Management</li>
                                            <li className="cu-plan-card-feature-item">Enquiry Management & Access to
                                                customer contact information and
                                                their details.</li>
                                            <li className="cu-plan-card-feature-item">Premium Customer Support</li>
                                            <li className="cu-plan-card-feature-item">Cancel Anytime</li> */}
                                            {/* </ul> */}
                                        </div>
                                        <div className="cu-plan-card-footer">
                                            <div className="cu-plan-expire">
                                                <h3 className="cu-plan-expire-date cu-plan-amount">{
                                                    planshow === "a-month" ? `$${plan.amount}/Month` :
                                                        planshow === "months" ? `$${plan.amount_months}/6 Months` :
                                                            `$${plan.amount_year}/Year`
                                                }</h3>
                                                {/* <label className="cu-plan-expire-label">When Paid Annually</label> */}
                                            </div>
                                            {/* <Link to='/payment-method' state={plan} className="btn btn-info">Get Started</Link> */}
                                            <Link to='/login' className="btn btn-info">Get Started</Link>
                                            {/* <a href="#" className="btn btn-info">Get Strated</a> */}
                                        </div>
                                    </div>
                                )
                            })}
                            {/* <div className="cu-plan-card">
                                <div className="cu-plan-card-head">
                                    <span className="cu-plan-card-icon">
                                        <svg width="73" height="73" viewBox="0 0 73 73" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M36.4602 70C54.7845 70 69.676 55.2456 69.676 37C69.676 18.7544 54.7845 4 36.4602 4C18.1359 4 3.24438 18.7544 3.24438 37C3.24438 55.2456 18.1359 70 36.4602 70Z"
                                                stroke="url(#paint0_linear_526_2835)" stroke-width="6" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M50.7764 25.7672C50.7764 23.6961 49.0853 22.0172 46.9994 22.0172L32.7555 22.0172C31.7538 22.0171 30.7931 22.4123 30.0848 23.1156L16.9472 36.1593C15.4722 37.6238 15.4722 39.9982 16.9471 41.4626L31.191 55.6048C32.666 57.0692 35.0575 57.0692 36.5325 55.6048L49.6701 42.561C50.3784 41.8577 50.7764 40.9039 50.7764 39.9093L50.7764 25.7672ZM44.0995 31.7399C44.0995 30.0314 42.7045 28.6463 40.9837 28.6463C39.2629 28.6463 37.8678 30.0314 37.8678 31.7399C37.8678 33.4485 39.2629 34.8335 40.9837 34.8335C42.7045 34.8335 44.0995 33.4485 44.0995 31.7399Z"
                                                fill="#FF7800" />
                                            <defs>
                                                <linearGradient id="paint0_linear_526_2835" x1="21.3523" y1="23" x2="72.9087"
                                                    y2="67.3651" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FF7800" />
                                                    <stop offset="1" stop-color="#FFE6D1" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                    <h4 className="cu-plan-card-title">Basic</h4>
                                </div>
                                <div className="cu-plan-card-body">
                                    <ul className="cu-plan-card-feature-list">
                                        <li className="cu-plan-card-feature-item">Access To the ContigoU Web Panel</li>
                                        <li className="cu-plan-card-feature-item">Unlimited Services</li>
                                        <li className="cu-plan-card-feature-item">Unlimited Beds</li>
                                        <li className="cu-plan-card-feature-item">Business profile Management</li>
                                        <li className="cu-plan-card-feature-item">Enquiry Management & Access to
                                            customer contact information and
                                            their details.</li>
                                        <li className="cu-plan-card-feature-item">Premium Customer Support</li>
                                        <li className="cu-plan-card-feature-item">Cancel Anytime</li>
                                    </ul>
                                </div>
                                <div className="cu-plan-card-footer">
                                    <div className="cu-plan-expire">
                                        <h3 className="cu-plan-expire-date cu-plan-amount">$210/Month</h3>
                                        <label className="cu-plan-expire-label">When Paid Annually</label>
                                    </div>
                                    <a href="#" className="btn btn-info">Get Strated</a>
                                </div>
                            </div>
                            <div className="cu-plan-card cu-advance-plan-card">
                                <div className="cu-plan-card-head">
                                    <span className="cu-plan-card-icon">
                                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="35.9998" cy="36" r="33" stroke="#923EF5" stroke-width="6" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M49.3382 23.4377C49.4574 23.4469 49.5521 23.5416 49.5613 23.6608L49.7865 26.5889C50.1944 31.892 48.2647 37.1086 44.5038 40.8696C43.1591 42.2143 41.6121 43.3401 39.9191 44.2064L27.6053 50.5064C27.4063 50.6083 27.1644 50.5701 27.0063 50.4121L22.5869 45.9927C22.4288 45.8346 22.3907 45.5927 22.4925 45.3936L28.7926 33.0799C29.6588 31.3868 30.7847 29.8399 32.1294 28.4952C35.8904 24.7343 41.1069 22.8045 46.41 23.2124L49.3382 23.4377ZM43.8433 31.452C43.5431 30.3313 42.6677 29.4559 41.5469 29.1556C40.4262 28.8553 39.2304 29.1757 38.41 29.9962C37.5896 30.8166 37.2692 32.0124 37.5695 33.1331C37.8698 34.2538 38.7452 35.1292 39.8658 35.4295C40.9866 35.7298 42.1824 35.4094 43.0028 34.589C43.8232 33.7685 44.1436 32.5727 43.8433 31.452Z"
                                                fill="#6C1AFC" />
                                            <path
                                                d="M27.2357 32.0099L22.9173 32.0097C20.9971 32.0096 19.2466 33.1096 18.4135 34.8397L15.5226 40.8439C15.2627 41.3837 15.6562 42.0097 16.2553 42.0094L22.1179 42.0097L27.1233 32.2263C27.1603 32.154 27.1978 32.0817 27.2357 32.0099Z"
                                                fill="#6C1AFC" />
                                            <path
                                                d="M30.7008 51.0292V56.4547C30.7007 57.0544 31.3274 57.4482 31.8678 57.188L37.8698 54.2981C39.6005 53.4649 40.7008 51.7139 40.7008 49.7931V45.9129L30.7008 51.0292Z"
                                                fill="#6C1AFC" />
                                        </svg>
                                    </span>
                                    <h4 className="cu-plan-card-title">Advance</h4>
                                </div>
                                <div className="cu-plan-card-body">
                                    <ul className="cu-plan-card-feature-list">
                                        <li className="cu-plan-card-feature-item">Access To the ContiguoU Web Panel
                                        </li>
                                        <li className="cu-plan-card-feature-item">5 Services</li>
                                        <li className="cu-plan-card-feature-item">Upto 100 Beds</li>
                                        <li className="cu-plan-card-feature-item">Business profile Management</li>
                                        <li className="cu-plan-card-feature-item">Enquiry Management & Access to
                                            customer contact information and
                                            their details.</li>
                                        <li className="cu-plan-card-feature-item">Premium Customer Support</li>
                                    </ul>
                                </div>
                                <div className="cu-plan-card-footer">
                                    <div className="cu-plan-expire">
                                        <h3 className="cu-plan-expire-date cu-plan-amount">$300/Month</h3>
                                        <label className="cu-plan-expire-label">When Paid Annually</label>
                                    </div>
                                    <a href="#" className="btn btn-blue">Get Strated</a>
                                </div>
                            </div>
                            <div className="cu-plan-card cu-premium-plan-card">
                                <div className="cu-plan-card-head">
                                    <span className="cu-plan-card-icon">
                                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M54.9932 26.9949L49.5245 43.8258H23.0742L17.6055 26.9949L28.395 33.0782L36.2993 18.1229L44.2037 33.0782L54.9932 26.9949Z"
                                                fill="#00657B" />
                                            <path
                                                d="M54.9932 26.9949L56.182 27.3812C56.3413 26.8912 56.1835 26.3538 55.7848 26.0275C55.3861 25.7013 54.8281 25.653 54.3793 25.906L54.9932 26.9949ZM49.5245 43.8258V45.0758C50.0661 45.0758 50.546 44.7271 50.7133 44.212L49.5245 43.8258ZM23.0742 43.8258L21.8854 44.212C22.0527 44.7271 22.5327 45.0758 23.0742 45.0758V43.8258ZM17.6055 26.9949L18.2195 25.906C17.7707 25.653 17.2126 25.7013 16.8139 26.0275C16.4152 26.3538 16.2575 26.8912 16.4167 27.3812L17.6055 26.9949ZM28.395 33.0782L27.7811 34.167C28.075 34.3327 28.4235 34.3726 28.7472 34.2775C29.0709 34.1825 29.3425 33.9606 29.5002 33.6622L28.395 33.0782ZM36.2994 18.1229L37.4045 17.5388C37.188 17.1292 36.7627 16.8729 36.2994 16.8729C35.836 16.8729 35.4107 17.1292 35.1942 17.5388L36.2994 18.1229ZM44.2037 33.0782L43.0986 33.6622C43.2563 33.9606 43.5278 34.1825 43.8516 34.2775C44.1753 34.3726 44.5237 34.3327 44.8177 34.167L44.2037 33.0782ZM53.8044 26.6087L48.3357 43.4395L50.7133 44.212L56.182 27.3812L53.8044 26.6087ZM49.5245 42.5758H23.0742V45.0758H49.5245V42.5758ZM24.263 43.4395L18.7943 26.6087L16.4167 27.3812L21.8854 44.212L24.263 43.4395ZM16.9916 28.0838L27.7811 34.167L29.0089 31.9893L18.2195 25.906L16.9916 28.0838ZM29.5002 33.6622L37.4045 18.707L35.1942 17.5388L27.2899 32.4941L29.5002 33.6622ZM35.1942 18.707L43.0986 33.6622L45.3088 32.4941L37.4045 17.5388L35.1942 18.707ZM44.8177 34.167L55.6072 28.0838L54.3793 25.906L43.5898 31.9893L44.8177 34.167Z"
                                                fill="#00657B" />
                                            <path d="M48.7993 49.3729H23.7993" stroke="#00657B" stroke-width="6"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <circle cx="36" cy="36" r="33" stroke="url(#paint0_linear_773_525)"
                                                stroke-width="6" />
                                            <defs>
                                                <linearGradient id="paint0_linear_773_525" x1="21" y1="22" x2="72.5" y2="66"
                                                    gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#00657B" />
                                                    <stop offset="1" stop-color="#C4F4FF" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                    <h4 className="cu-plan-card-title">Premium</h4>
                                </div>
                                <div className="cu-plan-card-body">
                                    <ul className="cu-plan-card-feature-list">
                                        <li className="cu-plan-card-feature-item">Access To the ContiguoU Web Panel
                                        </li>
                                        <li className="cu-plan-card-feature-item">Featured Listing </li>
                                        <li className="cu-plan-card-feature-item">Unlimited Services</li>
                                        <li className="cu-plan-card-feature-item">Unlimited beds</li>
                                        <li className="cu-plan-card-feature-item">Business profile Management</li>
                                        <li className="cu-plan-card-feature-item">Enquiry Management & Access to
                                            customer contact information and
                                            their details.</li>
                                        <li className="cu-plan-card-feature-item">Premium Customer Support</li>
                                    </ul>
                                </div>
                                <div className="cu-plan-card-footer">
                                    <div className="cu-plan-expire">
                                        <h3 className="cu-plan-expire-date cu-plan-amount">$650/Month</h3>
                                        <label className="cu-plan-expire-label">When Paid Annually</label>
                                    </div>
                                    <a href="#" className="btn btn-info">Get Strated</a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
                {/* <!-- Subscriptions Section End --> */}

                {/* <!-- Contact Section Start --> */}
                <section className="cu-contact-section" id="contact_us" ref={contactRef}>
                    <div className="container">
                        <div className="cu-contact-heading">
                            <h3 className="cu-section-subtitle">Need Help</h3>
                            <h2 className="cu-section-title">Feel Free to Contact Us for Any Questions or Concerns</h2>
                        </div>
                        <div className="cu-contact-details">
                            <div className="cu-contact-item">
                                <div className="cu-contact-item-icon">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M22.3833 13.1666C23.5622 13.171 24.6915 13.6413 25.5251 14.4749C26.3587 15.3084 26.8289 16.4378 26.8333 17.6166"
                                            stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path
                                            d="M22.3833 7.83337C24.978 7.83337 27.4664 8.86412 29.3012 10.6988C31.1359 12.5336 32.1666 15.022 32.1666 17.6167"
                                            stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path
                                            d="M22.3833 2.5C26.3925 2.5 30.2375 4.09264 33.0724 6.92757C35.9073 9.76249 37.5 13.6075 37.5 17.6167"
                                            stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path
                                            d="M31.25 35.6333C27.2 39.6666 17.9166 36.95 10.4833 29.5166C3.04997 22.0833 0.333305 12.8 4.36664 8.74998L8.79997 4.31665L17.35 12.8667L14.15 16.1C13.5067 16.7465 13.1457 17.6213 13.1457 18.5333C13.1457 19.4453 13.5067 20.3202 14.15 20.9666L19.0333 25.85C19.6798 26.4932 20.5547 26.8543 21.4666 26.8543C22.3786 26.8543 23.2535 26.4932 23.9 25.85L27.1333 22.65L35.6833 31.2L31.25 35.6333Z"
                                            stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                    </svg>
                                </div>
                                <div className="cu-contact-item__inner">
                                    <h3 className="cu-contact-item-title">For Any Help Call US</h3>
                                    <p className="cu-contact-item-text">(916) 9342471</p>
                                </div>
                            </div>
                            <div className="cu-contact-item">
                                <div className="cu-contact-item-icon">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M37.5 7.26672H2.5V32.7167H37.5V7.26672Z" stroke="white" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                        <path d="M2.5 7.26672L20 24.7667L37.5 7.26672" stroke="white" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                    </svg>
                                </div>
                                <div className="cu-contact-item__inner">
                                    <h3 className="cu-contact-item-title">For more details mail us on</h3>
                                    <p className="cu-contact-item-text">social@contigou.com</p>
                                </div>
                            </div>
                        </div>
                        <ContactUs />
                    </div>
                </section>
                {/* <!-- Contact Section End --> */}

            </main>

            <Footer value={"Home"} smoothScrollToRef={smoothScrollToRef} howRef={howRef} contactRef={contactRef} />
        </div>
    )
}

export default Index