import React, { useState } from 'react';

const Search = ({ onSearch , placeholder}) => {
  const [search, setSearch] = useState('');

  const onInputChange = (value) => {
    setSearch(value);
    onSearch(value);
  };
  return (
    <>
      <input
        type="text"
        className="form-control"
        style={{fontSize:"13px"}}
        placeholder={placeholder || "Search By Patient Name and Date"}
        value={search}
        onChange={(e) => onInputChange(e.target.value)}
      />
      <button className="cu-input-icon btn-icon">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4"
            d="M9.17533 16.6832C11.1667 16.6832 13.0764 15.8921 14.4845 14.484C15.8926 13.0759 16.6837 11.1662 16.6837 9.17484C16.6837 7.1835 15.8926 5.27373 14.4845 3.86564C13.0764 2.45756 11.1667 1.6665 9.17533 1.6665C7.18399 1.6665 5.27422 2.45756 3.86613 3.86564C2.45805 5.27373 1.66699 7.1835 1.66699 9.17484C1.66699 11.1662 2.45805 13.0759 3.86613 14.484C5.27422 15.8921 7.18399 16.6832 9.17533 16.6832Z"
            fill="#78797A" />
          <path
            d="M17.5103 15.7917C17.2353 15.2833 16.6519 15 15.8686 15C15.2769 15 14.7686 15.2417 14.4686 15.6583C14.1686 16.075 14.1019 16.6333 14.2853 17.1917C14.6436 18.275 15.2686 18.5167 15.6103 18.5583C15.6603 18.5667 15.7103 18.5667 15.7686 18.5667C16.1353 18.5667 16.7019 18.4083 17.2519 17.5833C17.6936 16.9417 17.7769 16.3 17.5103 15.7917Z"
            fill="#78797A" />
        </svg>
      </button>
    </>
  );
};

export default Search;
