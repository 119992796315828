import axios from 'axios';
import React, { useState } from 'react'
import { CONTIGO_API_URL } from '../BaseURL'
import { toast, Toaster } from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2'
import { Link, useNavigate } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css'
import City from '../city.json'


const Register = () => {

    const [register, setRegister] = useState({
        fname: "", lname: "", businessBox: false, email: "", number: "", state: "", city: "", centerName: "", businessId: "",
        license: "", businessEmail: "", bed: "", about: "", password: "", cpassword: "", pwdShow: true, cpwdShow: true
    })
    const [error, setError] = useState({})
    const [disable, setDisable] = useState(false)
    const nav = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);


    // state filter 
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');


    const handleStateChange = (e) => {
        const selectedState = e.target.value;
        setSelectedState(selectedState);
        setSelectedCity(''); // Reset the selected city when the state changes
    };

    console.log(selectedState, "state")

    const handleCityChange = (e) => {
        const selectedCity = e.target.value;
        setSelectedCity(selectedCity);
    };

    console.log(selectedCity, "selected City")


    const defaultCountry = City.find((c) => c.iso2 === 'US');
    const states = defaultCountry ? defaultCountry.states : [];
    const cities = states.find((s) => s.name === selectedState)?.cities || [];

    //   end state filter

    const [img, setImg] = useState({
        src: "",
        alt: "",
    })

    const handleNext = (e) => {
        e.preventDefault();
        if (validation()) {
            setCurrentStep(2)
        }
    }

    const handleStepClick = (step) => {
        setCurrentStep(step);
    };


    const handleChange = (e) => {
        setRegister({ ...register, [e.target.name]: e.target.value?.trimStart() })
        console.log(register.number)
    }

    // const handleImage = (e) => {
    //     if (e?.target?.files[0]) {
    //         setImg({
    //             src: URL.createObjectURL(e?.target?.files[0]),
    //             alt: e?.target?.files[0].name,
    //         });
    //         setDisable(true);
    //         const myurl = `${CONTIGO_API_URL}api/upload-image`;
    //         var bodyFormData = new FormData();
    //         bodyFormData.append("image", e?.target?.files[0]);

    //         axios({
    //             method: "post",
    //             url: myurl,
    //             data: bodyFormData,
    //         })
    //             .then((result) => {
    //                 console.log("=====", result);

    //                 if (result?.data?.success === true) {
    //                     //console.log('Success:=====',result?.data?.data?.filepath_url);
    //                     setRegister({ ...register, license: result?.data?.data?.url });
    //                     setImg({ alt: result?.data?.data?.filepath_url, src: result?.data?.data?.url })
    //                     setDisable(false);
    //                 } else {
    //                     console.log("Error:=====");
    //                     // console.log('Error:=====', result?.data);
    //                     setImg({ alt: "", src: "" })
    //                     setRegister({ ...register, image: "" });
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 //console.log('Errors', error);
    //                 setImg({ alt: "", src: "" })
    //                 setRegister({ ...register, image: "" });
    //                 setDisable(false);
    //                 toast.error("Unable to upload image");
    //             });
    //     }
    // };

    const handleImage = (e) => {
        if (e?.target?.files[0]) {
            const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
            const fileType = e.target.files[0].type;

            if (allowedTypes.includes(fileType)) {
                setImg({
                    src: URL.createObjectURL(e?.target?.files[0]),
                    alt: e?.target?.files[0].name,
                });
                setDisable(true);
                const myurl = `${CONTIGO_API_URL}api/upload-image`;
                var bodyFormData = new FormData();
                bodyFormData.append("image", e?.target?.files[0]);

                axios({
                    method: "post",
                    url: myurl,
                    data: bodyFormData,
                })
                    .then((result) => {
                        console.log("=====", result);

                        if (result?.data?.success === true) {
                            setRegister({ ...register, license: result?.data?.data?.url });
                            setImg({ alt: result?.data?.data?.filepath_url, src: result?.data?.data?.url })
                            setDisable(false);
                        } else {
                            console.log("Error:=====");
                            setImg({ alt: "", src: "" })
                            setRegister({ ...register, image: "" });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setImg({ alt: "", src: "" })
                        setRegister({ ...register, image: "" });
                        setDisable(false);
                        toast.error("Unable to upload image");
                    });
            } else {
                // Invalid file type, show error message
                setImg({ alt: "", src: "" });
                setRegister({ ...register, image: "" });
                setDisable(false);
                toast.error("Invalid file type. Only PDF, JPG, and PNG are allowed.");
            }
        }
    };



    const handleSignUp = (e) => {
        e.preventDefault();
        if (validation2()) {
            setDisable(true)
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('email', register.email);
            bodyFormData.append('business_email', register.businessEmail);
            bodyFormData.append('phone_number', parseInt(register.number.split('-')[1]));
            bodyFormData.append('country_code', parseInt(register.number.split('-')[0]));

            console.log(register.businessBox, "business box");

            axios({
                method: 'post',
                url: `${CONTIGO_API_URL}api/nursing-user/send-otp`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                console.log("@@", response?.data)
                if (response?.data?.success === true) {
                    setTimeout(() => {
                        nav('/otp-verification', { state: { register: { ...register }, type: 'signup', selectedCity, selectedState } })
                    }, 1500)
                    toast.success(response?.data?.message);
                    setDisable(false)
                }
                else {
                    toast.error(response?.data?.message);
                }
            }).catch((err) => {
                console.log(err);
                toast.error(err?.response?.data?.message);
                if (err.response.data.type === 1) {
                    setError({ email_err: err.response.data.message })
                    setDisable(false)
                } else if (err.response.data.type === 2) {
                    setError({ businessEmail_err: err.response.data.message })
                    setDisable(false)
                } else if (err.response.data.type === 0) {
                    setError({ number_err: err?.response?.data?.message })
                    setDisable(false)
                } else {
                    toast.error(err?.response?.data?.message);
                }
            })
        }
    }

    const [isChecked, setIsChecked] = useState(false);


    function handleCheckboxChange(e) {
        setRegister({ ...register, [e.target.name]: e.target.checked });
        setIsChecked(e.target.checked);
    }

    const validation = () => {
        let isValid = true;
        let err = {};

        if (!register.fname) {
            isValid = false;
            err['fname_err'] = "Please enter first name"
        }

        if (!register.lname) {
            isValid = false;
            err['lname_err'] = "Please enter last name"
        }

        if (!register.email) {
            isValid = false;
            err['email_err'] = "Please enter email"
        } else if (typeof register.email !== "undefined") {
            let lastAtPos = register.email.lastIndexOf('@');
            let lastDotPos = register.email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && register.email.indexOf('@@') === -1 && lastDotPos > 2 && (register.email.length - lastDotPos) > 2)) {
                isValid = false;
                err["email_err"] = "Email is not valid";
            }
        }

        if (!register.number) {
            isValid = false;
            err['number_err'] = "Please enter mobile number"
        } else {
            const phoneRegex = /^\d{1,2}-\d{10}$/; // Updated regular expression for phone number validation
            if (!phoneRegex.test(register.number)) {
                isValid = false;
                err['number_err'] = "Please enter a valid mobile number";
            }
        }

        if (!selectedState) {
            isValid = false;
            err['state_err'] = "Please select state"
        }

        if (!selectedCity) {
            isValid = false;
            err['city_err'] = "Please select city"
        }

        setError(err);
        return isValid
    }

    const validation2 = () => {
        let isValid = true;
        let err = {};

        if (!register.centerName) {
            isValid = false;
            err['centerName_err'] = "Please enter center name"
        }

        if (!register.businessId) {
            isValid = false;
            err['businessId_err'] = "Please enter business id"
        }

        if (!register.businessEmail) {
            isValid = false;
            err['businessEmail_err'] = "Please enter businessEmail"
        } else if (typeof register.businessEmail !== "undefined") {
            let lastAtPos = register.businessEmail.lastIndexOf('@');
            let lastDotPos = register.businessEmail.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && register.businessEmail.indexOf('@@') === -1 && lastDotPos > 2 && (register.businessEmail.length - lastDotPos) > 2)) {
                isValid = false;
                err["businessEmail_err"] = "Email is not valid";
            }
        }

        if (!register.password) {
            isValid = false;
            err['password_err'] = "Please enter password"
        } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}/.test(register.password)) {
            isValid = false;
            err['password_err'] = "Password must be at least 8 characters long, contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character";
        }

        if (!register.cpassword) {
            isValid = false;
            err['cpassword_err'] = "Please enter confirm password"
        }
        if (register.password && register.cpassword) {
            if (register.password !== register.cpassword) {
                isValid = false;
                err['cpassword_err'] = "Password doesn't match";
            }
        }

        if (!register.license) {
            isValid = false;
            err['license_err'] = "Please select the image"
        }

        if (!register.bed) {
            isValid = false;
            err['bed_err'] = "Please enter number of beds"
        }

        if (!register.about) {
            isValid = false;
            err['about_err'] = "Please enter about us field"
        }

        setError(err);
        return isValid
    }

    const handleChangeWithoutNumber = (e) => {
        const sanitizedValue = e.target.value.replace(/[^a-zA-Z\s]/g, '');
        setRegister({ ...register, [e.target.name]: sanitizedValue?.trimStart() })
    }

    return (
        <div className='cu-site'>
            <section className="cu-login-section">
                <div className="cu-login-section-content">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-12 col-xl-6">
                            <div className="cu-login-content">
                                <Link className="cu-login-top-logo" to="/">
                                    <img src="assets/images/white-logo.png" alt="Logo" className="img-fluid" />
                                </Link>
                                <div className="cu-login-content-area">
                                    <h2 className="cu-login-content-title">Patients are Waiting for you Register Now</h2>
                                    <div className="cu-login-logo">
                                        <Link to='/'>
                                            <img src="assets/images/login-logo.png" alt="login-logo" className="img-fluid" />
                                        </Link>
                                    </div>
                                    <div className="cu-login-left-img">
                                        <img src="assets/images/login-old-img.png" alt="login-old-img" className="img-fluid" />
                                    </div>
                                    <div className="cu-login-right-img">
                                        <img src="assets/images/login-boy-img.png" alt="login-boy-img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-12 col-xl-6">
                            <div className="cu-login-form-area">
                                <div className="cu-login-form-area__inner">
                                    <div className="cu-login-form-heading">
                                        <h1 className="cu-login-title">Sign Up</h1>
                                        <p className="cu-login-description">Register Your Nursing Home With Us</p>
                                    </div>

                                    <div className="cu-login-steps">
                                        <ul className="cu-login-step-list list-unstyled">
                                            <li className="cu-login-step-item">
                                                <span className="cu-login-step-count cu-login-step-count-1 active" style={{ cursor: "pointer" }}
                                                    onClick={() => handleStepClick(1)}
                                                >1</span>
                                            </li>
                                            <li className="cu-login-step-item">
                                                <span className={`cu-login-step-count cu-login-step-count-2 ${currentStep === 2 ? 'active' : ''}`}
                                                >2</span>
                                            </li>
                                        </ul>
                                    </div>
                                    {currentStep === 1 && (
                                        <div className={`cu-login-steps-form cu-registe-step-form cu-registe-steps-1 ${currentStep === 1 ? 'active' : 'd-none'}`}>
                                            <div className="cu-login-form">
                                                <form className="row">
                                                    <div className="col-12 mb-4">
                                                        <label htmlFor="inputFirstName" className="form-label">First Name</label>
                                                        <input type="text" className="form-control" id="inputFirstName" value={register.fname} name='fname' onChange={handleChangeWithoutNumber} />
                                                        <span className='error'>{error.fname_err}</span>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <label htmlFor="inputLastName" className="form-label">Last Name</label>
                                                        <input type="text" className="form-control" id="inputLastName" name='lname' onChange={handleChangeWithoutNumber} value={register.lname} />
                                                        <span className='error'>{error.lname_err}</span>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="cu-owner-check">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value={register.businessBox} name='businessBox' onChange={handleCheckboxChange} checked={isChecked}
                                                                    id="flexCheckDefault" />
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">Are you a
                                                                    Business Owner ?</label>
                                                            </div>
                                                            <span className='error'>{error.businessBox_err}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <label htmlFor="inputEmailAddress" className="form-label">Email Address</label>
                                                        <input type="text" className="form-control" id="inputEmailAddress" name='email' value={register.email} onChange={handleChange} />
                                                        <span className='error'>{error.email_err}</span>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <label htmlFor="inputEmailAddress" className="form-label">Phone Number</label>
                                                        <div className="select-code-group">
                                                            <PhoneInput
                                                                country='us'
                                                                inputClass='form-control'
                                                                inputExtraProps={{
                                                                    name: "number",
                                                                    required: true,
                                                                    autoFocus: true
                                                                }}
                                                                placeholder=""
                                                                name="number"
                                                                defaultCountry={"us"}
                                                                value={register.number}
                                                                onChange={(val, countryData) => {
                                                                    const numericValue = val.replace(/[^0-9]/g, ""); // Extract numeric value only
                                                                    const countryCodePrefix = "+" + countryData.dialCode; // Get country code prefix from countryData

                                                                    let formattedValue = numericValue;
                                                                    if (numericValue.startsWith(countryData.dialCode)) {
                                                                        formattedValue = `${countryData.dialCode}-${numericValue.substring(countryData.dialCode.length)}`;
                                                                    }
                                                                    // console.log(countryData)
                                                                    setRegister({ ...register, number: formattedValue });
                                                                }}
                                                            />



                                                            {/* <input type="text" className="form-control" id="inputEmailAddress" name='number' value={register.number} onChange={handleChange} /> */}
                                                            <span className='error'>{error.number_err}</span>
                                                            {/* <select className="form-control" id="countries">
                                                            <option value="GB" data-capital="London">+1</option>
                                                            <option value="FR" data-capital="Paris">+2</option>
                                                            <option value="IT" data-capital="Rome">+03</option>
                                                            <option value="CN" data-capital="Beijing">+98</option>
                                                            <option value="CN" data-capital="Beijing">+99</option>
                                                            <option value="DE" data-capital="Berlin">+45</option>
                                                            <option value="PL" data-capital="Warsaw">+88</option>
                                                        </select> */}
                                                        </div>
                                                    </div>
                                                    <div className="col col-12 col-md-6 mb-4">
                                                        <label htmlFor="inputState" className="form-label">State</label>
                                                        <select className="form-select" id="inputState"
                                                            value={selectedState}
                                                            onChange={handleStateChange} name='state'
                                                        // onChange={handleChange}
                                                        //  value={register.state}
                                                        >
                                                            <option value="">Select a state</option>
                                                            {states.map((state) => (
                                                                <option key={state.name} value={state.name}>
                                                                    {state.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <span className='error'>{error.state_err}</span>
                                                    </div>
                                                    <div className="col col-12 col-md-6 mb-4">
                                                        <label htmlFor="inputCity" className="form-label">City</label>

                                                        {cities.length > 0 ? (
                                                            <select className="form-select" id="inputCity"
                                                                // onChange={handleChange} 
                                                                value={selectedCity}
                                                                onChange={handleCityChange}
                                                                disabled={!selectedState}
                                                                name='city'
                                                            // value={register.city}
                                                            >
                                                                <option value="">Select a city</option>
                                                                {cities.map((city) => (
                                                                    <option key={city.id} value={city.name}>
                                                                        {city.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            <input type="text" className="form-control" id="inputEmailAddress" name='city' value={selectedCity} onChange={handleCityChange} disabled={!selectedState} />
                                                        )}

                                                        <span className='error'>{error.city_err}</span>
                                                    </div>
                                                    <div className="col-12">
                                                        <button type="button" onClick={handleNext} className="btn btn-info cu-register-step-btn w-100"
                                                        >Next
                                                        </button>
                                                        {/* <!-- <p className="cu-form-notes">Don’t Have an Account ? <a href="#">Create One</a></p> --> */}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )}
                                    {/* 2nd page */}
                                    {currentStep === 2 && (
                                        <div className={`cu-login-steps-form cu-registe-step-form cu-registe-steps-2 ${currentStep === 2 ? 'active' : 'd-none'}`}>
                                            <div className="cu-login-form">
                                                <form className="row" action="" >
                                                    <div className="col-12 mb-4">
                                                        <label htmlFor="inputCenterName" className="form-label">Center Name</label>
                                                        <input type="text" className="form-control" id="inputCenterName" name='centerName' value={register.centerName} onChange={handleChange} />
                                                        <span className='error'>{error.centerName_err}</span>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <label htmlFor="inputBusinessNumber" className="form-label">Business ID
                                                            Number</label>

                                                        <input type="text" className="form-control" id="inputBusinessNumber" name='businessId' value={register.businessId} onChange={handleChange} />
                                                        <span className='error'>{error.businessId_err}</span>

                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <label htmlFor="inputLicenseCopy" className="form-label">License Copy</label>
                                                        <div className="cu-upload-file-content">
                                                            <input type="file" className="form-control" onChange={handleImage} />
                                                            {img.src === "" ?

                                                                <div className="cu-upload-file-icon">
                                                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <g clip-path="url(#clip0_344_1961)">
                                                                            <path
                                                                                d="M20.0188 6.7002C19.7622 5.17522 19.0071 3.78349 17.8523 2.72735C16.5691 1.55276 14.901 0.90625 13.1638 0.90625C11.8214 0.90625 10.5136 1.2912 9.39328 2.01668C8.46052 2.61877 7.68569 3.43309 7.13788 4.39052C6.90099 4.3461 6.65423 4.32143 6.40746 4.32143C4.30999 4.32143 2.60241 6.02901 2.60241 8.12648C2.60241 8.39792 2.63202 8.65949 2.68137 8.91612C1.32418 9.90317 0.5 11.4923 0.5 13.1851C0.5 14.5521 1.00833 15.8797 1.93615 16.9309C2.88865 18.0068 4.14713 18.6434 5.48951 18.7175C5.50432 18.7175 5.51419 18.7175 5.529 18.7175H9.77329C10.1434 18.7175 10.4395 18.4214 10.4395 18.0512C10.4395 17.6811 10.1434 17.385 9.77329 17.385H5.54874C3.53023 17.2616 1.83251 15.3418 1.83251 13.1802C1.83251 11.7835 2.58266 10.4806 3.7918 9.77485C4.0731 9.61199 4.19155 9.27146 4.08297 8.96547C3.98427 8.69897 3.93492 8.41766 3.93492 8.11661C3.93492 6.75449 5.04534 5.64407 6.40746 5.64407C6.69864 5.64407 6.98489 5.69342 7.25139 5.79212C7.57711 5.91057 7.93738 5.76251 8.08544 5.45159C9.00833 3.49231 11.0022 2.22889 13.1687 2.22889C16.0805 2.22889 18.484 4.41026 18.7603 7.3023C18.7899 7.60335 19.017 7.84518 19.3131 7.89453C21.5093 8.26961 23.1675 10.298 23.1675 12.6126C23.1675 15.0654 21.2378 17.1974 18.859 17.38H15.2218C14.8516 17.38 14.5555 17.6761 14.5555 18.0463C14.5555 18.4164 14.8516 18.7125 15.2218 18.7125H18.8837C18.8985 18.7125 18.9133 18.7125 18.9331 18.7125C20.4383 18.604 21.8449 17.913 22.8911 16.7582C23.9325 15.6132 24.5 14.1425 24.5 12.6126C24.4951 9.84394 22.6049 7.38127 20.0188 6.7002Z"
                                                                                fill="#7D7D7D" />
                                                                            <path
                                                                                d="M16.4998 13.8172C16.7614 13.5556 16.7614 13.1361 16.4998 12.8745L12.9711 9.34585C12.8478 9.22247 12.675 9.14844 12.5023 9.14844C12.3296 9.14844 12.1568 9.21753 12.0335 9.34585L8.50477 12.8745C8.2432 13.1361 8.2432 13.5556 8.50477 13.8172C8.63308 13.9455 8.80582 14.0146 8.97362 14.0146C9.14141 14.0146 9.31415 13.9504 9.44246 13.8172L11.836 11.4236V22.4242C11.836 22.7943 12.1322 23.0904 12.5023 23.0904C12.8724 23.0904 13.1686 22.7943 13.1686 22.4242V11.4236L15.5621 13.8172C15.8188 14.0787 16.2383 14.0787 16.4998 13.8172Z"
                                                                                fill="#7D7D7D" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_344_1961">
                                                                                <rect width="24" height="24" fill="white"
                                                                                    transform="translate(0.5)" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </div>
                                                                :
                                                                <img src={img.src} alt={img.alt} style={{ height: "inherit", width: "auto", maxHeight: "100px" }} />
                                                            }
                                                            <p className="cu-upload-file-label">Upload Your Photo </p>
                                                        </div>
                                                        <span className='error'>{error.license_err}</span>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <label htmlFor="inputBusinessEmail" className="form-label">Business Email</label>
                                                        <input type="email" className="form-control" id="inputBusinessEmail" name='businessEmail' value={register.businessEmail} onChange={handleChange} />
                                                        <span className='error'>{error.businessEmail_err}</span>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <label htmlFor="inputNumberBeds" className="form-label">Number of Beds</label>
                                                        <input type="number" className="form-control" id="inputNumberBeds" onChange={handleChange} value={register.bed} name='bed' />
                                                        <span className='error'>{error.bed_err}</span>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <label htmlFor="inputAbout" className="form-label">How did you hear about us</label>
                                                        <input type="text" className="form-control" id="inputAbout" onChange={handleChange} name='about' value={register.about} />
                                                        <span className='error'>{error.about_err}</span>
                                                    </div>
                                                    <div className="col col-12 col-md-6 mb-4">
                                                        <label htmlFor="inputPassword" className="form-label">Password</label>
                                                        <div className="cu-input-icon-group">
                                                            <input type={register.pwdShow ? "password" : "text"} className="form-control" id="inputPassword" onChange={handleChange} name='password' value={register.password} />
                                                            {/* <span className="cu-input-icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                                                                    stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" />
                                                                <path
                                                                    d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
                                                                    stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" />
                                                            </svg>
                                                        </span> */}
                                                            {register.pwdShow ? <i className='fa fa-eye cu-input-icon' onClick={() => { setRegister({ ...register, pwdShow: false }) }} /> : <i className='fa fa-eye-slash cu-input-icon' onClick={() => setRegister({ ...register, pwdShow: true })} />}
                                                            <span className='error'>{error.password_err}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col col-12 col-md-6 mb-4">
                                                        <label htmlFor="inputConfirmPassword" className="form-label">Confirm
                                                            Password</label>
                                                        <div className="cu-input-icon-group">
                                                            <input type={register.cpwdShow ? "password" : "text"} className="form-control" id="inputConfirmPassword" value={register.cpassword} onChange={handleChange} name='cpassword' />
                                                            {register.cpwdShow ? <i className='fa fa-eye cu-input-icon' onClick={() => { setRegister({ ...register, cpwdShow: false }) }} /> : <i className='fa fa-eye-slash cu-input-icon' onClick={() => setRegister({ ...register, cpwdShow: true })} />}
                                                            {/* <span className="cu-input-icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                                                                    stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" />
                                                                <path
                                                                    d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
                                                                    stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round" />
                                                            </svg>
                                                        </span> */}
                                                            <span className='error'>{error.cpassword_err}</span>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <button type="button" className="btn btn-info w-100" onClick={handleSignUp} disabled={disable}>
                                                            {disable ? "Processing..." : "Register"}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Register