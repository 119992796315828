import { toast, Toaster } from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2'
import React, { useState } from 'react'
import axios from 'axios';
import { CONTIGO_API_URL } from '../BaseURL'


function ContactUs() {
    const [error, setError] = useState({})
    const [disable, setDisable] = useState(false)
    const [contact, setContact] = useState({ name: "", nurshingName: "", number: "", email: "", message: "" })


    const handleContact = (e) => {
        e.preventDefault();
        if (validate()) {
            setDisable(true)
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('email', contact.email);
            bodyFormData.append('name', contact.name);
            bodyFormData.append('nursing_home', contact.nurshingName);
            bodyFormData.append('phone_no', contact.number);
            bodyFormData.append('message', contact.message);
            bodyFormData.append('status', "1");

            axios({
                method: 'post',
                url: `${CONTIGO_API_URL}api/nursing-user/add-inquiry`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                console.log(response.data, "@@$$")
                toast.success("Thanks for contacting us.");
                setDisable(false)
                setContact({ name: "", nurshingName: "", number: "", email: "", message: "" });
            }).catch((error) => {
                console.log("error", error);
                toast.error(error?.response?.data?.message)
                setDisable(false)
            })
        }
    }


    const validate = () => {
        let errors = {};
        for (let key in contact) {
            if (!contact[key]) {
                errors[key + '_err'] = 'Please enter ' + key.replace(/_/g, " ");
                // toast.error('Please enter ' + key.replace(/_/g, " "), { style: { background: '#333', color: '#fff' } })
            }
            else if (key === "email") {
                if (typeof contact[key] !== "undefined") {
                    let lastAtPos = contact[key].lastIndexOf('@');
                    let lastDotPos = contact[key].lastIndexOf('.');
                    if (!(lastAtPos < lastDotPos && lastAtPos > 0 && contact[key].indexOf('@@') === -1 && lastDotPos > 2 && (contact[key].length - lastDotPos) > 2)) {
                        errors['email_err'] = "Email is not valid";
                        // toast.error('Email is not valid', { style: { background: '#333', color: '#fff' } })
                    }
                }
            }
        }

        if (!contact?.number) {
            errors.number_err = "Please enter Mobile number";
        }
        if (!contact?.nurshingName) {
            errors.nurshingName_err = "Please enter nursing name";
        }
        setError(errors)
        console.log(errors, "errors")
        return (Object.keys(errors).length > 0) ? false : true;
    }

    const handleChange = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value?.trimStart()})
    }

    
    const handleChangeWithoutNumber = (e) => {
        const sanitizedValue = e.target.value.replace(/[^a-zA-Z\s]/g, '');
        setContact({ ...contact, [e.target.name]: sanitizedValue?.trimStart()})
    }



    return (
        <div className="cu-contact-form-area">
            <div className="cu-contact-heading">
                <h3 className="cu-section-subtitle">Schedule A Call</h3>
                <h2 className="cu-section-title">Meet With our Experts</h2>
            </div>
            <div className="cu-contact-form">
                <form className="row">
                    <div className="col-md-6 mb-4">
                        <label htmlFor="inputName" className="form-label">Enter your Name</label>
                        <input type="text" className="form-control" id="inputName" name='name'
                         value={contact.name} onChange={handleChangeWithoutNumber} />
                        <span className='error'>{error.name_err}</span>
                    </div>
                    <div className="col-md-6 mb-4">
                        <label htmlFor="inputNursingName" className="form-label">Your Nursing Home Name</label>
                        <input type="text" className="form-control" id="inputNursingName" name='nurshingName' 
                        value={contact.nurshingName} onChange={handleChangeWithoutNumber} />
                        <span className='error'>{error.nurshingName_err}</span>
                    </div>
                    <div className="col-md-6 mb-4">
                        <label htmlFor="inputMobileNumber" className="form-label">Mobile Number</label>
                        {/* <input type="text" className="form-control" id="inputMobileNumber" name='number' value={contact.number} onChange={handleChange} /> */}
                        <PhoneInput
                            country='us'
                            inputClass='form-control'
                            inputExtraProps={{
                                name: "number",
                                required: true,
                                autoFocus: true
                            }}
                            placeholder=""
                            name="number"
                            defaultCountry={"us"}
                            value={contact.number}
                            onChange={(val, countryData) => {
                                const numericValue = val.replace(/[^0-9]/g, ""); // Extract numeric value only
                                const countryCodePrefix = "+" + countryData.dialCode; // Get country code prefix from countryData

                                let formattedValue = numericValue;
                                if (numericValue.startsWith(countryData.dialCode)) {
                                    formattedValue = `${countryData.dialCode}-${numericValue.substring(countryData.dialCode.length)}`;
                                }
                                console.log(countryCodePrefix)
                                setContact({ ...contact, number: formattedValue });
                            }}
                        />

                        <span className='error'>{error.number_err}</span>
                    </div>
                    <div className="col-md-6 mb-4">
                        <label htmlFor="inputEmailAddress" className="form-label">Email Address</label>
                        <input type="email" className="form-control" id="inputEmailAddress" name='email' value={contact.email} onChange={handleChange} />
                        <span className='error'>{error.email_err}</span>
                    </div>
                    <div className="col-12 mb-4">
                        <label htmlFor="inputMesage" className="form-label">Message</label>
                        <textarea className="form-control" id="inputMesage" rows="3" onChange={handleChange} value={contact.message} name='message'></textarea>
                        <span className='error'>{error.message_err}</span>
                    </div>
                    <div className="col-12 mb-4">
                        <div className="text-center">
                            <button disable={disable} type="button" className="btn btn-info" onClick={handleContact}
                                style={(Object.values(contact).every(val => !val)) ? { pointerEvents: "none" } : { cursor: 'pointer' }}
                            >Send Inquiry</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ContactUs