import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-hot-toast';
import { CONTIGO_API_URL } from '../BaseURL'
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import Notification from './Notification';
import { useRef } from 'react';


const Header = () => {

    const [profile, setProfile] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [notiModal, setNotiModal] = useState(false);
    const nav = useNavigate();
    const notiRef = useRef();

    useEffect(() => {
        const closeDropdown = (e) => {
            if (notiModal.current && !notiModal.current.contains(e.target)) {
                setNotiModal(false);
            }
        }

        document.body.addEventListener('click', closeDropdown);
        return () => document.body.removeEventListener('click', closeDropdown);
    }, [])

    useEffect(() => {
        getProfile();
        getNotifications();
    }, [])

    const getProfile = () => {
        axios({
            method: 'get',
            url: `${CONTIGO_API_URL}api/nursing-user/get-profile`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded ', 'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_BUSINESS')}` }
        }).then((response) => {
            console.log("@@", response?.data);
            if (response?.data?.success === true) {
                setProfile(response?.data?.data)
            }
        }).catch((err) => {
            console.log(err);
            // toast.error(err?.response?.data?.message);
            toast.error(err?.response?.data?.message || 'Something went wrong')
            if (err?.response?.success === 401) {
                localStorage.removeItem('CONTIGO_TOKEN_BUSINESS');
                nav('/login')
            }
        })
    }

    const getNotifications = () => {
        axios({
            method: 'get',
            url: `${CONTIGO_API_URL}api/nursing-user/get-notifications`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded ', 'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_BUSINESS')}` }
        }).then((response) => {
            console.log("@@", response?.data);
            if (response?.data?.success === true) {
                setNotifications(response?.data?.data)
            }
        }).catch((err) => {
            console.log(err);
            // toast.error(err?.response?.data?.message);
            toast.error(err?.response?.data?.message || 'Something went wrong')
            if (err?.response?.success === 401) {
                localStorage.removeItem('CONTIGO_TOKEN_BUSINESS');
                nav('/login')
            }
        })
    }

    const handleRead = async () => {
        let final = await Promise.all(notifications.map(el => {
            return { ...el, readed: true }
        }));
        setNotifications(final);
        axios({
            method: 'get',
            url: `${CONTIGO_API_URL}api/nursing-user/mark-as-read-notification`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded ', 'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_BUSINESS')}` }
        }).then((response) => {
            console.log("@@", response?.data);
            if (response?.data?.success === true) {
                // setNotifications(response?.data?.data)
            }
        }).catch((err) => {
            console.log(err);
            // toast.error(err?.response?.data?.message);
            toast.error(err?.response?.data?.message || 'Something went wrong')
            if (err?.response?.success === 401) {
                localStorage.removeItem('CONTIGO_TOKEN_BUSINESS');
                nav('/login')
            }
        })
    }

    useEffect(() => {
        $('.sidebar-toggler').on('click', function () {
            $(this).addClass('menu-show');
            $('.cu-sidebar').addClass('sidebar-show');
            $('body').addClass('menu-open');
        });

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Notification
                activeModal={notiModal}
                setActiveModal={() => setNotiModal(false)}
                notifications={notifications}
                handleRead={handleRead}
            />
            <header className="dashboard-header">
                <div className="dashboard-header-logo">
                    <a className="navbar-brand" href="/dashboard">
                        <img src="assets/images/logo.png" alt="Logo" className="img-fluid" />
                    </a>
                </div>
                <h2 className="dashboard-title">Hello, <span>{profile.first_name} {profile.last_name}</span></h2>
                <div className="dashboard-right-menu">
                    {/* <div className="cu-input-icon-group">
                        <input type="text" className="form-control" placeholder="Search here" />
                        <button type="button" className="cu-input-icon btn-icon">
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4"
                                    d="M9.82962 17.5295C11.821 17.5295 13.7307 16.7384 15.1388 15.3303C16.5469 13.9223 17.338 12.0125 17.338 10.0212C17.338 8.02982 16.5469 6.12004 15.1388 4.71196C13.7307 3.30387 11.821 2.51282 9.82962 2.51282C7.83829 2.51282 5.92851 3.30387 4.52043 4.71196C3.11234 6.12004 2.32129 8.02982 2.32129 10.0212C2.32129 12.0125 3.11234 13.9223 4.52043 15.3303C5.92851 16.7384 7.83829 17.5295 9.82962 17.5295Z"
                                    fill="#78797A" />
                                <path
                                    d="M18.1646 16.6378C17.8896 16.1295 17.3062 15.8461 16.5229 15.8461C15.9312 15.8461 15.4229 16.0878 15.1229 16.5045C14.8229 16.9211 14.7562 17.4795 14.9396 18.0378C15.2979 19.1211 15.9229 19.3628 16.2646 19.4045C16.3146 19.4128 16.3646 19.4128 16.4229 19.4128C16.7896 19.4128 17.3562 19.2545 17.9062 18.4295C18.3479 17.7878 18.4312 17.1461 18.1646 16.6378Z"
                                    fill="#78797A" />
                            </svg>
                        </button>
                    </div> */}
                    <button className="btn-icon btn-notification" onClick={() => setNotiModal(!notiModal)} ref={notiRef}>
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4"
                                d="M16.2706 12.9196L15.4373 11.5362C15.2623 11.2279 15.1039 10.6446 15.1039 10.3029V8.19456C15.1039 5.4779 12.8956 3.26123 10.1706 3.26123C7.44559 3.26123 5.23726 5.4779 5.23726 8.19456V10.3029C5.23726 10.6446 5.07892 11.2279 4.90392 11.5279L4.06226 12.9196C3.72892 13.4779 3.65392 14.0946 3.86226 14.6612C4.06226 15.2196 4.53726 15.6529 5.15392 15.8612C6.77059 16.4112 8.47059 16.6779 10.1706 16.6779C11.8706 16.6779 13.5706 16.4112 15.1873 15.8696C15.7706 15.6779 16.2206 15.2362 16.4373 14.6612C16.6539 14.0862 16.5956 13.4529 16.2706 12.9196Z"
                                fill="#7D7D7D" />
                            <path
                                d="M12.0297 3.61294C11.4547 3.38794 10.8297 3.26294 10.1714 3.26294C9.52135 3.26294 8.89635 3.37961 8.32135 3.61294C8.67969 2.93794 9.38802 2.51294 10.1714 2.51294C10.963 2.51294 11.663 2.93794 12.0297 3.61294ZM12.513 17.5213C12.3378 18.0064 12.0174 18.4258 11.5955 18.7225C11.1735 19.0192 10.6705 19.1788 10.1547 19.1796C9.49635 19.1796 8.84635 18.9129 8.38802 18.4379C8.12135 18.1879 7.92135 17.8546 7.80469 17.5129C7.91302 17.5296 8.02135 17.5379 8.13802 17.5546C8.32969 17.5796 8.52969 17.6046 8.72969 17.6213C9.20469 17.6629 9.68802 17.6879 10.1714 17.6879C10.6464 17.6879 11.1214 17.6629 11.588 17.6213C11.763 17.6046 11.938 17.5963 12.1047 17.5713L12.513 17.5213Z"
                                fill="#7D7D7D" />
                        </svg>
                    </button>
                    <span className="cu-header-profile" style={{ cursor: "pointer" }} onClick={() => nav('/my-profile')}>
                        {/* <img src="assets/images/profile-img.png" alt="profile-img" className="img-fluid" /> */}
                        <div style={{ display: "flex" }}>
                            <span style={{ backgroundColor: '#F6F7F9', color: 'black', borderRadius: '50%', width: '50px', height: '50px', fontSize: '20px', textAlign: 'center', paddingTop: '11px' }}>{profile.first_name?.charAt(0).toUpperCase()}{profile.last_name?.charAt(0).toUpperCase()}</span>
                        </div>
                    </span>
                    <button className="sidebar-toggler btn-icon">
                        <span className="sidebar-toggler-icon"></span>
                        <span className="sidebar-toggler-icon"></span>
                        <span className="sidebar-toggler-icon"></span>
                    </button>
                </div>
            </header>
        </>
    )
}

export default Header