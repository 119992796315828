import axios from 'axios';
import React, { useState } from 'react'
import { toast, Toaster } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { CONTIGO_API_URL } from '../BaseURL'

const ResetPassword = () => {

    const [details, setDetails] = useState({ password: '', confirm_password: '', pwdShow: true, cpwdShow: true });
    const [error, setError] = useState({});
    const [disable, setDisable] = useState(false)
    const nav = useNavigate();
    const location = useLocation();
    const email = location.state;

    const handleChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value?.trimStart() })
    }

    const validation = () => {
        let isValid = true;
        let err = {};

        if (!details.password) {
            isValid = false;
            err['password_err'] = "Please enter password"
        } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}/.test(details.password)) {
            isValid = false;
            err['password_err'] = "Password must be at least 8 characters long, contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character";
            console.log("password")
        }

        if (!details.confirm_password) {
            isValid = false;
            err['confirm_password_err'] = "Please enter confirm password"
        }
        if (details.password && details.confirm_password) {
            if (details.password !== details.confirm_password) {
                isValid = false;
                err['confirm_password_err'] = "Password doesn't match";
            }
        }
        setError(err);
        return isValid
    }

    const handleChangePassword = (e) => {
        e.preventDefault();
        if (validation()) {
            setDisable(true)
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('email', email);
            bodyFormData.append('new_password', details.password);
            axios({
                method: 'post',
                url: `${CONTIGO_API_URL}api/nursing-user/change-password`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                if (response.data.success === true) {
                    setTimeout(() => {
                        nav('/login')
                    }, 1500)
                    toast.success("Password Changed Successfully...")
                    setDisable(false)
                }
            }).catch((err) => {
                setError({ ...error, confirm_password_err: err.response.data.message })
                toast.error(err.response.data.message)
                setDisable(false)
            })
        }
    }

    return (
        <div className='cu-site'>
            <section className="cu-login-section">
                <div className="cu-login-section-content">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-12 col-xl-6">
                            <div className="cu-login-content">
                                <a className="cu-login-top-logo" href="/">
                                    <img src="assets/images/white-logo.png" alt="Logo" className="img-fluid" />
                                </a>
                                <div className="cu-login-content-area">
                                    <h2 className="cu-login-content-title">Patients are Waiting for you Login Now</h2>
                                    <div className="cu-login-logo">
                                        <img src="assets/images/login-logo.png" alt="login-logo" className="img-fluid" />
                                    </div>
                                    <div className="cu-login-left-img">
                                        <img src="assets/images/login-old-img.png" alt="login-old-img" className="img-fluid" />
                                    </div>
                                    <div className="cu-login-right-img">
                                        <img src="assets/images/login-boy-img.png" alt="login-boy-img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-12 col-xl-6">
                            <div className="cu-login-form-area">
                                <div className="cu-login-form-area__inner">
                                    <div className="cu-login-form-heading">
                                        <h1 className="cu-login-title">Change Password</h1>
                                        <p className="cu-login-description">Enter your new password</p>
                                    </div>
                                    <div className="cu-login-form">
                                        <form className="row" action="/dashboard">
                                            <div className="col-12 mb-4">
                                                <label htmlFor="inputPassword4" className="form-label">Password</label>
                                                <div className="cu-input-icon-group">
                                                    <input type={details.pwdShow ? "password" : "text"} className="form-control" id="inputPassword4" name='password' value={details.password} onChange={handleChange} />
                                                    {/* <span className="cu-input-icon">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path
                                                                d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </svg>
                                                    </span> */}
                                                    {details.pwdShow ? <i className='fa fa-eye cu-input-icon' onClick={() => { setDetails({ ...details, pwdShow: false }) }} /> : <i className='fa fa-eye-slash cu-input-icon' onClick={() => setDetails({ ...details, pwdShow: true })} />}
                                                    <span className='error'>{error.password_err}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <label htmlFor="inputConfirmPassword" className="form-label">Confirm Password</label>
                                                <div className="cu-input-icon-group">
                                                    <input type={details.cpwdShow ? "password" : "text"} className="form-control" id="inputConfirmPassword" onChange={handleChange} value={details.confirm_password} name='confirm_password' />
                                                    {details.cpwdShow ? <i className='fa fa-eye cu-input-icon' onClick={() => { setDetails({ ...details, cpwdShow: false }) }} /> : <i className='fa fa-eye-slash cu-input-icon' onClick={() => setDetails({ ...details, cpwdShow: true })} />}
                                                    {/* <span className="cu-input-icon">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path
                                                                d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
                                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </svg>
                                                    </span> */}
                                                    <span className='error'>{error.confirm_password_err}</span>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button type="button" onClick={handleChangePassword} className="btn btn-info w-100" disabled={disable}>{disable ? "Processing..." : "Submit"}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ResetPassword