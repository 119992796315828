import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Header from './Header'
import Sidebar from './Sidebar'
import { CONTIGO_API_URL } from '../BaseURL';
import axios from 'axios';
import parse from 'html-react-parser';
import { toast, Toaster } from 'react-hot-toast';
const SubscriptionPlan = () => {

    const nav = useNavigate();
    const [planshow, setPlanShow] = useState("a-month");

    useEffect(() => {
        if (localStorage.getItem('CONTIGO_TOKEN_BUSINESS') === null) {
            nav('/login')
        }
        else {
            getSubscriptionPlan();
        }
    }, []);
    const [plans, setPlans] = useState([]);

    const getSubscriptionPlan = () => {
        axios({
            method: 'get',
            url: `${CONTIGO_API_URL}api/nursing-user/get-subscription-plans`,
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_BUSINESS')}` }
        }).then((response) => {
            if (response.data.status === true) {
                setPlans(response.data.data)

            }
        }).catch((error) => {
            toast.error(error?.response?.data?.message || "Something went wrong");
            console?.log("Errors", error);
            if (error.response.status === 401) {
                localStorage.removeItem('CONTIGO_TOKEN_BUSINESS');
                nav('/login')
            }
        });
    }

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <div className='cu-site cu-dashboard-site'>
            <Header />
            <main className="cu-dashboard-content">
                <Sidebar />

                <div className="cu-dashboard-content-area" data-scrollbar>
                    <div className="cu-service-content">
                        {plans && <div class="switch-toggle switch-3 switch-candy">

                            <input id="on" name="state-d" type="radio" checked={planshow === "a-month" ? true : false} onChange={() => setPlanShow("a-month")} />
                            <label for="on" onclick="">1 Month</label>

                            <input id="na" name="state-d" type="radio" checked={planshow === "months" ? true : false} onChange={() => setPlanShow("months")} />
                            <label for="na" class="disabled" onclick="">6 Months</label>

                            <input id="off" name="state-d" type="radio" checked={planshow === "a-year" ? true : false} onChange={() => setPlanShow("a-year")} />
                            <label for="off" onclick="">1 Year</label>

                        </div>}
                        <div className="cu-plan-content">
                            {plans && plans.map((elem) => (
                                <div className="cu-plan-card">
                                    <div className="cu-plan-card-head">
                                        {elem.is_active && elem?.duration === planshow &&
                                            <span className="cu-plan-card-check-icon">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47714 15.5229 0 10 0C4.47714 0 0 4.47714 0 10C0 15.5229 4.47714 20 10 20ZM16.0288 7.27632C16.4452 6.85593 16.4452 6.17436 16.0288 5.75396C15.6123 5.33357 14.9371 5.33357 14.5207 5.75396L8.24175 12.0924L5.47929 9.30379C5.06286 8.88339 4.38768 8.88339 3.97125 9.30379C3.55479 9.72418 3.55479 10.4058 3.97125 10.8261L6.98504 13.8685C7.67911 14.5692 8.80439 14.5692 9.49846 13.8685L16.0288 7.27632Z"
                                                        fill="#00657B" />
                                                </svg>
                                            </span>
                                        }
                                        <span className="cu-plan-card-icon">
                                            <svg width="47" height="47" viewBox="0 0 47 47" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="23.7987" cy="23.7987" r="19.9706" stroke="#FF7800" stroke-width="6" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M31.3452 16.6927C31.4126 16.6978 31.4662 16.7514 31.4714 16.8188L31.5988 18.4753C31.8295 21.4752 30.7379 24.4261 28.6104 26.5536C27.8497 27.3143 26.9747 27.9512 26.017 28.4412L19.0512 32.005C18.9386 32.0627 18.8018 32.0411 18.7124 31.9517L16.2124 29.4517C16.1229 29.3623 16.1014 29.2254 16.159 29.1128L19.7229 22.1471C20.2128 21.1894 20.8497 20.3143 21.6104 19.5536C23.7379 17.4261 26.6889 16.3345 29.6888 16.5652L31.3452 16.6927ZM28.2368 21.2262C28.067 20.5923 27.5718 20.0971 26.9378 19.9272C26.3038 19.7573 25.6274 19.9386 25.1633 20.4027C24.6992 20.8668 24.5179 21.5432 24.6878 22.1772C24.8577 22.8112 25.3529 23.3064 25.9868 23.4762C26.6208 23.6461 27.2972 23.4649 27.7613 23.0008C28.2254 22.5367 28.4067 21.8602 28.2368 21.2262Z"
                                                    fill="#FF7800" />
                                                <path
                                                    d="M18.8404 21.5416L16.3975 21.5415C15.3113 21.5414 14.321 22.1637 13.8498 23.1424L12.2145 26.5388C12.0674 26.8442 12.29 27.1983 12.6289 27.1982L15.9453 27.1983L18.7768 21.664C18.7977 21.6231 18.8189 21.5822 18.8404 21.5416Z"
                                                    fill="#FF7800" />
                                                <path
                                                    d="M20.8006 32.3009L20.8006 35.37C20.8006 35.7093 21.1551 35.932 21.4608 35.7849L24.8561 34.1501C25.8351 33.6787 26.4575 32.6882 26.4575 31.6017V29.4067L20.8006 32.3009Z"
                                                    fill="#FF7800" />
                                            </svg>
                                        </span>
                                        <h4 className="cu-plan-card-title">{elem.plan_name}</h4>
                                    </div>
                                    <div className="cu-plan-card-body">
                                        {parse(elem.description)}
                                        {/* <ul className="cu-plan-card-feature-list">
                                            <li className="cu-plan-card-feature-item">Access To the ContiguoU Web Panel
                                            </li>
                                            <li className="cu-plan-card-feature-item">5 Services</li>
                                            <li className="cu-plan-card-feature-item">Upto 100 Beds</li>
                                            <li className="cu-plan-card-feature-item">Business profile Management</li>
                                            <li className="cu-plan-card-feature-item">Enquiry Management & Access to
                                                customer contact information and
                                                their details.</li>
                                            <li className="cu-plan-card-feature-item">Premium Customer Support</li>
                                        </ul> */}
                                        <div className="cu-plan-expire">

                                            <label className="cu-plan-expire-label">Pays</label>
                                            <h3 className="cu-plan-expire-date cu-plan-amount">{
                                                planshow === "a-month" ? `$${elem.amount}/Month` :
                                                    planshow === "months" ? `$${elem.amount_months}/6 Months` :
                                                        `$${elem.amount_year}/Year`
                                            }</h3>

                                            {/* <h3 className="cu-plan-expire-date cu-plan-amount">${elem.amount}/{(elem?.duration.includes("1") ? Capitalize(elem?.duration.split(' ')[1]) : (elem?.duration))}</h3> */}
                                        </div>
                                    </div>
                                    <div className="cu-plan-card-footer">
                                        {elem?.is_active == true && elem?.duration === planshow ?
                                            <>
                                                <span className='error'>*Note: Plan will be expired after <br />{elem?.expired_At} </span>
                                                <br />
                                                <button className='btn btn-info' disabled>Active</button>
                                            </>
                                            :
                                            <Link to='/payment-method' state={{ ...elem, planshow }} className='btn btn-info'>Do Payment</Link>
                                        }
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </main>

            {/* <!-- Delete Modal --> */}
            <div className="modal fade cu-delete-modal" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content ">
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="cu-thank-you-content">
                                <div className="cu-delete-icon">
                                    <svg width="62" height="62" viewBox="0 0 62 62" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M43.607 58.1251H18.3937C17.1354 58.1268 15.9246 57.6453 15.0112 56.78C14.0978 55.9146 13.5516 54.7316 13.4853 53.4751L11.2637 13.7434H50.737L48.5153 53.4751C48.4491 54.7316 47.9029 55.9146 46.9895 56.78C46.0761 57.6453 44.8652 58.1268 43.607 58.1251Z"
                                            stroke="#C90303" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path d="M6.33008 13.7434H55.6717" stroke="#C90303" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                        <path
                                            d="M26.065 3.875H35.9334C37.242 3.875 38.497 4.39485 39.4223 5.32018C40.3477 6.24552 40.8675 7.50054 40.8675 8.80917V13.7433H21.1309V8.80917C21.1309 7.50054 21.6507 6.24552 22.576 5.32018C23.5014 4.39485 24.7564 3.875 26.065 3.875Z"
                                            stroke="#C90303" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path d="M31 21.1316V50.7366" stroke="#C90303" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                        <path d="M40.8691 21.1316V50.7366" stroke="#C90303" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                        <path d="M21.1309 21.1316V50.7366" stroke="#C90303" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                    </svg>
                                </div>
                                <h3 className="cu-thank-you-title">Are You Sure you want to delete this ?</h3>
                                <div className="cu-modal-btn">
                                    <button className="btn btn-danger" type="button" data-bs-dismiss="modal"
                                        aria-label="Close">Delete </button>
                                    <button className="btn btn-info" type="button" data-bs-dismiss="modal" aria-label="Close">Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionPlan