// import './App.css';

import { Navigate, Route, Routes } from "react-router-dom";
import AddNewFacility from "./components/AddNewFacility";
import AddNewService from "./components/AddNewService";
import AddNewTherapy from "./components/AddNewTherapy";
import ForgetPassword from "./components/ForgetPassword";
import Index from "./components/Index";
import Login from "./components/Login";
import Otp from "./components/Otp";
import Register from "./components/Register";
import ResetPassword from "./components/ResetPassword";
import Dashboard from "./components/Dashboard";
import CenterProfile from "./components/CenterProfile";
import Facility from "./components/Facility";
import MyProfile from "./components/MyProfile";
import Payment from "./components/Payment";
import Service from "./components/Service";
import SubscriptionPlan from "./components/SubscriptionPlan";
import Therapy from "./components/Therapy";
import Inquiry from "./components/Inquiry";
import AboutUs from "./components/AboutUs";
import Subscription from "./components/Subscription";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { Toaster } from 'react-hot-toast';


function App() {
  return (
    <div className="App">
      <Toaster />
      <Routes>
        <Route path="/" exact element={<Index />} />
        <Route path="/about-us" exact element={<AboutUs />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route path="/subscription" exact element={<Subscription />} />

        <Route path="/login" exact element={<Login />} />
        <Route path="/register" exact element={<Register />} />

        <Route path="/otp-verification" exact element={<Otp />} />
        <Route path="/reset-password" exact element={<ResetPassword />} />
        <Route path="/forget-password" exact element={<ForgetPassword />} />

        <Route path="/manage-amenity" exact element={<AddNewFacility />} />
        {/* <Route path="/add-new-amenity/:id" element={<AddNewFacility />} /> */}

        <Route path="/add-new-services" exact element={<AddNewService />} />
        <Route path="/add-new-therapy" exact element={<AddNewTherapy />} />

        <Route path="/center-profile" exact element={<CenterProfile />} />

        <Route path="/dashboard" exact element={<Dashboard />} />

        <Route path="/amenities" exact element={<Facility />} />
        <Route path="/inquiry" exact element={<Inquiry />} />
        <Route path="/services" exact element={<Service />} />
        <Route path="/therapy" exact element={<Therapy />} />

        <Route path="/subscription-plans" exact element={<SubscriptionPlan />} />

        <Route path="/my-profile" exact element={<MyProfile />} />

        <Route path="/payment-method" exact element={<Payment />} />

        <Route path="*" exact element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
