import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import Header from './Header'
import Sidebar from './Sidebar'
import axios from 'axios';
import { CONTIGO_API_URL } from '../BaseURL';
import { toast, Toaster } from 'react-hot-toast';

import Autocomplete from 'react-google-autocomplete';
import GoogleMapReact from 'google-map-react';


import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';


const CenterProfile = () => {

    const [subscription, setSubscription] = useState({});
    const [disable, setDisable] = useState(false);
    const [disableTwo, setDisableTwo] = useState(false)
    const [details, setDetails] = useState({
        name: "",
        description: "",
        roomType: "",
        time: "",
        timeTwo: "",
        medicare: false,
        medicade: false,
        image: [],
        file: "",
        imageName: ""
    });
    const [imgSize, setImgSize] = useState("")
    const [img, setImg] = useState({
        src: "",
        alt: ""
    });

    const [day, setDay] = useState({
        all: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false
    })

    const [bed, setBed] = useState({
        // maleBed: 0,
        // femaleBed: 0,
        totalBed: 0,
    })

    const nav = useNavigate();

    const [fileSize, setFileSize] = useState("");
    const [fileData, setFileData] = useState({
        src: "",
        alt: ""
    });

    const [location, setLocation] = useState({ address: "", country: "", state: "", city: "", zip: "", lat: null, lng: null });
    const [position, setPosition] = useState({ lat: null, lng: null })
    const [error, setError] = useState({})


    const locationObject = {
        address: location?.address,
        country: location?.country,
        state: location?.state,
        city: location?.city,
        zip: location?.zip,
        lat: location.lat,
        lng: location.lng,
    };

    const locationJSON = JSON.stringify(locationObject);

    // console.log(location.lat, "late")
    // console.log(location.lng, "long")

    // google map


    const google = window.google;
    const mapRef = useRef(null);

    useEffect(() => {
        // const map = new google.maps.Map(mapRef.current, {
        //     center: { lat: 36.778259, lng: -119.417931 },
        //     zoom: 17,
        //     mapTypeControlOptions: {
        //         position: google.maps.ControlPosition.BOTTOM_RIGHT
        //     }
        // });

        // const marker = new google.maps.Marker({
        //     position: { lat: 36.778259, lng: -119.417931 },
        //     map: map,
        //     title: 'California'
        // });

        const defaultLocation = { lat: 36.778259, lng: -119.417931 }; // Default location for California

        const map = new google.maps.Map(mapRef.current, {
            center: { lat: position?.lat || defaultLocation?.lat, lng: position?.lng || defaultLocation?.lng },
            zoom: 17,
            // ... Other map options ...
        });

        const marker = new google.maps.Marker({
            position: { lat: position?.lat || defaultLocation?.lat, lng: position?.lng || defaultLocation?.lng },
            map: map,
            title: 'Location',
        });

        map.panTo(marker.getPosition());

        const searchBox = new google.maps.places.SearchBox(
            document.getElementById("pac-input")
        );

        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();

            console.log(places, 'places')

            if (places.length === 0) return;
            const formattedAddress = places[0].formatted_address;

            map.fitBounds(places[0].geometry.viewport);
            setLocation((prevLocation) => ({
                ...prevLocation,
                address: formattedAddress
            }));


            handleGeocode(formattedAddress, map);

            const bounds = new google.maps.LatLngBounds();
            places.forEach((place) => {
                if (!place.geometry) return;

                if (place.geometry.viewport) {
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });

            map.fitBounds(bounds);


            setLocation(places[0].formatted_address);
            handleGeocode(places[0].formatted_address, map);
            setLocation({ ...location, address: places[0].formatted_address });

            console.log(places[0].formatted_address, "address")
        });
    }, [position]);

    // console.log(position, "position")

    const handleGeocode = (adr, map) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: adr }, (results, status) => {
            if (status === 'OK') {

                setPosition({
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                });

                const map = new google.maps.Map(mapRef.current, {
                    center: { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() },
                    zoom: 17,
                });

                const location = results[0].geometry.location;
                console.log(location, "marker location")

                setLocation((prevLocation) => ({
                    ...prevLocation,
                    lat: location.lat(),
                    lng: location.lng(),
                }));

                var marker = new google.maps.Marker({
                    position: location,
                    map: map,
                    title: 'Location'
                });

                map.panTo(marker.getPosition());
                // const marker = new google.maps.Marker({
                //     position: location,
                //     map: map,
                //     title: 'Location'
                // });

                // updateLatLong(location.lat(), location.lng());
                updateLatLong(results[0].geometry.location.lat(), results[0].geometry.location.lng());

                const addressComponents = results[0].address_components;
                console.log(addressComponents, 'address componenets')

                let country = "";
                let state = "";
                let city = "";
                let zip = "";

                for (const component of addressComponents) {
                    if (component.types.includes("country")) {
                        country = component.long_name;
                    } else if (component.types.includes("administrative_area_level_1")) {
                        state = component.long_name;
                    } else if (component.types.includes("locality")) {
                        city = component.long_name;
                    } else if (component.types.includes("postal_code")) {
                        zip = component.long_name;
                    }
                }

                setLocation((prevLocation) => ({
                    ...prevLocation,
                    country: country,
                    state: state,
                    city: city,
                    zip: zip
                }));

            } else {
                console.log('Geocode was not successful for the following reason: ' + status);
            }
        });
    };


    // const handleGeocode = (adr, map) => {
    //     const geocoder = new window.google.maps.Geocoder();
    //     geocoder.geocode({ address: adr }, (results, status) => {
    //         if (status === 'OK') {
    //             setLocation({
    //                 late: results[0].geometry.location.lat(),
    //                 long: results[0].geometry.location.lng(),
    //             });

    //             const marker = new google.maps.Marker({
    //                 position: { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() },
    //                 map: map,
    //                 title: 'California'
    //             });

    //             map.panTo(marker.getPosition());
    //             updateLatLong(results[0].geometry.location.lat(), results[0].geometry.location.lng());
    //         } else {
    //             console.log('Geocode was not successful for the following reason: ' + status);
    //         }
    //     });
    // };

    function updateLatLong(lat, lng) {
        setLocation(prevAddInfo => ({
            ...prevAddInfo,
            lat: lat,
            lng: lng
        }));

        console.log(location, "update")
    }




    const getProfile = () => {
        axios({
            method: 'get',
            url: `${CONTIGO_API_URL}api/nursing-user/get-center-profile`,
            // data: bodyFormData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_BUSINESS')}`
            }
        }).then((response) => {
            console.log("@@", response?.data)
            setDetails({
                name: response?.data?.data?.center_name,
                description: response?.data?.data?.description,
                roomType: response?.data?.data?.room_type,
                medicade: response?.data?.data?.medicade,
                medicare: response?.data?.data?.medicare,
                time: response?.data?.data?.timing,
                timeTwo: response?.data?.data?.end_timing,
                image: (response?.data?.data?.center_image) ? response?.data?.data?.center_image : [],
                file: response?.data?.data?.certificate
            })

            setBed({
                // maleBed: response?.data?.data?.male_beds,
                // femaleBed: response?.data?.data?.female_beds,
                totalBed: response?.data?.data?.No_of_bed,
            })

            setPosition({ lat: response?.data?.data?.address?.lat, lng: response?.data?.data?.address?.lng })

            setFileData({
                src: response?.data?.data?.certificate,
                alt: response?.data?.data?.certificate?.split('uploads/')[1]
            })


            setDay({
                all: response?.data.data?.opening_days?.all,
                mon: response?.data.data?.opening_days?.mon,
                tue: response?.data.data?.opening_days?.tue,
                wed: response?.data.data?.opening_days?.wed,
                thu: response?.data.data?.opening_days?.thu,
                fri: response?.data.data?.opening_days?.fri,
                sat: response?.data.data?.opening_days?.sat,
                sun: response?.data.data?.opening_days?.sun
            })

            setImg({
                src: response?.data?.data?.center_image,
                // alt: response.data?.data?.center_image,
                // size: response.data.data.center_image
            })

            // console.log(img?.src, "image Url")

            setLocation({
                country: response?.data?.data?.address?.country,
                state: response?.data?.data?.address?.state,
                city: response?.data?.data?.address?.city,
                zip: response?.data?.data?.address?.zip,
                lat: response?.data?.data?.address?.lat,
                lng: response?.data?.data?.address?.lng,
                address: response?.data?.data?.address?.address
            })

        }).catch((error) => {
            console.log(error, "error")
            if (error?.response?.status === 401) {
                localStorage?.removeItem('CONTIGO_TOKEN_BUSINESS');
                nav('/login')
            }
        })

    }

    const newDay = JSON.stringify(day)


    const handleSubmit = () => {
        if (validation()) {
            setDisable(true)
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('center_name', details.name);

            bodyFormData.append('address', locationJSON);

            bodyFormData.append('description', details.description);
            bodyFormData.append('room_type', details.roomType);
            // bodyFormData.append('male_beds', bed.maleBed);
            // bodyFormData.append('female_beds', bed.femaleBed);
            bodyFormData.append('No_of_bed', bed.totalBed);
            bodyFormData.append('timing', details.time);
            bodyFormData.append('end_timing', details.timeTwo);
            bodyFormData.append('opening_days', newDay);
            bodyFormData.append('medicare', details.medicare);
            bodyFormData.append('medicade', details.medicade);
            bodyFormData.append('certificate', details.file);
            bodyFormData.append('center_image', JSON.stringify(details.image));

            console.log(day, "bodyform data")

            axios({
                method: 'post',
                url: `${CONTIGO_API_URL}api/nursing-user/update-center-profile`,
                data: bodyFormData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_BUSINESS')}`
                }
            }).then((response) => {
                console.log(response?.data, "@@")
                if (response?.data?.status === true) {
                    toast.success(response?.data?.message);
                    setDisable(false);
                }
            }).catch((error) => {
                console.log(error, "error");
                toast.error(error?.response?.data?.message)
                setDisable(false);
            })
        }
    }

    useEffect(() => {
        if (localStorage.getItem('CONTIGO_TOKEN_BUSINESS') === null) {
            nav('/login')
        }

        // getWidgets();
        checkSubscription()
    }, [])

    const checkSubscription = () => {

        axios({
            method: 'post',
            url: `${CONTIGO_API_URL}api/nursing-user/check-subscription`,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_BUSINESS')}` }
        }).then((response) => {
            if (response.data.status === true) {
                setSubscription(response.data.data);
                getProfile();
            } else {
                nav('/subscription-plans')
            }
        }).catch((error) => {
            toast.error(error?.response?.data?.message || 'Something went wrong')
            console?.log("Errors", error);
            if (error.response.status === 401) {
                localStorage.removeItem('CONTIGO_TOKEN_BUSINESS');
                nav('/login')
            }
        });
    }

    // useEffect(() => {
    //     if (localStorage.getItem('CONTIGO_TOKEN_BUSINESS') === null) {
    //         nav('/login')
    //     }
    //     getProfile();
    // }, []);


    const handleChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value?.trimStart() });
    }

    const handleDay = (e) => {
        const { name, checked } = e.target;

        if (name === 'all') {
            setDay({
                all: checked,
                mon: checked,
                tue: checked,
                wed: checked,
                thu: checked,
                fri: checked,
                sat: checked,
                sun: checked
            });
        } else {
            setDay(prevState => {
                const updatedDays = {
                    ...prevState,
                    [name]: checked
                };

                if (updatedDays.mon && updatedDays.tue && updatedDays.wed &&
                    updatedDays.thu && updatedDays.fri && updatedDays.sat &&
                    updatedDays.sun) {
                    updatedDays.all = true;
                } else {
                    updatedDays.all = false;
                }
                return updatedDays;
            });
        }
    };


    // const handleChangeThree = (e) => {
    //     const { name, value } = e.target;

    //     if (name === 'maleBed' || name === 'femaleBed') {

    //         const numericValue = parseInt(value);

    //         setBed((prevBed) => ({
    //             ...prevBed,
    //             [name]: numericValue,
    //             totalBed: name === 'maleBed' ? numericValue + prevBed.femaleBed : prevBed.maleBed + numericValue,
    //         }));
    //     }
    // };

    const handleChangeThree = (e) => {
        setBed({ ...bed, [e.target.name]: e.target.value });
    }

    // const handleChangeTwo = (e) => {
    //     setLocation({ ...location, [e.target.name]: e.target.value })
    // }

    const handleChangeTwo = (e) => {
        const { name, value } = e.target;
        setLocation((prevLocation) => ({
            ...prevLocation,
            [name]: value
        }));

        // setLocation({ ...location, address: e.target.value });

        console.log(location)

        handleGeocode(e.target.value, mapRef.current);
    };

    const handleFile = (e) => {
        if (e?.target?.files[0]) {

            const file = e.target.files[0];

            // Check if the selected file is one of the allowed formats (PDF, XLSX, DOCX)
            const allowedFormats = /(pdf|xlsx|docx)$/i;
            if (!allowedFormats.test(file.name)) {
                toast.error("Only PDF, XLSX, and DOCX files are allowed.");
                return;
            }

            // Convert the file size to MB
            const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2); // 1 MB = 1024 * 1024 bytes

            // Set the file size in the fileSize state in MB
            setFileSize(fileSizeInMB + " MB");

            // Set the file data in the fileData state
            setFileData({
                src: URL.createObjectURL(file),
                alt: file.name,
            });

            setDisableTwo(true);
            const myurl = `${CONTIGO_API_URL}api/upload-image`;
            const fileFormData = new FormData();
            fileFormData.append("image", file);

            axios({
                method: "post",
                url: myurl,
                data: fileFormData,
            })
                .then((result) => {
                    console.log("=====", result);

                    if (result?.data?.success === true) {
                        // Set the uploaded file URL in the details state
                        setDetails({ ...details, file: result?.data?.data?.url });
                        setDisableTwo(false);

                    } else {
                        console.log("Error:=====");
                        setDetails({ ...details, file: "" });
                        setDisableTwo(false)
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setDetails({ ...details, file: "" });
                    setDisableTwo(false);
                    toast.error("Unable to upload file");
                });
        }
    };

    const fileExtension = fileData?.alt?.split('.').pop()?.toLowerCase();

    const getImageForExtension = (extension) => {
        switch (extension) {
            case 'pdf':
                return 'assets/images/pdf-icon.png';
            case 'xlsx':
                return 'assets/images/xlsx.png';
            case 'doc':
            case 'docx':
                return 'assets/images/docs.png';
            default:
                return 'assets/images/default.png';
        }
    };

    const imageSrc = getImageForExtension(fileExtension);

    // multiple image

    const [uploadedImages, setUploadedImages] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);

    // const handleImage = (event) => {
    //     const files = event.target.files;
    //     const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];


    //     // Validate file types
    //     const selectedFiles = [];
    //     for (let i = 0; i < files.length; i++) {
    //         const file = files[i];
    //         if (validImageTypes.includes(file.type)) {
    //             selectedFiles.push(file);
    //         } else {
    //             console.log('Invalid file type:', file.name);
    //         }
    //     }

    //     // Create object URLs for the selected files and store them in the state
    //     const previews = selectedFiles.map((file) => URL.createObjectURL(file));
    //     setImagePreviews((prevImagePreviews) => [...prevImagePreviews, ...previews]);

    //     // Update state with valid images only
    //     setUploadedImages((prevUploadedImages) => [...prevUploadedImages, ...selectedFiles]);

    //     // Upload valid images to the API
    //     uploadImagesToAPI(selectedFiles);
    // };

    const handleImage = (event) => {
        const files = event.target.files;
        const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

        const selectedFiles = [];
        const invalidFiles = [];

        for (let i = 0; i < files?.length; i++) {
            const file = files[i];
            if (validImageTypes.includes(file.type)) {
                selectedFiles.push(file);
            } else {
                invalidFiles.push(file);
            }
        }

        if (invalidFiles?.length > 0) {
            const invalidFileNames = invalidFiles.map((file) => file.name).join(', ');
            toast.error('Only JPEG, PNG, and JPG files are allowed');
            return; // Stop further processing
        }

        const previews = selectedFiles.map((file) => URL.createObjectURL(file));
        setImagePreviews((prevImagePreviews) => [...prevImagePreviews, ...previews]);

        setUploadedImages((prevUploadedImages) => [...prevUploadedImages, ...selectedFiles]);

        uploadImagesToAPI(selectedFiles);
    };


    const formatFileSize = (bytes) => {
        const megabytes = bytes / (1024 * 1024);
        return `${megabytes.toFixed(2)} MB`;
    };

    const uploadImagesToAPI = async (images) => {
        try {
            setDisable(true)
            const formData = new FormData();
            images.forEach((image) => {
                formData.append('images', image);
            });

            // Replace 'your-api-endpoint' with the actual API endpoint URL
            const response = await axios.post(`${process.env.REACT_APP_API_URL}api/upload-multiple-image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // setDetails({ ...details, image: response?.data?.data.filter(e => { return e.url }) })


            // const imageUrls = response?.data?.data.map((url) => ({ url }));

            response?.data?.data?.map((item, index) => {
                details.image.push(item?.url)
            });

            setDisable(false)
        } catch (error) {
            console.error('Error uploading images:', error);
            setDisable(false);
        }
    };



    // Add this cleanup function to clear the object URLs when the component unmounts
    useEffect(() => {
        return () => {
            imagePreviews.forEach((previewUrl) => URL.revokeObjectURL(previewUrl));
        };
    }, [imagePreviews]);

    const handleDeleteImg = (imageURL) => {
        const updatedImages = details.image.filter((img) => img !== imageURL);
        setDetails({ ...details, image: updatedImages });
    };

    function handleCheckboxChange(e) {
        setDetails({ ...details, [e.target.name]: e.target.checked });
    }

    const validation = () => {
        let isValid = true;
        let err = {};

        if (!details.name) {
            isValid = false;
            err['name_err'] = "Please enter patient name"
        }

        if (!location.address) {
            isValid = false;
            err['address_err'] = "Please enter address"
        }

        if (!details.description) {
            isValid = false;
            err['description_err'] = "Please enter center description"
        }

        if (!details.roomType) {
            isValid = false;
            err['roomType_err'] = "Please select room type"
        }

        // if (!bed.maleBed) {
        //     isValid = false;
        //     err['male_err'] = "Please enter male beds"
        // }


        // if (!bed.femaleBed) {
        //     isValid = false;
        //     err['female_err'] = "Please enter female beds"
        // }

        if (!details.time) {
            isValid = false;
            err['time_err'] = "Please enter opening time"
        }

        if (!details.timeTwo) {
            isValid = false;
            err['timeTwo_err'] = "Please enter closing time"
        }

        if (!details.timeTwo) {
            isValid = false;
            err['timeTwo_err'] = "Please enter end timing"
        }

        if (!details?.image?.length > 0) {
            isValid = false;
            err['image_err'] = "Please select image"
        }

        if (!details.file) {
            isValid = false;
            err['file_err'] = "Please select file"
        }

        if (!location.zip) {
            isValid = false;
            err['zip_err'] = "Please enter zip"
        }

        if (!location?.city) {
            isValid = false;
            err['city_err'] = "Please enter city"
        }

        // if(!day){
        //     isValid =false;
        //     err['day_err'] = "Please select atleast one day"
        // }

        if (!Object.values(day).some((value) => value)) {
            isValid = false;
            err['day_err'] = "Please select at least one day";
        }
        console.log(err, "validation messgae")

        setError(err);
        return isValid
    }

    // const anyDaySelected = Object.values(day).some(value => value);


    // const handlePlaceSelected = (place) => {
    //     // Extracting the data from the 'place' object
    //     const name = place.name;
    //     const address = place.formatted_address;
    //     const latitude = place.geometry.location.lat();
    //     const longitude = place.geometry.location.lng();

    //     // Do whatever you want with the extracted data
    //     console.log('Name:', name);
    //     console.log('Address:', address);
    //     console.log('Latitude:', latitude);
    //     console.log('Longitude:', longitude);

    //     // You can also update the state or call other functions here if needed
    //     setLocation({ name, address, latitude, longitude });
    //     console.log(location, "location data")
    // };


    // const handlePlaceSelected = (place) => {

    //     console.log(place, 'place data')

    //     const address = place?.formatted_address;
    //     const latitude = place?.geometry?.location?.lat();
    //     const longitude = place?.geometry?.location?.lng();

    //     // Extracting additional location details if available
    //     let country = "";
    //     let state = "";
    //     let city = "";

    //     if (place?.address_components && Array?.isArray(place?.address_components)) {
    //         for (const component of place?.address_components) {
    //             const types = component.types;

    //             if (types.includes("country")) {
    //                 country = component?.long_name;
    //             } else if (types?.includes("administrative_area_level_1")) {
    //                 state = component?.long_name;
    //             } else if (types?.includes("locality") || types.includes("administrative_area_level_2")) {
    //                 city = component?.long_name;
    //             }
    //         }
    //     }

    //     // Do whatever you want with the extracted data
    //     console.log('Address:', address);
    //     console.log('Latitude:', latitude);
    //     console.log('Longitude:', longitude);
    //     console.log('Country:', country);
    //     console.log('State:', state);
    //     console.log('City:', city);

    //     // You can also update the state or call other functions here if needed
    //     setLocation({ address, latitude, longitude, country, state, city });
    //     console.log(location, "location data");

    // };


    return (
        <div className='cu-site cu-dashboard-site'>
            <Header />
            <main className="cu-dashboard-content">
                <Sidebar />
                <div className="cu-dashboard-content-area" data-scrollbar>
                    <div className="cu-center-profile-content">
                        <h2 className="cu-dashboard-page-title">Center profile</h2>
                        <form className="row">
                            <div className="col-12">
                                <label htmlFor="inputNamePatient" className="form-label">Center Name</label>
                                <input type="text" className="form-control" id="inputNamePatient" name='name'
                                    value={details.name} onChange={handleChange} />
                                <span className='error'>{error.name_err}</span>
                            </div>
                            <div className="col col-12 col-md-6 col-xl-4">
                                <label htmlFor="inputAddress" className="form-label">Address</label>
                                <input type="text" id="pac-input" className="form-control" name='address'
                                    placeholder=''
                                    value={location?.address} onChange={handleChangeTwo} />
                                {/* <Autocomplete
                                    apiKey="AIzaSyC2ikMAAgu6yhCxewumKUT79J13WcQcngE"
                                    onPlaceSelected={handlePlaceSelected}
                                    types={['cities']}
                                    // componentRestrictions={{ country: 'YOUR_COUNTRY_CODE' }}
                                    className="form-control"
                                    value={location.address}
                                    onChange={(e) => {
                                        setLocation({ ...location, address: e.target.value });
                                    }}
                                /> */}
                                <span className='error'>{error.address_err}</span>
                            </div>
                            <div className="col col-12 col-md-6 col-xl-2">
                                <label htmlFor="inputCountry" className="form-label">Country</label>
                                <input type="text" className="form-control" id="inputCenterDescription" name='country'
                                    value={location.country} onChange={handleChangeTwo}
                                    readOnly
                                />
                            </div>
                            <div className="col col-12 col-md-4 col-xl-2">
                                <label htmlFor="inputState" className="form-label">State</label>
                                <input type="text" className="form-control" id="inputCenterDescription" name='state'
                                    value={location.state} onChange={handleChangeTwo}
                                    readOnly
                                />
                            </div>
                            <div className="col col-12 col-md-4 col-xl-2">
                                <label htmlFor="inputCity" className="form-label">City</label>
                                <input type="text" className="form-control" id="inputCenterDescription" name='city'
                                    value={location.city} onChange={handleChangeTwo}
                                />
                                <span className='error'>{error.city_err}</span>
                            </div>
                            <div className="col col-12 col-md-4 col-xl-2">
                                <label htmlFor="inputZIP" className="form-label">ZIP</label>
                                <input type="text" className="form-control" id="inputCenterDescription" name='zip'
                                    value={location.zip} onChange={handleChangeTwo}
                                />
                                <span className='error'>{error.zip_err}</span>
                            </div>
                            <div className="col col-12 col-md-12 mt-2">
                                <div className="cu-center-profile-map" id='map' ref={mapRef} style={{ width: "auto", height: "200px" }}>
                                    {/* <div className='cu-center-profile-map'>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d276254.2938621077!2d-94.97839335440851!3d39.092741785987435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c0f75eafe99997%3A0x558525e66aaa51a2!2sKansas%20City%2C%20MO%2C%20USA!5e0!3m2!1sen!2sin!4v1686215056379!5m2!1sen!2sin"
                                        allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div> */}
                                </div>
                            </div>
                            <div className="col-12">
                                <label htmlFor="inputCenterDescription" className="form-label">Center Description</label>
                                <input type="text" className="form-control" id="inputCenterDescription" name='description'
                                    value={details.description} onChange={handleChange}
                                />
                                <span className='error'>{error.description_err}</span>
                            </div>
                            <div className="col col-12 col-md-4">
                                <label htmlFor="inputRoomType" className="form-label">Room Type</label>
                                <select className="form-select room-type-dropdown" id="inputRoomType"
                                    value={details.roomType} name='roomType' onChange={handleChange}>
                                    <option value="0">Select</option>
                                    <option value="1">Private Rooms</option>
                                    <option value="2">Double Rooms</option>
                                    <option value="3">Triples Rooms</option>
                                    <option value="4">Quads Rooms</option>
                                </select>
                                <span className='error'>{error.roomType_err}</span>
                            </div>
                            {/* <div className="col col-12 col-md-4">
                                <label htmlFor="inputMaleBeds" className="form-label">Male Beds</label>
                                <input type="number" className="form-control" id="inputCenterDescription" name='maleBed'
                                    value={bed.maleBed} onChange={handleChangeThree}
                                />
                                <span className='error'>{error.male_err}</span>
                            </div>
                            <div className="col col-12 col-md-4" >
                                <label htmlFor="inputFemaleBeds" className="form-label">Female Beds</label>
                                <input type="number" className="form-control" id="inputCenterDescription"
                                    value={bed.femaleBed} name='femaleBed' onChange={handleChangeThree}
                                />
                                <span className='error'>{error.female_err}</span>
                            </div> */}
                            <div className="col col-12 col-md-4">
                                <label htmlFor="inputTotalBeds" className="form-label">Total Beds</label>
                                <input type="number" className="form-control" id="inputCenterDescription"
                                    value={bed.totalBed} name='totalBed' onChange={handleChangeThree}
                                />
                            </div>
                            <div className="col col-12 col-md-4 mb-2">
                            </div>
                            <div className="col col-12 col-md-4 mb-2">
                                <label htmlFor="inputTiming" className="form-label">Opening time</label>
                                <div className="cu-input-icon-group cu-input-right-icon">
                                    <input type="time"
                                        min="1"
                                        max="12" className=" form-control" id="inputTiming"
                                        value={details.time} name='time' onChange={handleChange} />
                                    <span className='error'>{error.time_err}</span>
                                </div>
                            </div>

                            <div className="col col-12 col-md-4 mb-2">
                                <label htmlFor="inputTiming" className="form-label">Closing time</label>
                                <div className="cu-input-icon-group cu-input-right-icon">
                                    <input type="time" min="09:00" max="18:00" className="form-control" id="inputTiming"
                                        value={details.timeTwo} name='timeTwo' onChange={handleChange} />
                                    <span className='error'>{error.timeTwo_err}</span>
                                </div>
                            </div>
                            <div className="col col-12 col-md-4 mb-2">
                            </div>
                            <div className="col mb-2">
                                <label htmlFor="inputOpeningDays" className="form-label">Opening Days</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name='all' checked={day.all} onChange={handleDay} />
                                        <label className="form-check-label" htmlFor="daysCheckbox1">All Day</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name='mon' checked={day.mon} onChange={handleDay} />
                                        <label className="form-check-label" htmlFor="daysCheckbox2">Monday</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name='tue' checked={day.tue} onChange={handleDay} />
                                        <label className="form-check-label" htmlFor="daysCheckbox3">Tuesday</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name='wed' checked={day.wed} onChange={handleDay} />
                                        <label className="form-check-label" htmlFor="daysCheckbox4">Wednesday</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name='thu' checked={day.thu} onChange={handleDay} />
                                        <label className="form-check-label" htmlFor="daysCheckbox4">Thursday</label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name='fri' checked={day.fri} onChange={handleDay} />
                                        <label className="form-check-label" htmlFor="daysCheckbox4">Friday</label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name='sat' checked={day.sat} onChange={handleDay} />
                                        <label className="form-check-label" htmlFor="daysCheckbox4">Saturday</label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name='sun' checked={day.sun} onChange={handleDay} />
                                        <label className="form-check-label" htmlFor="daysCheckbox4">Sunday</label>
                                    </div>
                                </div>
                                <span className='error'>{error.day_err}</span>

                            </div>
                            <div className="col-12">
                                <div className="cu-supported-check-content">
                                    <h4 className="cu-supported-check-title">Supported Insurance</h4>
                                    <div className="cu-supported-check">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="supportedCheck1"
                                                value={details.medicare} onChange={handleCheckboxChange} checked={details.medicare} name='medicare'
                                            />
                                            <label className="form-check-label" htmlFor="supportedCheck1">Medicare</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="supportedCheck2"
                                                value={details.medicade} onChange={handleCheckboxChange} checked={details.medicade} name='medicade' />
                                            <label className="form-check-label" htmlFor="supportedCheck2">Medicade</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-12 col-md-12 col-lg-6">
                                <div className="cu-upload-img-conetnt">
                                    <label htmlFor="inputTiming" className="form-label">Center Images</label>
                                    {((subscription?.plan?.plan_name === "Basic" && details?.image?.length < 3) ||
                                        (subscription?.plan?.plan_name === "Premium" && details?.image?.length < 7)) && <div className="cu-upload-img__inner">
                                            <input type="file" className="form-control" onChange={handleImage} accept=".jpg,.jpeg,.png" />
                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M22.1799 17.1367L19.0499 9.8267C17.9899 7.3467 16.0399 7.2467 14.7299 9.6067L12.8399 13.0167C11.8799 14.7467 10.0899 14.8967 8.84993 13.3467L8.62993 13.0667C7.33993 11.4467 5.51993 11.6467 4.58993 13.4967L2.86993 16.9467C1.65993 19.3467 3.40993 22.1767 6.08993 22.1767H18.8499C21.4499 22.1767 23.1999 19.5267 22.1799 17.1367ZM7.46993 8.1767C8.26558 8.1767 9.02864 7.86063 9.59125 7.29802C10.1539 6.73541 10.4699 5.97235 10.4699 5.1767C10.4699 4.38105 10.1539 3.61799 9.59125 3.05538C9.02864 2.49277 8.26558 2.1767 7.46993 2.1767C6.67428 2.1767 5.91121 2.49277 5.34861 3.05538C4.786 3.61799 4.46993 4.38105 4.46993 5.1767C4.46993 5.97235 4.786 6.73541 5.34861 7.29802C5.91121 7.86063 6.67428 8.1767 7.46993 8.1767Z"
                                                    stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                            </svg>
                                            <p className="cu-upload-img-label">Drag & Drop or <span>Image</span> to Upload</p>
                                            <p className="cu-upload-img-notes">JPG & PNG Only</p>
                                        </div>}
                                    {/* <div className="cu-upload-img-process-content">
                                        <div className="cu-upload-img-process-head">
                                            <div className="cu-upload-image">
                                                <img src="assets/images/upload-img.png" alt="upload-img" className="img-fluid" />
                                            </div>
                                            <div className="cu-upload-img-detail">
                                                <h5 className="cu-upload-img-title">image1001</h5>
                                                <p className="cu-upload-img-text">
                                                    <span className="cu-upload-img-size">22.45 KB . 3 Sec Left</span>
                                                    <span className="cu-upload-img-progress">44%</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: "25%" }} aria-valuenow="25"
                                                aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div> */}
                                    {details?.image?.length > 0 ?
                                        <div className="cu-upload-img-process-content">
                                            <div className="cu-upload-img-process-head col">
                                                <div className="cu-upload-image">

                                                    {details.image.map((previewUrl, index) => (
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <img key={index} src={previewUrl} alt={`Uploaded ${index}`}
                                                                    style={{ marginTop: "15px", width: "auto", borderRadius: "7px" }} />
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                                <div className="cu-upload-img-detail">
                                                    {details.image.map((elem) => {
                                                        const url = new URL(elem);
                                                        const filename = url?.pathname?.split('uploads/')[1];
                                                        return (
                                                            <div className='row'>
                                                                <div className='col'>
                                                                    {/* <h5 className="cu-upload-img-title">{filename}</h5> */}
                                                                    <h5 className="cu-upload-img-title">{filename?.length <= 14 ? filename : filename?.slice(0, 14) + (filename?.length > 14 ? "..." : "")}</h5>
                                                                </div>
                                                                <div className='col' style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                                    <button className="btn btn-outline-secondary" type='button'
                                                                        onClick={() => handleDeleteImg(elem)}
                                                                        style={{ padding: "5px 10px", fontSize: "13px", marginTop: "20px" }}><i className="fas fa-trash" /></button>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}

                                                </div>

                                            </div>
                                        </div>
                                        : ''}
                                    <span className='error'>{error.image_err}</span>

                                </div>
                            </div>
                            <div className="col col-12 col-md-12 col-lg-6">
                                <div className="cu-upload-img-conetnt">
                                    <label htmlFor="inputTiming" className="form-label">Upload Certificate</label>
                                    <div className="cu-upload-img__inner">
                                        <input type="file" className="form-control" onChange={handleFile} />
                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M22.1799 17.1367L19.0499 9.8267C17.9899 7.3467 16.0399 7.2467 14.7299 9.6067L12.8399 13.0167C11.8799 14.7467 10.0899 14.8967 8.84993 13.3467L8.62993 13.0667C7.33993 11.4467 5.51993 11.6467 4.58993 13.4967L2.86993 16.9467C1.65993 19.3467 3.40993 22.1767 6.08993 22.1767H18.8499C21.4499 22.1767 23.1999 19.5267 22.1799 17.1367ZM7.46993 8.1767C8.26558 8.1767 9.02864 7.86063 9.59125 7.29802C10.1539 6.73541 10.4699 5.97235 10.4699 5.1767C10.4699 4.38105 10.1539 3.61799 9.59125 3.05538C9.02864 2.49277 8.26558 2.1767 7.46993 2.1767C6.67428 2.1767 5.91121 2.49277 5.34861 3.05538C4.786 3.61799 4.46993 4.38105 4.46993 5.1767C4.46993 5.97235 4.786 6.73541 5.34861 7.29802C5.91121 7.86063 6.67428 8.1767 7.46993 8.1767Z"
                                                stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                        <p className="cu-upload-img-label">Drag & Drop or <span>Choose File</span> to Upload</p>
                                        <p className="cu-upload-img-notes">PDF, Word and Excel file only</p>
                                    </div>
                                    {fileData?.src ?
                                        <div className="cu-upload-img-process-content">
                                            <div className="cu-upload-img-process-head">
                                                <div className="cu-upload-image">
                                                    <img src={imageSrc} alt="upload-img" className="img-fluid" />
                                                </div>
                                                <div className="cu-upload-img-detail">
                                                    {fileData?.alt > 0 ? (
                                                        <h5 className="cu-upload-img-title">file name</h5>
                                                    ) : (
                                                        // <h5 className="cu-upload-img-title">{fileData?.alt}</h5>
                                                        <h5 className="cu-upload-img-title">
                                                            {fileData?.alt?.length > 18 ? `${fileData.alt.substring(0, 18)}...` : fileData.alt}
                                                        </h5>
                                                    )}
                                                    {/* <h5 className="cu-upload-img-title">{fileData.alt}</h5> */}
                                                    <p className="cu-upload-img-text">
                                                        <span className="cu-upload-img-size">{fileSize}</span>
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        : ""}
                                    <span className='error'>{error.file_err}</span>

                                </div>
                            </div>
                            <div className="col-12">
                                <div className="cu-inqury-form-btn">
                                    <button type="button" className="btn btn-info" disabled={disable || disableTwo} onClick={handleSubmit}>{disable || disableTwo ? "Processing..." : 'Update Center Profile'}</button>
                                    <a href="/center-profile" className="btn btn-outline-info">Cancel</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default CenterProfile