import { Link } from "react-router-dom"

const Header = () => {
    return (
        <header class="cu-site-header">
            <nav class="navbar navbar-expand-lg">
                <div class="container-fluid p-0">
                    <a class="navbar-brand" href="index.html">
                        <img src="assets/images/logo.png" alt="Logo" class="img-fluid" />
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icons"></span>
                        <span class="navbar-toggler-icons"></span>
                        <span class="navbar-toggler-icons"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a class={`nav-link ${window.location.pathname === "/" ? 'active' : ''}`} aria-current="page" href="/">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class={`nav-link ${window.location.pathname === "/about-us" ? 'active' : ''}`} href="/about-us">About US</a>
                            </li>
                            {/* <li class="nav-item">
                                <a class="nav-link" href="#how_works">How it Works</a>
                            </li> */}
                            <li class="nav-item">
                                <a class={`nav-link ${window.location.pathname === "/subscription" ? 'active' : ''}`} href="/subscription">Subscriptions</a>
                            </li>
                            {/* <li class="nav-item">
                                <a class="nav-link" href="#contact_us">Contact US</a>
                            </li> */}
                        </ul>
                    </div>
                    <div class="cu-header-btn">
                        <Link to="/login" className="btn btn-info">Login</Link>
                        <Link to="/register" className="btn btn-primary">Sign Up</Link>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header