import React, { useEffect, useMemo, useState } from 'react'
import Header from './layouts/Header'
import Footer from './layouts/Footer'
import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { CONTIGO_API_URL } from '../BaseURL'
import parse from 'html-react-parser';
import { RxCross2 } from "react-icons/rx";
import ContactUs from './contactUs';

const Subscription = () => {
    const nav = useNavigate();
    const [planshow, setPlanShow] = useState("a-month");

    useEffect(() => {

        if (localStorage.getItem('CONTIGO_TOKEN_BUSINESS')) {
            toast.success("Already Logged-In")
            setTimeout(() => {
                nav('/dashboard')
            }, 1500)
        }
        else {
            getSubscriptionPlan();
        }
        // eslint-disable-next-line
    }, [])


    const [plans, setPlans] = useState([]);

    const getSubscriptionPlan = () => {
        axios({
            method: 'get',
            url: `${CONTIGO_API_URL}api/nursing-user/get-subscription-plans`,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.data.status === true) {
                setPlans(response.data.data)

            }
        }).catch((error) => {
            toast.error("Something went wrong");
            console?.log("Errors", error);
            if (error.response.status === 401) {
                localStorage.removeItem('CONTIGO_TOKEN_BUSINESS');
                nav('/login')
            }
        });
    }

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const faqs = [
        {
            id: 1,
            ques: "What articles of clothing should we bring?",
            ans: `<p>We recommend approximately five changes of clothing. For Short Term Rehab bring work-out clothes that are easy to slip on and a pair of comfortable rubber-soled shoes. All articles should be washed, clearly labeled with resident’s name in permanent ink and entered into the residents’ personal inventory log in the medical chart. Other personal items should be marked or engraved for identification. We advise that resident’s do not keep valuables such as jewelry or large amounts of cash in the facility.</p>`
        },
        {
            id: 2,
            ques: "Is there a limit to the number of people who can visit at one time?",
            ans: `<p>The common areas of the facility are where you will have to meet with your loved one if you have a large amount of people and your loved one shares a room. If it is not possible to visit in one of the common areas, we suggest a limit of one to two people in a resident room at one time. Check with the facility on their rules.</p>`
        },
        {
            id: 3,
            ques: "Can we bring a family pet to the facility?",
            ans: `<p>Most facilities allow the family pet to come visit. Please remember to clean up after them and take the proper safety measures at all times. All pets are to be kept on a leash and should have all current vaccinations. Pets are not permitted in the dining area during meal service. ContigoU have listed the sites that allow pets. Please feel free to contact other sites if they are not listed.</p>`
        },
        {
            id: 4,
            ques: "Can I take my loved one home for a visit?",
            ans: `<p>Residents may take a leave of absence only with a physician’s authorization, which can be arranged through the nursing staff. Upon arrival and departure, the resident or responsible party must sign the leave of absence log usually located at the nurses station.</p>`
        },
        {
            id: 5,
            ques: "What is the difference between a CNA, LVN, and RN?",
            ans: `<p>A Certified Nursing Assistant (CNA) is a health professional who works under the supervision of Registered Nurses (RN’s) and Licensed Vocational Nurses (LVN’s) to provide a wide variety of basic hands-on resident care. CNAs perform basic nursing functions such as personal care, taking vital signs, positioning residents, and ambulation.</p><br/>
            <p>An LVN provides basic bedside nursing care to residents under the direction of a physician or registered nurse. Duties within the scope of practice for an LVN typically include but are not limited to, provision of basic hygienic and nursing care; measurement of vital signs; basic client assessment; documentation; performance of prescribed medical treatments; administration of prescribed medications; and, the performance of non-medicated intravenous therapy and blood withdrawal.</p><br/>
            <p>An RN’s duties may include everything a CNA and LVN carry out, in addition to administering an IV, assessment of residents, skin care, wound care, advocate for you and processing doctors’ orders. Furthermore, RN’s typically take on management positions such as the Nursing Supervisor or Director of Nursing, who is responsible for all nursing duties in the building.</p>
            `
        },
        {
            id: 6,
            ques: "How often will a physician or health professional visit?",
            ans: `<p>Physicians generally see residents upon admission and once every 30 days thereafter. However, the nursing professionals are in constant contact with all of the physicians that visit the facility. Some insurance companies require that physicians visit more frequently. Check with the nursing staff for further information.</p>`
        },
        {
            id: 7,
            ques: "Will Medicare cover my stay in the facility?",
            ans: `<p>Medicare will cover skilled care only if all of the following are true:</p><br/>
            <ul>
            <li>You have Medicare Part A as indicated by a Medicare card that reads, “hospital insurance”, and you have not exhausted your benefit period.</li>
            <li>You have a qualifying inpatient hospital stay of three consecutive days or more, and admit to our facility within 30 days of discharging from the hospital.</li>
            <li>Your doctor has ordered the services you need for care in a skilled nursing facility, which require the skills of professional personnel such as registered nurses, licensed practical nurses, physical therapists, occupational therapists, speech-language pathologists, etc.</li>
            <li>You require the skilled care on a daily basis, and these services can only be provided on an inpatient basis.</li>
            <li>You need these skilled services for a medical condition that was treated during a qualifying three-day hospital stay or started while you were receiving care in a skilled nursing facility for a medical condition that was treated during a qualifying three-day hospital stay.</li>
            <li>Generally, Medicare pays for 100% of your stay during the first 20 days of care. After the 20th day, Medicare requires a daily co-insurance copay. Medicare does not cover skilled nursing fees beyond 100 days.</li>
            </ul>`
        }
    ]

    // const getComparisionObject = useMemo(() => {
    //     var itemsArray = [];
    //     plans.forEach(function (obj) {
    //         var tempDiv = document.createElement('div');
    //         tempDiv.innerHTML = obj.description;
    //         var listItems = tempDiv.querySelectorAll('li');
    //         listItems.forEach(function (li) {
    //             itemsArray.push(li.textContent);
    //         });
    //     });
    //     // Create a Set to store unique values
    //     var uniqueSet = new Set();

    //     itemsArray.forEach(function (item) {
    //         // Split the item by comma if it contains one
    //         if (item.includes(',')) {
    //             var splitItems = item.split(',').map(function (splitItem) {
    //                 // Trim each split item to remove any leading/trailing spaces
    //                 return splitItem.trim();
    //             });
    //             // Add each split item to the set
    //             splitItems.forEach(function (splitItem) {
    //                 uniqueSet.add(splitItem.toLowerCase());
    //             });
    //         } else {
    //             // Add the item to the set as is
    //             uniqueSet.add(item.toLowerCase());
    //         }
    //     });

    //     // Convert the Set back to an array
    //     var uniqueArray = Array.from(uniqueSet);
    //     console.log("uniqueArray", uniqueArray);

    // }, [plans])

    const filterPlansData = (filterPlans) => {
        let itemsArray = [];

        let tempDiv = document.createElement('div');
        tempDiv.innerHTML = filterPlans.description;
        let listItems = tempDiv.querySelectorAll('li');
        listItems.forEach(function (li) {
            itemsArray.push(li.textContent);
        });

        let uniqueSet = [];
        itemsArray.forEach(function (item) {
            if (item.includes(',')) {
                let splitItems = item.split(',').map(function (splitItem) {
                    return splitItem.trim();
                });
                splitItems.forEach(function (splitItem) {
                    uniqueSet.push(splitItem.toLowerCase());
                });
            } else {
                uniqueSet.push(item.toLowerCase());
            }
        });

        let newArray = uniqueSet.map(function (item) {
            return item.replace(/^\d+\s*/, '').trim();
        });

        return newArray
    }


    const getComparisionObject = useMemo(() => {
        const filterPlans = plans?.find((plan) => {
            return (
                plan?.plan_name?.toLowerCase()?.includes("premium")
            )
        })

        if (filterPlans) {
            if (filterPlans && Object?.keys(filterPlans)?.length > 0) {
                const newArray = filterPlansData(filterPlans)
                // let plansObject = {};
                // newArray && newArray?.forEach(function (n) {
                //     plans.forEach(function (plan) {
                //         const newArraySpecificPlan = filterPlansData(plan)
                //         plansObject[n] = 
                //     })
                // })
                let plansObject = {};
                plans.forEach(function (plan) {
                    let itemsArray = []

                    const newArraySpecificPlan = filterPlansData(plan)
                    console.log("newArraySpecificPlan ======", newArraySpecificPlan);


                    const arrayWithKeys = {}
                    newArray?.forEach(function (n) {
                        if (newArraySpecificPlan?.includes(n)) {
                            arrayWithKeys[n] = true
                        } else {
                            arrayWithKeys[n] = false
                        }
                    })
                    plansObject[plan.plan_name] = arrayWithKeys;
                });

                var newObject = {};
                // Iterate over each feature in the inputObject
                Object.keys(plansObject).forEach(function (plan) {
                    Object.keys(plansObject[plan]).forEach(function (feature) {
                        if (!newObject[feature]) {
                            newObject[feature] = {};
                        }
                        newObject[feature][plan] = plansObject[plan][feature];
                    });
                });

                return newObject
            }
        }
    }, [plans])

    console.log("getComparisionObject =============", getComparisionObject);

    return (
        <div class="cu-site">
            <Header />
            <div class="cu-site-content">

                <section class="cu-subscription-section cu-subscriptions-landing-section" id="subscriptions">
                    <div class="container cu-service-content">
                        <h3 class="cu-section-subtitle">Subscriptions</h3>
                        <h3 class="cu-section-title">Find best inpatient care facility for you after hospitalization</h3>
                        {plans && <div class="switch-toggle switch-3 switch-candy">

                            <input id="on" name="state-d" type="radio" checked={planshow === "a-month" ? true : false} onChange={() => setPlanShow("a-month")} />
                            <label for="on" onclick="">1 Month</label>

                            <input id="na" name="state-d" type="radio" checked={planshow === "months" ? true : false} onChange={() => setPlanShow("months")} />
                            <label for="na" class="disabled" onclick="">6 Months</label>

                            <input id="off" name="state-d" type="radio" checked={planshow === "a-year" ? true : false} onChange={() => setPlanShow("a-year")} />
                            <label for="off" onclick="">1 Year</label>

                        </div>}

                        {/* <div class="cu-subscription-plan-filter">
                            <div class="cu-subscription-plan-filter__inner">
                                <div class="form-check form-switch">
                                    <label class="form-check-label form-check-label-month"
                                        for="planQuarterSwitch">Monthly</label>
                                    <input class="form-check-input" type="checkbox" id="planQuarterSwitch" checked />
                                    <label class="form-check-label form-check-label-year active"
                                        for="planQuarterSwitch">Yearly</label>
                                </div>
                            </div>
                            <p class="cu-subscription-plan-discount-badge">30% discount</p>
                        </div> */}
                        <div className="cu-subscription-plan-content">
                            {plans.map((plan, index) => {
                                return (
                                    <div
                                        // className={plan.plan_name.toLowerCase().includes('basic') ? "cu-plan-card" : "cu-plan-card cu-advance-plan-card"}
                                        className={index === 0 ? "cu-plan-card cu-basic-plan-card" : index === 1 ? "cu-plan-card cu-advance-plan-card" : "cu-plan-card cu-premium-plan-card"}

                                    >
                                        <div className="cu-plan-card-head">
                                            <span className="cu-plan-card-icon">
                                                {/* {plan.plan_name.toLowerCase().includes('basic') ? */}
                                                {index === 0 ?
                                                    <svg width="73" height="73" viewBox="0 0 73 73" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M36.4602 70C54.7845 70 69.676 55.2456 69.676 37C69.676 18.7544 54.7845 4 36.4602 4C18.1359 4 3.24438 18.7544 3.24438 37C3.24438 55.2456 18.1359 70 36.4602 70Z"
                                                            stroke="url(#paint0_linear_526_2835)" stroke-width="6" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M50.7764 25.7672C50.7764 23.6961 49.0853 22.0172 46.9994 22.0172L32.7555 22.0172C31.7538 22.0171 30.7931 22.4123 30.0848 23.1156L16.9472 36.1593C15.4722 37.6238 15.4722 39.9982 16.9471 41.4626L31.191 55.6048C32.666 57.0692 35.0575 57.0692 36.5325 55.6048L49.6701 42.561C50.3784 41.8577 50.7764 40.9039 50.7764 39.9093L50.7764 25.7672ZM44.0995 31.7399C44.0995 30.0314 42.7045 28.6463 40.9837 28.6463C39.2629 28.6463 37.8678 30.0314 37.8678 31.7399C37.8678 33.4485 39.2629 34.8335 40.9837 34.8335C42.7045 34.8335 44.0995 33.4485 44.0995 31.7399Z"
                                                            fill="#FF7800" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_526_2835" x1="21.3523" y1="23" x2="72.9087"
                                                                y2="67.3651" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#FF7800" />
                                                                <stop offset="1" stop-color="#FFE6D1" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                    : index === 1 ?
                                                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="35.9998" cy="36" r="33" stroke="#923EF5" stroke-width="6" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M49.3382 23.4377C49.4574 23.4469 49.5521 23.5416 49.5613 23.6608L49.7865 26.5889C50.1944 31.892 48.2647 37.1086 44.5038 40.8696C43.1591 42.2143 41.6121 43.3401 39.9191 44.2064L27.6053 50.5064C27.4063 50.6083 27.1644 50.5701 27.0063 50.4121L22.5869 45.9927C22.4288 45.8346 22.3907 45.5927 22.4925 45.3936L28.7926 33.0799C29.6588 31.3868 30.7847 29.8399 32.1294 28.4952C35.8904 24.7343 41.1069 22.8045 46.41 23.2124L49.3382 23.4377ZM43.8433 31.452C43.5431 30.3313 42.6677 29.4559 41.5469 29.1556C40.4262 28.8553 39.2304 29.1757 38.41 29.9962C37.5896 30.8166 37.2692 32.0124 37.5695 33.1331C37.8698 34.2538 38.7452 35.1292 39.8658 35.4295C40.9866 35.7298 42.1824 35.4094 43.0028 34.589C43.8232 33.7685 44.1436 32.5727 43.8433 31.452Z"
                                                                fill="#6C1AFC" />
                                                            <path
                                                                d="M27.2357 32.0099L22.9173 32.0097C20.9971 32.0096 19.2466 33.1096 18.4135 34.8397L15.5226 40.8439C15.2627 41.3837 15.6562 42.0097 16.2553 42.0094L22.1179 42.0097L27.1233 32.2263C27.1603 32.154 27.1978 32.0817 27.2357 32.0099Z"
                                                                fill="#6C1AFC" />
                                                            <path
                                                                d="M30.7008 51.0292V56.4547C30.7007 57.0544 31.3274 57.4482 31.8678 57.188L37.8698 54.2981C39.6005 53.4649 40.7008 51.7139 40.7008 49.7931V45.9129L30.7008 51.0292Z"
                                                                fill="#6C1AFC" />
                                                        </svg> :
                                                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M54.9932 26.9949L49.5245 43.8258H23.0742L17.6055 26.9949L28.395 33.0782L36.2993 18.1229L44.2037 33.0782L54.9932 26.9949Z" fill="#00657B"></path>
                                                            <path d="M54.9932 26.9949L56.182 27.3812C56.3413 26.8912 56.1835 26.3538 55.7848 26.0275C55.3861 25.7013 54.8281 25.653 54.3793 25.906L54.9932 26.9949ZM49.5245 43.8258V45.0758C50.0661 45.0758 50.546 44.7271 50.7133 44.212L49.5245 43.8258ZM23.0742 43.8258L21.8854 44.212C22.0527 44.7271 22.5327 45.0758 23.0742 45.0758V43.8258ZM17.6055 26.9949L18.2195 25.906C17.7707 25.653 17.2126 25.7013 16.8139 26.0275C16.4152 26.3538 16.2575 26.8912 16.4167 27.3812L17.6055 26.9949ZM28.395 33.0782L27.7811 34.167C28.075 34.3327 28.4235 34.3726 28.7472 34.2775C29.0709 34.1825 29.3425 33.9606 29.5002 33.6622L28.395 33.0782ZM36.2994 18.1229L37.4045 17.5388C37.188 17.1292 36.7627 16.8729 36.2994 16.8729C35.836 16.8729 35.4107 17.1292 35.1942 17.5388L36.2994 18.1229ZM44.2037 33.0782L43.0986 33.6622C43.2563 33.9606 43.5278 34.1825 43.8516 34.2775C44.1753 34.3726 44.5237 34.3327 44.8177 34.167L44.2037 33.0782ZM53.8044 26.6087L48.3357 43.4395L50.7133 44.212L56.182 27.3812L53.8044 26.6087ZM49.5245 42.5758H23.0742V45.0758H49.5245V42.5758ZM24.263 43.4395L18.7943 26.6087L16.4167 27.3812L21.8854 44.212L24.263 43.4395ZM16.9916 28.0838L27.7811 34.167L29.0089 31.9893L18.2195 25.906L16.9916 28.0838ZM29.5002 33.6622L37.4045 18.707L35.1942 17.5388L27.2899 32.4941L29.5002 33.6622ZM35.1942 18.707L43.0986 33.6622L45.3088 32.4941L37.4045 17.5388L35.1942 18.707ZM44.8177 34.167L55.6072 28.0838L54.3793 25.906L43.5898 31.9893L44.8177 34.167Z" fill="#00657B"></path>
                                                            <path d="M48.7993 49.3729H23.7993" stroke="#00657B" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"></path>
                                                            <circle cx="36" cy="36" r="33" stroke="url(#paint0_linear_773_525)" stroke-width="6"></circle>
                                                            <defs>
                                                                <linearGradient id="paint0_linear_773_525" x1="21" y1="22" x2="72.5" y2="66" gradientUnits="userSpaceOnUse">
                                                                    <stop stop-color="#00657B"></stop>
                                                                    <stop offset="1" stop-color="#C4F4FF"></stop>
                                                                </linearGradient>
                                                            </defs>
                                                        </svg>
                                                }
                                            </span>
                                            <h4 className="cu-plan-card-title">{plan.plan_name}</h4>
                                        </div>
                                        <div className="cu-plan-card-body">
                                            {/* <ul className="cu-plan-card-feature-list"> */}
                                            {
                                                parse(plan.description.replace('<ul', "<ul class='cu-plan-card-feature-list'")
                                                    .replaceAll('<li', "<li class='cu-plan-card-feature-item'")
                                                )}
                                            {/* <li className="cu-plan-card-fea ture-item">Access To the ContigoU Web Panel</li>
                                            <li className="cu-plan-card-feature-item">Unlimited Services</li>
                                            <li className="cu-plan-card-feature-item">Unlimited Beds</li>
                                            <li className="cu-plan-card-feature-item">Business profile Management</li>
                                            <li className="cu-plan-card-feature-item">Enquiry Management & Access to
                                                customer contact information and
                                                their details.</li>
                                            <li className="cu-plan-card-feature-item">Premium Customer Support</li>
                                            <li className="cu-plan-card-feature-item">Cancel Anytime</li> */}
                                            {/* </ul> */}
                                        </div>
                                        <div className="cu-plan-card-footer">
                                            <div className="cu-plan-expire">
                                                <h3 className="cu-plan-expire-date cu-plan-amount">{
                                                    planshow === "a-month" ? `$${plan.amount}/Month` :
                                                        planshow === "months" ? `$${plan.amount_months}/6 Months` :
                                                            `$${plan.amount_year}/Year`
                                                }</h3>
                                            </div>
                                            {/* <Link to='/payment-method' state={plan} className="btn btn-info">Get Started</Link> */}
                                            <Link to='/login' className="btn btn-info">Get Started</Link>
                                            {/* <a href="#" className="btn btn-info">Get Strated</a> */}
                                        </div>
                                    </div>
                                )
                            })}
                            {/* <div className="cu-plan-card">
                                <div className="cu-plan-card-head">
                                    <span className="cu-plan-card-icon">
                                        <svg width="73" height="73" viewBox="0 0 73 73" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M36.4602 70C54.7845 70 69.676 55.2456 69.676 37C69.676 18.7544 54.7845 4 36.4602 4C18.1359 4 3.24438 18.7544 3.24438 37C3.24438 55.2456 18.1359 70 36.4602 70Z"
                                                stroke="url(#paint0_linear_526_2835)" stroke-width="6" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M50.7764 25.7672C50.7764 23.6961 49.0853 22.0172 46.9994 22.0172L32.7555 22.0172C31.7538 22.0171 30.7931 22.4123 30.0848 23.1156L16.9472 36.1593C15.4722 37.6238 15.4722 39.9982 16.9471 41.4626L31.191 55.6048C32.666 57.0692 35.0575 57.0692 36.5325 55.6048L49.6701 42.561C50.3784 41.8577 50.7764 40.9039 50.7764 39.9093L50.7764 25.7672ZM44.0995 31.7399C44.0995 30.0314 42.7045 28.6463 40.9837 28.6463C39.2629 28.6463 37.8678 30.0314 37.8678 31.7399C37.8678 33.4485 39.2629 34.8335 40.9837 34.8335C42.7045 34.8335 44.0995 33.4485 44.0995 31.7399Z"
                                                fill="#FF7800" />
                                            <defs>
                                                <linearGradient id="paint0_linear_526_2835" x1="21.3523" y1="23" x2="72.9087"
                                                    y2="67.3651" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FF7800" />
                                                    <stop offset="1" stop-color="#FFE6D1" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                    <h4 className="cu-plan-card-title">Basic</h4>
                                </div>
                                <div className="cu-plan-card-body">
                                    <ul className="cu-plan-card-feature-list">
                                        <li className="cu-plan-card-feature-item">Access To the ContigoU Web Panel</li>
                                        <li className="cu-plan-card-feature-item">Unlimited Services</li>
                                        <li className="cu-plan-card-feature-item">Unlimited Beds</li>
                                        <li className="cu-plan-card-feature-item">Business profile Management</li>
                                        <li className="cu-plan-card-feature-item">Enquiry Management & Access to
                                            customer contact information and
                                            their details.</li>
                                        <li className="cu-plan-card-feature-item">Premium Customer Support</li>
                                        <li className="cu-plan-card-feature-item">Cancel Anytime</li>
                                    </ul>
                                </div>
                                <div className="cu-plan-card-footer">
                                    <div className="cu-plan-expire">
                                        <h3 className="cu-plan-expire-date cu-plan-amount">$210/Month</h3>
                                        <label className="cu-plan-expire-label">When Paid Annually</label>
                                    </div>
                                    <a href="#" className="btn btn-info">Get Strated</a>
                                </div>
                            </div>
                            <div className="cu-plan-card cu-advance-plan-card">
                                <div className="cu-plan-card-head">
                                    <span className="cu-plan-card-icon">
                                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="35.9998" cy="36" r="33" stroke="#923EF5" stroke-width="6" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M49.3382 23.4377C49.4574 23.4469 49.5521 23.5416 49.5613 23.6608L49.7865 26.5889C50.1944 31.892 48.2647 37.1086 44.5038 40.8696C43.1591 42.2143 41.6121 43.3401 39.9191 44.2064L27.6053 50.5064C27.4063 50.6083 27.1644 50.5701 27.0063 50.4121L22.5869 45.9927C22.4288 45.8346 22.3907 45.5927 22.4925 45.3936L28.7926 33.0799C29.6588 31.3868 30.7847 29.8399 32.1294 28.4952C35.8904 24.7343 41.1069 22.8045 46.41 23.2124L49.3382 23.4377ZM43.8433 31.452C43.5431 30.3313 42.6677 29.4559 41.5469 29.1556C40.4262 28.8553 39.2304 29.1757 38.41 29.9962C37.5896 30.8166 37.2692 32.0124 37.5695 33.1331C37.8698 34.2538 38.7452 35.1292 39.8658 35.4295C40.9866 35.7298 42.1824 35.4094 43.0028 34.589C43.8232 33.7685 44.1436 32.5727 43.8433 31.452Z"
                                                fill="#6C1AFC" />
                                            <path
                                                d="M27.2357 32.0099L22.9173 32.0097C20.9971 32.0096 19.2466 33.1096 18.4135 34.8397L15.5226 40.8439C15.2627 41.3837 15.6562 42.0097 16.2553 42.0094L22.1179 42.0097L27.1233 32.2263C27.1603 32.154 27.1978 32.0817 27.2357 32.0099Z"
                                                fill="#6C1AFC" />
                                            <path
                                                d="M30.7008 51.0292V56.4547C30.7007 57.0544 31.3274 57.4482 31.8678 57.188L37.8698 54.2981C39.6005 53.4649 40.7008 51.7139 40.7008 49.7931V45.9129L30.7008 51.0292Z"
                                                fill="#6C1AFC" />
                                        </svg>
                                    </span>
                                    <h4 className="cu-plan-card-title">Advance</h4>
                                </div>
                                <div className="cu-plan-card-body">
                                    <ul className="cu-plan-card-feature-list">
                                        <li className="cu-plan-card-feature-item">Access To the ContiguoU Web Panel
                                        </li>
                                        <li className="cu-plan-card-feature-item">5 Services</li>
                                        <li className="cu-plan-card-feature-item">Upto 100 Beds</li>
                                        <li className="cu-plan-card-feature-item">Business profile Management</li>
                                        <li className="cu-plan-card-feature-item">Enquiry Management & Access to
                                            customer contact information and
                                            their details.</li>
                                        <li className="cu-plan-card-feature-item">Premium Customer Support</li>
                                    </ul>
                                </div>
                                <div className="cu-plan-card-footer">
                                    <div className="cu-plan-expire">
                                        <h3 className="cu-plan-expire-date cu-plan-amount">$300/Month</h3>
                                        <label className="cu-plan-expire-label">When Paid Annually</label>
                                    </div>
                                    <a href="#" className="btn btn-blue">Get Strated</a>
                                </div>
                            </div>
                            <div className="cu-plan-card cu-premium-plan-card">
                                <div className="cu-plan-card-head">
                                    <span className="cu-plan-card-icon">
                                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M54.9932 26.9949L49.5245 43.8258H23.0742L17.6055 26.9949L28.395 33.0782L36.2993 18.1229L44.2037 33.0782L54.9932 26.9949Z"
                                                fill="#00657B" />
                                            <path
                                                d="M54.9932 26.9949L56.182 27.3812C56.3413 26.8912 56.1835 26.3538 55.7848 26.0275C55.3861 25.7013 54.8281 25.653 54.3793 25.906L54.9932 26.9949ZM49.5245 43.8258V45.0758C50.0661 45.0758 50.546 44.7271 50.7133 44.212L49.5245 43.8258ZM23.0742 43.8258L21.8854 44.212C22.0527 44.7271 22.5327 45.0758 23.0742 45.0758V43.8258ZM17.6055 26.9949L18.2195 25.906C17.7707 25.653 17.2126 25.7013 16.8139 26.0275C16.4152 26.3538 16.2575 26.8912 16.4167 27.3812L17.6055 26.9949ZM28.395 33.0782L27.7811 34.167C28.075 34.3327 28.4235 34.3726 28.7472 34.2775C29.0709 34.1825 29.3425 33.9606 29.5002 33.6622L28.395 33.0782ZM36.2994 18.1229L37.4045 17.5388C37.188 17.1292 36.7627 16.8729 36.2994 16.8729C35.836 16.8729 35.4107 17.1292 35.1942 17.5388L36.2994 18.1229ZM44.2037 33.0782L43.0986 33.6622C43.2563 33.9606 43.5278 34.1825 43.8516 34.2775C44.1753 34.3726 44.5237 34.3327 44.8177 34.167L44.2037 33.0782ZM53.8044 26.6087L48.3357 43.4395L50.7133 44.212L56.182 27.3812L53.8044 26.6087ZM49.5245 42.5758H23.0742V45.0758H49.5245V42.5758ZM24.263 43.4395L18.7943 26.6087L16.4167 27.3812L21.8854 44.212L24.263 43.4395ZM16.9916 28.0838L27.7811 34.167L29.0089 31.9893L18.2195 25.906L16.9916 28.0838ZM29.5002 33.6622L37.4045 18.707L35.1942 17.5388L27.2899 32.4941L29.5002 33.6622ZM35.1942 18.707L43.0986 33.6622L45.3088 32.4941L37.4045 17.5388L35.1942 18.707ZM44.8177 34.167L55.6072 28.0838L54.3793 25.906L43.5898 31.9893L44.8177 34.167Z"
                                                fill="#00657B" />
                                            <path d="M48.7993 49.3729H23.7993" stroke="#00657B" stroke-width="6"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <circle cx="36" cy="36" r="33" stroke="url(#paint0_linear_773_525)"
                                                stroke-width="6" />
                                            <defs>
                                                <linearGradient id="paint0_linear_773_525" x1="21" y1="22" x2="72.5" y2="66"
                                                    gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#00657B" />
                                                    <stop offset="1" stop-color="#C4F4FF" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                    <h4 className="cu-plan-card-title">Premium</h4>
                                </div>
                                <div className="cu-plan-card-body">
                                    <ul className="cu-plan-card-feature-list">
                                        <li className="cu-plan-card-feature-item">Access To the ContiguoU Web Panel
                                        </li>
                                        <li className="cu-plan-card-feature-item">Featured Listing </li>
                                        <li className="cu-plan-card-feature-item">Unlimited Services</li>
                                        <li className="cu-plan-card-feature-item">Unlimited beds</li>
                                        <li className="cu-plan-card-feature-item">Business profile Management</li>
                                        <li className="cu-plan-card-feature-item">Enquiry Management & Access to
                                            customer contact information and
                                            their details.</li>
                                        <li className="cu-plan-card-feature-item">Premium Customer Support</li>
                                    </ul>
                                </div>
                                <div className="cu-plan-card-footer">
                                    <div className="cu-plan-expire">
                                        <h3 className="cu-plan-expire-date cu-plan-amount">$650/Month</h3>
                                        <label className="cu-plan-expire-label">When Paid Annually</label>
                                    </div>
                                    <a href="#" className="btn btn-info">Get Strated</a>
                                </div>
                            </div> */}
                        </div>
                        {/* <div class="cu-subscription-plan-content cu-monthly-yearly-plan active">
                            <div class="cu-plan-card cu-basic-plan-card">
                                <div class="cu-plan-card-head">
                                    <span class="cu-plan-card-icon">
                                        <svg width="73" height="73" viewBox="0 0 73 73" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M36.4602 70C54.7845 70 69.676 55.2456 69.676 37C69.676 18.7544 54.7845 4 36.4602 4C18.1359 4 3.24438 18.7544 3.24438 37C3.24438 55.2456 18.1359 70 36.4602 70Z"
                                                stroke="url(#paint0_linear_526_28354)" stroke-width="6" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M50.7764 25.7672C50.7764 23.6961 49.0853 22.0172 46.9994 22.0172L32.7555 22.0172C31.7538 22.0171 30.7931 22.4123 30.0848 23.1156L16.9472 36.1593C15.4722 37.6238 15.4722 39.9982 16.9471 41.4626L31.191 55.6048C32.666 57.0692 35.0575 57.0692 36.5325 55.6048L49.6701 42.561C50.3784 41.8577 50.7764 40.9039 50.7764 39.9093L50.7764 25.7672ZM44.0995 31.7399C44.0995 30.0314 42.7045 28.6463 40.9837 28.6463C39.2629 28.6463 37.8678 30.0314 37.8678 31.7399C37.8678 33.4485 39.2629 34.8335 40.9837 34.8335C42.7045 34.8335 44.0995 33.4485 44.0995 31.7399Z"
                                                fill="#FF7800" />
                                            <defs>
                                                <linearGradient id="paint0_linear_526_28354" x1="21.3523" y1="23" x2="72.9087"
                                                    y2="67.3651" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#FF7800" />
                                                    <stop offset="1" stop-color="#FFE6D1" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                    <h4 class="cu-plan-card-title">Basic</h4>
                                </div>
                                <div class="cu-plan-card-body">
                                    <ul class="cu-plan-card-feature-list">
                                        <li class="cu-plan-card-feature-item">Access To the ContigoU Web Panel</li>
                                        <li class="cu-plan-card-feature-item">Unlimited Services</li>
                                        <li class="cu-plan-card-feature-item">Unlimited Beds</li>
                                        <li class="cu-plan-card-feature-item">Business profile Management</li>
                                        <li class="cu-plan-card-feature-item">Enquiry Management & Access to
                                            customer contact information and
                                            their details.</li>
                                        <li class="cu-plan-card-feature-item">Premium Customer Support</li>
                                        <li class="cu-plan-card-feature-item">Cancel Anytime</li>
                                    </ul>
                                </div>
                                <div class="cu-plan-card-footer">
                                    <div class="cu-plan-expire">
                                        <h3 class="cu-plan-expire-date cu-plan-amount">$210/Year</h3>
                                        <label class="cu-plan-expire-label">When Paid Annually</label>
                                    </div>
                                    <a href="#" class="btn btn-info">Get Strated</a>
                                </div>
                            </div>
                            <div class="cu-plan-card cu-advance-plan-card">
                                <div class="cu-plan-card-head">
                                    <span class="cu-plan-card-icon">
                                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="35.9998" cy="36" r="33" stroke="#923EF5" stroke-width="6" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M49.3382 23.4377C49.4574 23.4469 49.5521 23.5416 49.5613 23.6608L49.7865 26.5889C50.1944 31.892 48.2647 37.1086 44.5038 40.8696C43.1591 42.2143 41.6121 43.3401 39.9191 44.2064L27.6053 50.5064C27.4063 50.6083 27.1644 50.5701 27.0063 50.4121L22.5869 45.9927C22.4288 45.8346 22.3907 45.5927 22.4925 45.3936L28.7926 33.0799C29.6588 31.3868 30.7847 29.8399 32.1294 28.4952C35.8904 24.7343 41.1069 22.8045 46.41 23.2124L49.3382 23.4377ZM43.8433 31.452C43.5431 30.3313 42.6677 29.4559 41.5469 29.1556C40.4262 28.8553 39.2304 29.1757 38.41 29.9962C37.5896 30.8166 37.2692 32.0124 37.5695 33.1331C37.8698 34.2538 38.7452 35.1292 39.8658 35.4295C40.9866 35.7298 42.1824 35.4094 43.0028 34.589C43.8232 33.7685 44.1436 32.5727 43.8433 31.452Z"
                                                fill="#6C1AFC" />
                                            <path
                                                d="M27.2357 32.0099L22.9173 32.0097C20.9971 32.0096 19.2466 33.1096 18.4135 34.8397L15.5226 40.8439C15.2627 41.3837 15.6562 42.0097 16.2553 42.0094L22.1179 42.0097L27.1233 32.2263C27.1603 32.154 27.1978 32.0817 27.2357 32.0099Z"
                                                fill="#6C1AFC" />
                                            <path
                                                d="M30.7008 51.0292V56.4547C30.7007 57.0544 31.3274 57.4482 31.8678 57.188L37.8698 54.2981C39.6005 53.4649 40.7008 51.7139 40.7008 49.7931V45.9129L30.7008 51.0292Z"
                                                fill="#6C1AFC" />
                                        </svg>
                                    </span>
                                    <h4 class="cu-plan-card-title">Advance</h4>
                                </div>
                                <div class="cu-plan-card-body">
                                    <ul class="cu-plan-card-feature-list">
                                        <li class="cu-plan-card-feature-item">Access To the ContiguoU Web Panel
                                        </li>
                                        <li class="cu-plan-card-feature-item">5 Services</li>
                                        <li class="cu-plan-card-feature-item">Upto 100 Beds</li>
                                        <li class="cu-plan-card-feature-item">Business profile Management</li>
                                        <li class="cu-plan-card-feature-item">Enquiry Management & Access to
                                            customer contact information and
                                            their details.</li>
                                        <li class="cu-plan-card-feature-item">Premium Customer Support</li>
                                    </ul>
                                </div>
                                <div class="cu-plan-card-footer">
                                    <div class="cu-plan-expire">
                                        <h3 class="cu-plan-expire-date cu-plan-amount">$300/Year</h3>
                                        <label class="cu-plan-expire-label">When Paid Annually</label>
                                    </div>
                                    <a href="#" class="btn btn-blue">Get Strated</a>
                                </div>
                            </div>
                            <div class="cu-plan-card cu-premium-plan-card">
                                <div class="cu-plan-card-head">
                                    <span class="cu-plan-card-icon">
                                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M54.9932 26.9949L49.5245 43.8258H23.0742L17.6055 26.9949L28.395 33.0782L36.2993 18.1229L44.2037 33.0782L54.9932 26.9949Z"
                                                fill="#00657B" />
                                            <path
                                                d="M54.9932 26.9949L56.182 27.3812C56.3413 26.8912 56.1835 26.3538 55.7848 26.0275C55.3861 25.7013 54.8281 25.653 54.3793 25.906L54.9932 26.9949ZM49.5245 43.8258V45.0758C50.0661 45.0758 50.546 44.7271 50.7133 44.212L49.5245 43.8258ZM23.0742 43.8258L21.8854 44.212C22.0527 44.7271 22.5327 45.0758 23.0742 45.0758V43.8258ZM17.6055 26.9949L18.2195 25.906C17.7707 25.653 17.2126 25.7013 16.8139 26.0275C16.4152 26.3538 16.2575 26.8912 16.4167 27.3812L17.6055 26.9949ZM28.395 33.0782L27.7811 34.167C28.075 34.3327 28.4235 34.3726 28.7472 34.2775C29.0709 34.1825 29.3425 33.9606 29.5002 33.6622L28.395 33.0782ZM36.2994 18.1229L37.4045 17.5388C37.188 17.1292 36.7627 16.8729 36.2994 16.8729C35.836 16.8729 35.4107 17.1292 35.1942 17.5388L36.2994 18.1229ZM44.2037 33.0782L43.0986 33.6622C43.2563 33.9606 43.5278 34.1825 43.8516 34.2775C44.1753 34.3726 44.5237 34.3327 44.8177 34.167L44.2037 33.0782ZM53.8044 26.6087L48.3357 43.4395L50.7133 44.212L56.182 27.3812L53.8044 26.6087ZM49.5245 42.5758H23.0742V45.0758H49.5245V42.5758ZM24.263 43.4395L18.7943 26.6087L16.4167 27.3812L21.8854 44.212L24.263 43.4395ZM16.9916 28.0838L27.7811 34.167L29.0089 31.9893L18.2195 25.906L16.9916 28.0838ZM29.5002 33.6622L37.4045 18.707L35.1942 17.5388L27.2899 32.4941L29.5002 33.6622ZM35.1942 18.707L43.0986 33.6622L45.3088 32.4941L37.4045 17.5388L35.1942 18.707ZM44.8177 34.167L55.6072 28.0838L54.3793 25.906L43.5898 31.9893L44.8177 34.167Z"
                                                fill="#00657B" />
                                            <path d="M48.7993 49.3729H23.7993" stroke="#00657B" stroke-width="6"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <circle cx="36" cy="36" r="33" stroke="url(#paint0_linear_773_525)"
                                                stroke-width="6" />
                                            <defs>
                                                <linearGradient id="paint0_linear_773_525" x1="21" y1="22" x2="72.5" y2="66"
                                                    gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#00657B" />
                                                    <stop offset="1" stop-color="#C4F4FF" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                    <h4 class="cu-plan-card-title">Premium</h4>
                                </div>
                                <div class="cu-plan-card-body">
                                    <ul class="cu-plan-card-feature-list">
                                        <li class="cu-plan-card-feature-item">Access To the ContiguoU Web Panel
                                        </li>
                                        <li class="cu-plan-card-feature-item">Featured Listing </li>
                                        <li class="cu-plan-card-feature-item">Unlimited Services</li>
                                        <li class="cu-plan-card-feature-item">Unlimited beds</li>
                                        <li class="cu-plan-card-feature-item">Business profile Management</li>
                                        <li class="cu-plan-card-feature-item">Enquiry Management & Access to
                                            customer contact information and
                                            their details.</li>
                                        <li class="cu-plan-card-feature-item">Premium Customer Support</li>
                                    </ul>
                                </div>
                                <div class="cu-plan-card-footer">
                                    <div class="cu-plan-expire">
                                        <h3 class="cu-plan-expire-date cu-plan-amount">$650/Year</h3>
                                        <label class="cu-plan-expire-label">When Paid Annually</label>
                                    </div>
                                    <a href="#" class="btn btn-info">Get Strated</a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>


                <section class="cu-compare-plans-section">
                    <div class="container">
                        <h3 class="cu-section-subtitle">Compare plans</h3>
                        <h2 class="cu-section-title"> Find the subscription that works best for your nursing center. We've got options designed for you.</h2>
                        <div class="cu-compare-plans-content">
                            <div class="cu-compare-plans-top-header">
                                <div class="cu-compare-plans-row">
                                    <div class="cu-compare-plans-cell">
                                    </div>
                                    {
                                        plans && plans?.map((key, index) => {
                                            return (
                                                <div class="cu-compare-plans-cell" key={index}>
                                                    <h3 class="cu-compare-plans-title">{key?.plan_name}</h3>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div class="cu-compare-plans-table">
                                {
                                    getComparisionObject && Object.entries(getComparisionObject)?.map(([k, val]) => {
                                        return (
                                            <div class="cu-compare-plans-row">
                                                <div class="cu-compare-plans-cell">
                                                    <div class="cu-compare-plans-item">
                                                        <p class="cu-compare-plans-item-text" style={{ fontSize: "17px" }}>{k}</p>
                                                    </div>
                                                </div>
                                                {
                                                    Object?.entries(val)?.map(([ik, ival]) => {
                                                        return (
                                                            ival ?
                                                                <div class="cu-compare-plans-cell">
                                                                    <div class="text-center">
                                                                        <img src="assets/images/check-icon.svg" alt="check-icon" class="img-fluid" />
                                                                    </div>
                                                                </div> :
                                                                <div class="cu-compare-plans-cell" style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                                                                    <div class="text-center" style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", height: "15px", width: "15px", background: "red" }}>
                                                                        <RxCross2 color='#fff' size={10} />
                                                                    </div>
                                                                </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </section>

                <section class="cu-faq-section">
                    <div class="container">
                        <div class="cu-section-heading">
                            <h2 class="cu-section-title">Frequently Asked Questions</h2>
                            <p class="cu-section-description">Got questions? We have answers. Check out our FAQ for common questions or reach out if you need help.</p>
                        </div>
                        <div class="cu-faq-content-area">
                            <div class="row">
                                <div class="col col-12 col-md-6 col-lg-7">
                                    <div class="cu-faq-content">
                                        <div class="accordion accordion-flush" id="faqAccordion">
                                            {faqs?.map((el) =>
                                                <div class="accordion-item" key={el?.id}>
                                                    <h2 class="accordion-header" id="faq-heading1">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target={`#faq-collapse${el?.id}`}
                                                            aria-expanded="false" aria-controls={`faq-collapse${el?.id}`}>
                                                            {el?.ques}
                                                        </button>
                                                    </h2>
                                                    <div id={`faq-collapse${el?.id}`} class="accordion-collapse collapse"
                                                        aria-labelledby="faq-heading1" data-bs-parent="#faqAccordion">
                                                        <div class="accordion-body">
                                                            {parse(el?.ans)}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-12 col-md-6 col-lg-5">
                                    <div class="cu-faq-img">
                                        <img src="assets/images/faq-img.png" alt="faq-img" class="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="cu-contact-section" id="contacu_us">
                    <div class="container">
                        <div class="cu-contact-heading">
                            <h3 class="cu-section-subtitle">Need Help</h3>
                            <h2 class="cu-section-title">Feel Free to Contact Us for Any Questions or Concerns</h2>
                        </div>
                        <div class="cu-contact-details">
                            <div class="cu-contact-item">
                                <div class="cu-contact-item-icon">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M22.3833 13.1666C23.5622 13.171 24.6915 13.6413 25.5251 14.4749C26.3587 15.3084 26.8289 16.4378 26.8333 17.6166"
                                            stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path
                                            d="M22.3833 7.83337C24.978 7.83337 27.4664 8.86412 29.3012 10.6988C31.1359 12.5336 32.1666 15.022 32.1666 17.6167"
                                            stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path
                                            d="M22.3833 2.5C26.3925 2.5 30.2375 4.09264 33.0724 6.92757C35.9073 9.76249 37.5 13.6075 37.5 17.6167"
                                            stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path
                                            d="M31.25 35.6333C27.2 39.6666 17.9166 36.95 10.4833 29.5166C3.04997 22.0833 0.333305 12.8 4.36664 8.74998L8.79997 4.31665L17.35 12.8667L14.15 16.1C13.5067 16.7465 13.1457 17.6213 13.1457 18.5333C13.1457 19.4453 13.5067 20.3202 14.15 20.9666L19.0333 25.85C19.6798 26.4932 20.5547 26.8543 21.4666 26.8543C22.3786 26.8543 23.2535 26.4932 23.9 25.85L27.1333 22.65L35.6833 31.2L31.25 35.6333Z"
                                            stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
                                    </svg>
                                </div>
                                <div class="cu-contact-item__inner">
                                    <h3 class="cu-contact-item-title">For Any Help Call US</h3>
                                    <p class="cu-contact-item-text">(916) 9342471</p>
                                </div>
                            </div>
                            <div class="cu-contact-item">
                                <div class="cu-contact-item-icon">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M37.5 7.26672H2.5V32.7167H37.5V7.26672Z" stroke="white" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                        <path d="M2.5 7.26672L20 24.7667L37.5 7.26672" stroke="white" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                    </svg>
                                </div>
                                <div class="cu-contact-item__inner">
                                    <h3 class="cu-contact-item-title">For more details mail us on</h3>
                                    <p class="cu-contact-item-text">support@contigou.com</p>
                                </div>
                            </div>
                        </div>
                        <ContactUs />
                    </div>
                </section>

            </div>
            <Footer value={"Subscription"} />
        </div>
    )
}

export default Subscription