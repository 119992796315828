import React, { useEffect, useState, useMemo } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from './Header'
import Sidebar from './Sidebar'
import axios from 'axios';
import { CONTIGO_API_URL } from '../BaseURL';
import { toast, Toaster } from 'react-hot-toast';

import { Search } from './Table';


const Facility = () => {
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(true); // New state for loading

    // useEffect(() => {
    //     if (localStorage.getItem('CONTIGO_TOKEN_BUSINESS') === null) {
    //         nav('/login')
    //     }
    // }, []);

    useEffect(() => {
        if (localStorage.getItem('CONTIGO_TOKEN_BUSINESS') === null) {
            nav('/login')
        }

        // getWidgets();
        checkSubscription()
    }, [])

    const checkSubscription = () => {

        axios({
            method: 'post',
            url: `${CONTIGO_API_URL}api/nursing-user/check-subscription`,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_BUSINESS')}` }
        }).then((response) => {
            if (response.data.status === true) {

            } else {
                nav('/subscription-plans')
            }
        }).catch((error) => {
            toast.error(error?.response?.data?.message || 'Something went wrong')
            console?.log("Errors", error);
            if (error.response.status === 401) {
                localStorage.removeItem('CONTIGO_TOKEN_BUSINESS');
                nav('/login')
            }
        });
    }


    const nav = useNavigate();
    const location = useLocation();
    const [list, setList] = useState([])
    const [deleteId, setDeleteId] = useState()
    const [disable, setDisable] = useState(true);

    useEffect(() => {
        getFacility();
    }, [])


    const setModalData = (id) => {
        setDeleteId(id);
        console.log(id, "delete id")
    }

    const getFacility = () => {
        const myurl = `${CONTIGO_API_URL}api/nursing-user/facilities`;
        setIsLoading(true)
        axios({
            method: "get",
            url: myurl,
            // data: fd,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('CONTIGO_TOKEN_BUSINESS') },
        }).then(async (response) => {
            console?.log("$$", response?.data);
            if (response.data.status) {
                setList(response?.data?.data);
            }
        }).catch((error) => {
            toast.error(error?.response?.data?.message || 'Something went wrong')
            console?.log("Errors", error);
            if (error?.response?.status === 401) {
                localStorage.removeItem('CONTIGO_TOKEN_BUSINESS');
                nav('/login')
            }
        }).finally(() => {
            setIsLoading(false)
        });
    }

    const deleteFacility = (e, id) => {
        e.preventDefault()
        setDisable(true);
        const myurl = `${CONTIGO_API_URL}api/nursing-user/delete-facility`;
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('id', deleteId);
        console.log(deleteId, "id of delete")

        axios({
            method: 'post',
            url: myurl,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_BUSINESS')}` },
        })
            .then((response) => {
                if (response?.data?.status === true) {
                    toast.success(response?.data?.message);
                    getFacility();
                }
                setDisable(false);
            })
            .catch((error) => {
                console.log('Errors', error);
                toast.error('Something went wrong...!');
                setDisable(false);
            });

    }


    const filterList = useMemo(() => {

        if (search?.length > 0) {
            const finalFilterList = list?.filter((d) => {
                return (
                    d?.name?.toLowerCase()?.includes(search?.toLowerCase())
                )
            })
            return finalFilterList
        } else {
            return list
        }
    }, [search, list])

    return (
        <div className='cu-site cu-dashboard-site'>
            <Header />
            <main className="cu-dashboard-content">
                <Sidebar />
                <div className="cu-dashboard-content-area" data-scrollbar>
                    <div className="cu-service-content">
                        <div className="cu-dashboard-inner-head">
                            <h1 className="cu-dashboard-head-title">Amenities</h1>
                            <div className="cu-inquiry-filter" style={{
                                justifyContent: "flex-end", marginRight: "0px"

                            }}>
                                <div className="cu-input-icon-group">
                                    <Search
                                        placeholder={"Search By Amenity  Name"}
                                        onSearch={(value) => {
                                            setSearch(value);
                                        }} />
                                </div>
                                <Link to="/manage-amenity" className="cu-dashboard-head-link">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z"
                                            fill="#00657B" />
                                    </svg>
                                    <span>Add New</span>
                                </Link>
                            </div>
                        </div>
                        {isLoading ? (
                            <div className="loading-screen">
                                <span className='loading-icon'>
                                    <img src='/assets/images/loading.gif' />
                                </span>
                            </div>
                        ) : filterList?.length <= 0 ?
                            <div style={{ textAlign: "center" }}>
                                Amenities Not Found
                            </div> :
                            <div className="cu-service-area">
                                <div className="row">
                                    {filterList.map((e) =>
                                        <div className="col col-12 col-md-6 col-lg-6 col-xl-4">
                                            <div className="cu-all-service-card">
                                                <div className="cu-all-service-card-img">
                                                    <img src={e?.image} alt="service" className="img-fluid" />
                                                </div>
                                                <div className="cu-all-service-card-body">
                                                    <h2 className="cu-all-service-card-title">{e?.name.length > 25 ? e?.name.substring(0, 25) + '...' : e?.name}</h2>
                                                    <p className="cu-all-service-card-description" style={{wordWrap: "break-word"}}>{e?.description?.length > 100 ? e.description.slice(0, 100) + '...' : e.description || ""}</p>
                                                </div>
                                                <div className="cu-all-service-card-footer">
                                                    <span className=" cu-card-btn btn-edit" style={{ cursor: "pointer" }} onClick={() => nav('/manage-amenity', { state: e })}>Edit</span>
                                                    <a href="" className=" cu-card-btn btn-delete" data-bs-toggle="modal"
                                                        onClick={() => setModalData(e?.id)}
                                                        data-bs-target="#deleteModal">Delete</a>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {/* <div className="col col-12 col-md-6 col-lg-6 col-xl-4">
                                    <div className="cu-all-service-card">
                                        <div className="cu-all-service-card-img">
                                            <img src="assets/images/facility2.png" alt="service" className="img-fluid" />
                                        </div>
                                        <div className="cu-all-service-card-body">
                                            <h2 className="cu-all-service-card-title">Gaming and Therapies</h2>
                                            <p className="cu-all-service-card-description">The Nike renew rival 2 returns with a
                                                redesigned upper in a more traditional.</p>
                                        </div>
                                        <div className="cu-all-service-card-footer">
                                            <a href="#" className=" cu-card-btn btn-edit">Edit</a>
                                            <a href="#" data-bs-toggle="modal" data-bs-target="#deleteModal"
                                                className=" cu-card-btn btn-delete">Delete</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-6 col-xl-4">
                                    <div className="cu-all-service-card">
                                        <div className="cu-all-service-card-img">
                                            <img src="assets/images/facility3.png" alt="service" className="img-fluid" />
                                        </div>
                                        <div className="cu-all-service-card-body">
                                            <h2 className="cu-all-service-card-title">Clean Rehabs</h2>
                                            <p className="cu-all-service-card-description">The Nike renew rival 2 returns with a
                                                redesigned upper in a more traditional.</p>
                                        </div>
                                        <div className="cu-all-service-card-footer">
                                            <a href="#" className=" cu-card-btn btn-edit">Edit</a>
                                            <a href="#" data-bs-toggle="modal" data-bs-target="#deleteModal"
                                                className=" cu-card-btn btn-delete">Delete</a>
                                        </div>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </main>

            {/* <!-- Delete Modal --> */}
            <div className="modal fade cu-delete-modal" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content ">
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="cu-thank-you-content">
                                <div className="cu-delete-icon">
                                    <svg width="62" height="62" viewBox="0 0 62 62" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M43.607 58.1251H18.3937C17.1354 58.1268 15.9246 57.6453 15.0112 56.78C14.0978 55.9146 13.5516 54.7316 13.4853 53.4751L11.2637 13.7434H50.737L48.5153 53.4751C48.4491 54.7316 47.9029 55.9146 46.9895 56.78C46.0761 57.6453 44.8652 58.1268 43.607 58.1251Z"
                                            stroke="#C90303" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path d="M6.33008 13.7434H55.6717" stroke="#C90303" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                        <path
                                            d="M26.065 3.875H35.9334C37.242 3.875 38.497 4.39485 39.4223 5.32018C40.3477 6.24552 40.8675 7.50054 40.8675 8.80917V13.7433H21.1309V8.80917C21.1309 7.50054 21.6507 6.24552 22.576 5.32018C23.5014 4.39485 24.7564 3.875 26.065 3.875Z"
                                            stroke="#C90303" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path d="M31 21.1316V50.7366" stroke="#C90303" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                        <path d="M40.8691 21.1316V50.7366" stroke="#C90303" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                        <path d="M21.1309 21.1316V50.7366" stroke="#C90303" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                    </svg>
                                </div>
                                <h3 className="cu-thank-you-title">Are You Sure you want to delete this ?</h3>
                                <div className="cu-modal-btn">
                                    <button className="btn btn-danger" type="button" data-bs-dismiss="modal"
                                        aria-label="Close" onClick={deleteFacility}>Delete </button>
                                    <button className="btn btn-info" style={{ backgroundColor: "#78797A", borderColor: "#78797A" }} type="button" data-bs-dismiss="modal" aria-label="Close" >Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Facility