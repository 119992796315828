import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './Header'
import Sidebar from './Sidebar'
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements
} from '@stripe/react-stripe-js';

import CheckoutForm from "./Checkout";
import axios from 'axios';
import { CONTIGO_API_URL } from '../BaseURL';
import { Box, Divider, List, ListItem, Radio, RadioGroup, Typography } from '@mui/material';
import { toast, Toaster } from 'react-hot-toast';
const stripePromise = loadStripe("pk_test_51LOJ6bIhqAVNscPwePh5C2yeWR5MM8UG1DoNDPzHvWWbG7PT5o8A71lEgaCT3QyvTGL8ocxRxp42PIMMOts5Ua9d00mHYepjse");


const Payment = () => {

    const nav = useNavigate();
    const location = useLocation();

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");

    useEffect(() => {
        if (localStorage.getItem('CONTIGO_TOKEN_BUSINESS') === null) {
            nav('/login')
        }

        getPaymentMethods();
    }, [])




    const getPaymentMethods = () => {
        axios({
            method: 'post',
            url: `${CONTIGO_API_URL}api/nursing-user/get-payment-methods`,
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_BUSINESS')}` }
        }).then((response) => {
            if (response.data.status === true) {
                setPaymentMethods(response.data.data)

            }
        }).catch((error) => {
            toast.error(error?.response?.data?.message || "Something went wrong");
            console?.log("Errors", error);
            if (error.response.status === 401) {
                localStorage.removeItem('CONTIGO_TOKEN_BUSINESS');
                nav('/login')
            }
        });
    }

    const deleteCard = (id, last4) => {
        const isConfirm = window.confirm("Are you sure to delete **** **** **** " + last4 + " card");

        if (isConfirm) {
            axios({
                method: 'post',
                data: {
                    payment_method_id: id
                },
                url: `${CONTIGO_API_URL}api/nursing-user/remove-payment-method`,
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_BUSINESS')}` }
            }).then((response) => {
                if (response.data.status === true) {
                    toast.success(response.data.message);
                    getPaymentMethods();

                }
                else {
                    toast.error(response.data.message);
                }
            }).catch((error) => {
                toast.error(error?.response?.data?.message || "Something went wrong");
                console?.log("Errors", error);
                if (error.response.status === 401) {
                    localStorage.removeItem('CONTIGO_TOKEN_BUSINESS');
                    nav('/login')
                }
            });
        }

    }

    const selectCardImage = (brand) => {
        if (brand == "visa") {
            return "/assets/images/visa.svg";
        }
        else if (brand == "mastercard") {
            return "/assets/images/mastercard.svg";
        }
        else if (brand == "discover") {
            return "/assets/images/discover.svg";
        }
        else if (brand == "amex") {
            return "/assets/images/amex.svg";
        }
        else {
            return "/assets/images/cards.svg";
        }
    }

    return (
        <div className='cu-site cu-dashboard-site'>
            <Header />
            <main className="cu-dashboard-content">
                <Sidebar />
                <div className="cu-dashboard-content-area" data-scrollbar>
                    <div className="cu-payment-content">
                        <div className="cu-payment-card-list">

                        </div>
                        <div className="cu-add-card-content">
                            <h3 className="cu-pay-useing-title">Pay Using Credit Cards</h3>
                            <ul className="cu-pay-useing-card-list list-unstyled">
                                <li className="cu-pay-useing-card-item">
                                    <img src="assets/images/card1.png" alt="Logo" className="img-fluid" />
                                </li>
                                <li className="cu-pay-useing-card-item">
                                    <img src="assets/images/visa-icon.png" alt="Logo" className="img-fluid" />
                                </li>
                                <li className="cu-pay-useing-card-item">
                                    <img src="assets/images/master-icon.png" alt="Logo" className="img-fluid" />
                                </li>
                            </ul>
                            <div className="cu-add-card-area" style={{ fontFamily: "Roboto,Helvetica,Arial,sans-serif" }}>


                                <Box sx={{ minWidth: 240 }}>
                                    <Box
                                        sx={{
                                            mb: 2,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            id="example-payment-channel-label"
                                            level="title-md"
                                            textColor={'text.secondary'}
                                            fontWeight="xl"
                                        >
                                            Pay with
                                        </Typography>

                                    </Box>
                                    <RadioGroup
                                        aria-labelledby="example-payment-channel-label"
                                        overlay
                                        name="example-payment-channel"
                                        onChange={(e) => { setSelectedOption(e.target.value) }}

                                    >
                                        <List
                                            component="div"
                                            variant="outlined"
                                            orientation="vertical"
                                            sx={{
                                                borderRadius: 'sm',
                                                boxShadow: 'sm',
                                            }}
                                        >
                                            <div style={{ maxHeight: "290px", "overflow": "overlay" }}>
                                                {paymentMethods.map((value, index) => (
                                                    <React.Fragment key={value.id}>
                                                        {index !== 0 && <Divider />}
                                                        <ListItem className='payment-method-list' style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <Radio id={value.id} value={value.id} label={value.id} />

                                                            <img src={selectCardImage(value.brand)} height="25px" width="auto" />
                                                            <div style={{ minWidth: "160px", }}>

                                                                **** **** **** {value.last4} &nbsp;&nbsp;
                                                            </div>
                                                            <span className='text-danger'>Expire On : </span>{value.exp_month.toLocaleString('en-US', { useGrouping: false, minimumIntegerDigits: 2 }) + "/" + value.exp_year}
                                                            <span className='text-danger' style={{ cursor: "pointer" }} onClick={() => { deleteCard(value.id, value.last4) }}>
                                                                <i className='fa fa-trash'></i>
                                                            </span>
                                                        </ListItem>
                                                    </React.Fragment>
                                                ))}
                                            </div>

                                            <React.Fragment key={paymentMethods.length}>
                                                <Divider />
                                                <ListItem>
                                                    <Radio id={paymentMethods.length} value={"Other Card"} label={"Other Card"} />{"Other Card"}
                                                </ListItem>
                                            </React.Fragment>
                                        </List>
                                    </RadioGroup>
                                </Box>
                                <div >

                                    <Elements Elements stripe={stripePromise} >
                                        <CheckoutForm plan={location.state} hidden={(selectedOption === "Other Card") ? "" : "hidden"} paymentMethod={selectedOption} />
                                    </Elements>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {/* <!-- Delete Modal --> */}
            <div className="modal fade cu-delete-modal" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content ">
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="cu-thank-you-content">
                                <div className="cu-delete-icon">
                                    <svg width="62" height="62" viewBox="0 0 62 62" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M43.607 58.1251H18.3937C17.1354 58.1268 15.9246 57.6453 15.0112 56.78C14.0978 55.9146 13.5516 54.7316 13.4853 53.4751L11.2637 13.7434H50.737L48.5153 53.4751C48.4491 54.7316 47.9029 55.9146 46.9895 56.78C46.0761 57.6453 44.8652 58.1268 43.607 58.1251Z"
                                            stroke="#C90303" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path d="M6.33008 13.7434H55.6717" stroke="#C90303" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                        <path
                                            d="M26.065 3.875H35.9334C37.242 3.875 38.497 4.39485 39.4223 5.32018C40.3477 6.24552 40.8675 7.50054 40.8675 8.80917V13.7433H21.1309V8.80917C21.1309 7.50054 21.6507 6.24552 22.576 5.32018C23.5014 4.39485 24.7564 3.875 26.065 3.875Z"
                                            stroke="#C90303" stroke-width="1.5" stroke-miterlimit="10" />
                                        <path d="M31 21.1316V50.7366" stroke="#C90303" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                        <path d="M40.8691 21.1316V50.7366" stroke="#C90303" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                        <path d="M21.1309 21.1316V50.7366" stroke="#C90303" stroke-width="1.5"
                                            stroke-miterlimit="10" />
                                    </svg>
                                </div>
                                <h3 className="cu-thank-you-title">Are You Sure you want to delete this ?</h3>
                                <div className="cu-modal-btn">
                                    <button className="btn btn-danger" type="button" data-bs-dismiss="modal"
                                        aria-label="Close">Delete </button>
                                    <button className="btn btn-info" type="button" data-bs-dismiss="modal" aria-label="Close">Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Payment