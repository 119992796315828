import React from 'react'
import Header from './layouts/Header'
import Footer from './layouts/Footer'

const AboutUs = () => {
    return (
        <div class="cu-site">
            <Header />

            <div class="cu-site-content">
                <section class="cu-about-landing-section">
                    <div class="container">
                        <div class="row">
                            <div class="col col-12 col-md-12 col-lg-9">
                                <div class="cu-about-landing-ccontent">
                                    <h1 class="cu-section-title">About Us</h1>
                                    <p class="cu-section-description">ContigoU (WithU) we care about you and our communities and help by connecting people to healthcare and resources.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="cu-vision-section">
                    <div class="container">
                        <div class="row justify-content-between align-items-center">
                            <div class="col col-12 col-md-6 col-lg-5">
                                <div class="cu-vision-content">
                                    <h2 class="cu-section-title">Our Vision</h2>
                                    <p class="cu-section-description">ContigoU's vision is to be earth's customer centric company, where they can be empowered with connection and resources to choose and make their connections and decisions for a better health.</p>
                                </div>
                            </div>
                            <div class="col col-12 col-md-6 col-lg-5">
                                <div class="cu-vision-img">
                                    <img src="assets/images/vision-img.png" alt="vision" class="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="cu-vision-section cu-story-section">
                    <div class="container">
                        <div class="row justify-content-between align-items-center">
                            <div class="col col-12 col-md-6 col-lg-5">
                                <div class="cu-vision-img">
                                    <img src="assets/images/story-img.png" alt="story" class="img-fluid" />
                                </div>
                            </div>
                            <div class="col col-12 col-md-6 col-lg-5">
                                <div class="cu-vision-content">
                                    <h2 class="cu-section-title">Our Story</h2>
                                    <p class="cu-section-description">Renee started ContigoU for three big reasons. First, as a nurse in the hospital, she saw how hard it was for healthcare workers and patients when they didn't have the right connections and resources. Second, as someone with family and friends, Renee knew how tough it could be when someone got a bad diagnosis or a loved one didn't come home, especially without the right information. Third, as a person, she believed everyone should have the power to make their own choices with the help of resources and contacts.
                                        To tackle these challenges, Renee teamed up with a group of skilled and caring people who wanted to help others. Together, they created ContigoU, so you won't have to face these tough situations now or in the future. ContigoU is here to make connecting with Skilled Nursing Rehab and all your healthcare needs easy for you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="cu-vision-section">
                    <div class="container">
                        <div class="row justify-content-between align-items-center">
                            <div class="col col-12 col-md-6 col-lg-5">
                                <div class="cu-vision-content">
                                    <h2 class="cu-section-title">Our Process</h2>
                                    {/* <div class="cu-section-description">
                                        <ol class="cu-process-list">
                                            <li class="cu-process-item"><span>Consultation:</span> Patients or their families
                                                provide relevant medical
                                                information and preferences.</li>
                                            <li class="cu-process-item"><span>Personalized Matchmaking:</span> Our expert team
                                                carefully matches
                                                patients with the most suitable post-acute care providers from our trusted
                                                network.</li>
                                            <li class="cu-process-item"><span>Smooth Transitions:</span> We facilitate seamless
                                                transitions from
                                                hospitals to post-acute care facilities, ensuring continuity of care.</li>
                                            <li class="cu-process-item"><span>Ongoing Support:</span> Our team remains available
                                                24/7 to address any
                                                concerns or provide assistance throughout the recovery journey.</li>
                                            <li class="cu-process-item"><span>Feedback and Improvement:</span> We welcome
                                                patient feedback to
                                                continually improve our services and enhance the patient experience.</li>
                                        </ol>
                                    </div> */}
                                    <div className="cu-section-description">
                                        <p>"Our Process: Wondering how our platform works? It’s easy.</p><br />
                                        <p>Patients: Browse Skilled Nursing</p><br />
                                        <p>We'll show you how we make it simple for skilled nursing centers and patients to connect."</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-12 col-md-6 col-lg-5">
                                <div class="cu-vision-img">
                                    <img src="assets/images/process-img.png" alt="process" class="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="cu-why-choose-section">
                    <div class="container">
                        <div class="cu-section-heading">
                            <h2 class="cu-section-title">Why choose Us</h2>
                            <p class="cu-section-description">Discover why so many skilled nursing centers trust us. We're all about making things efficient, clear, and focused on patients. </p>
                        </div>
                        <div class="cu-why-choose-content">
                            <div class="row">
                                <div class="col col-12 col-md-6 col-lg-6">
                                    <div class="cu-why-choose-card">
                                        <span class="cu-why-choose-icon">
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M42 21.7778C42 35.1118 30.666 41.778 24 44C17.334 41.778 6 35.1118 6 21.7778C6 19.4038 6 12.274 6 10C16 8 19.5561 6.222 24.0361 4C28.4461 6.222 32 8 42 10C42 12.388 42 19.3278 42 21.7778Z"
                                                    fill="#FF7800" />
                                            </svg>
                                        </span>
                                        <div class="cu-why-choose-card-body">
                                            <h3 class="cu-why-choose-card-title">Expertise and Trust</h3>
                                            <p class="cu-why-choose-card-description">We are a team of experienced professionals
                                                dedicated to post-acute care services. Our network of providers goes through
                                                rigorous screening, ensuring that you receive the highest standard of care.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-12 col-md-6 col-lg-6">
                                    <div class="cu-why-choose-card">
                                        <span class="cu-why-choose-icon">
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M41.6601 22.93C39.3141 35.03 24.0002 42 24.0002 42C24.0002 42 8.68609 35.03 6.33809 22.93C4.89809 15.506 8.04419 8.03799 16.0422 7.99999C22.0002 7.97199 24.0002 13.9759 24.0002 13.9759C24.0002 13.9759 26.0003 7.96999 31.9563 7.99999C39.9723 8.03799 43.1001 15.508 41.6601 22.93Z"
                                                    fill="#FF7800" />
                                            </svg>
                                        </span>
                                        <div class="cu-why-choose-card-body">
                                            <h3 class="cu-why-choose-card-title">Personalized Care</h3>
                                            <p class="cu-why-choose-card-description">We understand that each patient's journey
                                                is different. Our personalized approach ensures that you are matched with a
                                                post-acute care provider who not only meets your medical requirements but also
                                                aligns with your preferences and values.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-12 col-md-6 col-lg-6">
                                    <div class="cu-why-choose-card">
                                        <span class="cu-why-choose-icon">
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M38.9131 21.594C38.7531 21.232 38.3951 21 37.9991 21H28.9991V5.99998C28.9991 5.58598 28.7452 5.21399 28.3572 5.06599C27.9672 4.91599 27.5331 5.02397 27.2551 5.32997L9.25512 25.33C8.99112 25.624 8.92319 26.044 9.08519 26.406C9.24519 26.768 9.60314 27 9.99914 27H18.9991V42C18.9991 42.414 19.2531 42.786 19.6411 42.934C19.7591 42.978 19.8791 43 19.9991 43C20.2771 43 20.5492 42.884 20.7432 42.668L38.7432 22.668C39.0072 22.376 39.0731 21.954 38.9131 21.594Z"
                                                    fill="#FF7800" />
                                            </svg>
                                        </span>
                                        <div class="cu-why-choose-card-body">
                                            <h3 class="cu-why-choose-card-title">Expertise</h3>
                                            <p class="cu-why-choose-card-description">Transitioning from a hospital to a
                                                post-acute care facility can be overwhelming. We streamline the process, making
                                                it hassle-free for you and your family. Our team works closely with both
                                                hospitals and providers to ensure a smooth handoff, so you can focus on your
                                                recovery.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-12 col-md-6 col-lg-6">
                                    <div class="cu-why-choose-card">
                                        <span class="cu-why-choose-icon">
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M31.9564 8.00011C26.0004 7.97211 24.0003 13.9762 24.0003 13.9762C24.0003 13.9762 22.0003 7.97011 16.0423 8.00011C8.04232 8.04011 4.89821 15.5083 6.33821 22.9303C8.68421 35.0303 24.0003 42.0001 24.0003 42.0001C24.0003 42.0001 39.3144 35.0303 41.6624 22.9303C43.1004 15.5083 39.9724 8.04011 31.9564 8.00011ZM34.0003 26.5001H30.8023L27.2484 31.8321C26.9664 32.2521 26.4983 32.5001 26.0003 32.5001C25.9343 32.5001 25.8684 32.496 25.8004 32.486C25.2324 32.41 24.7582 32.0182 24.5763 31.4742L21.5462 22.3839L19.2484 25.8321C18.9704 26.2481 18.5023 26.5001 18.0003 26.5001H14.0003C13.1723 26.5001 12.5003 25.8281 12.5003 25.0001C12.5003 24.1721 13.1723 23.5001 14.0003 23.5001H17.1983L20.7523 18.1681C21.0703 17.6901 21.6343 17.4323 22.2003 17.5143C22.7683 17.5903 23.2424 17.982 23.4244 18.526L26.4544 27.6163L28.7523 24.1681C29.0303 23.7521 29.4983 23.5001 30.0003 23.5001H34.0003C34.8283 23.5001 35.5003 24.1721 35.5003 25.0001C35.5003 25.8281 34.8283 26.5001 34.0003 26.5001Z"
                                                    fill="#FF7800" />
                                            </svg>
                                        </span>
                                        <div class="cu-why-choose-card-body">
                                            <h3 class="cu-why-choose-card-title">Compassionate Support</h3>
                                            <p class="cu-why-choose-card-description">Your well-being is our top priority. Our
                                                dedicated team is available 24/7 to answer your questions, address concerns, and
                                                provide ongoing support throughout your recovery journey. We are here to guide
                                                and assist you every step of the way.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer value={"About Us"} />
        </div>
    )
}

export default AboutUs