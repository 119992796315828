import moment from 'moment'
import React from 'react'
import { Modal } from 'react-bootstrap'

const ReadMoreDescription = ({ activeModal, setActiveModal, text }) => {
    return (
        <Modal
            className="modal fade cu-delete-modal"
            id="deleteModal"
            show={activeModal}
            onHide={setActiveModal}
            centered={true}
            size='lg'
        >
            <Modal.Body>
                <button type="button" className="btn-close" onClick={setActiveModal}></button>
                <div className='row readmore-values'>
                    <div className='col col-md-6'>
                        <span>Patient Name </span><span>:</span>
                    </div>
                    <div className='col col-md-6'>
                        <span>{text?.first_name} {text?.last_name}</span>
                    </div>
                </div>
                <div className='row readmore-values'>
                    <div className='col col-md-6'>
                        <span>Discharge Date </span><span>:</span>
                    </div>
                    <div className='col col-md-6'>
                        <span>{moment(text?.discharge_date).format('MM/DD/YYYY')}</span>
                    </div>
                </div>
                <div className='row readmore-values'>
                    <div className='col col-md-6'>
                        <span>Profile Description </span><span>:</span>
                    </div>
                    <div className='col col-md-6'>
                        <span className='long-text'>{text?.description}</span>
                    </div>
                </div>
                <div className='row readmore-values'>
                    <div className='col col-md-6'>
                        <span>Service Needed </span><span>:</span>
                    </div>
                    <div className='col col-md-6'>
                        <span className='long-text'>{text?.service_need}</span>
                    </div>
                </div >
                <div className='row readmore-values'>
                    <div className='col col-md-6'>
                        <span>Phone Number </span><span>:</span>
                    </div>
                    <div className='col col-md-6'>
                        <span>{text?.phone_no}</span>
                    </div>
                </div >
                <div className='row readmore-values'>
                    <div className='col col-md-6'>
                        <span>Email </span><span>:</span>
                    </div>
                    <div className='col col-md-6'>
                        <span>{text?.email}</span>
                    </div>
                </div >
                <div className='row readmore-values'>
                    <div className='col col-md-6'>
                        <span>Created By </span><span>:</span>
                    </div>
                    <div className='col col-md-6'>
                        <span>{text?.created_by}</span>
                    </div>
                </div>
            </Modal.Body >
        </Modal >
    )
}

export default ReadMoreDescription