import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { CONTIGO_API_URL } from '../BaseURL'
import axios from 'axios'
import { toast, Toaster } from 'react-hot-toast';


const AddNewFacility = () => {

    const [details, setDetails] = useState
        ({ name: "", description: "", image: "" });
    const [error, setError] = useState({});
    const [disable, setDisable] = useState(false);
    const nav = useNavigate();
    const [img, setImg] = useState({
        src: "",
        alt: ""
    });

    useEffect(() => {
        if (localStorage.getItem('CONTIGO_TOKEN_BUSINESS') === null) {
            nav('/login')
        }

        // getWidgets();
        checkSubscription()
    }, [])

    const checkSubscription = () => {

        axios({
            method: 'post',
            url: `${CONTIGO_API_URL}api/nursing-user/check-subscription`,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_BUSINESS')}` }
        }).then((response) => {
            if (response.data.status === true) {

            } else {
                nav('/subscription-plans')
            }
        }).catch((error) => {
            toast.error(error?.response?.data?.message || 'Something went wrong')
            console?.log("Errors", error);
            if (error.response.status === 401) {
                localStorage.removeItem('CONTIGO_TOKEN_BUSINESS');
                nav('/login')
            }
        });
    }

    const location = useLocation();
    useEffect(() => {
        if (location.state) {
            setDetails({ name: location.state.name, description: location.state.description, image: location.state.image })
            setImg({ src: location.state.image, alt: location.state.image.split('uploads/')[1] })
        }
    }, [location.state])
    // const data = location.state
    // console.log(data, "previous data")



    const [imgSize, setImgSize] = useState("")

    // const handleImage = (e) => {
    //     if (e?.target?.files[0]) {
    //         setImg({
    //             src: URL.createObjectURL(e?.target?.files[0]),
    //             alt: e?.target?.files[0].name,
    //         });
    //         setDisable(true);
    //         const myurl = `${CONTIGO_API_URL}api/upload-image`;
    //         var bodyFormData = new FormData();
    //         bodyFormData.append("image", e?.target?.files[0]);

    //         axios({
    //             method: "post",
    //             url: myurl,
    //             data: bodyFormData,
    //         })
    //             .then((result) => {
    //                 console.log("=====", result);

    //                 if (result?.data?.success === true) {
    //                     //console.log('Success:=====',result?.data?.data?.filepath_url);
    //                     setDetails({ ...details, image: result?.data?.data?.url });
    //                     setImg({ alt: result?.data?.data?.filepath_url, src: result?.data?.data?.url })
    //                     var fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
    //                     var fSize = e.target.files[0].size;
    //                     var i = 0; while (fSize > 900) { fSize /= 1024; i++; }

    //                     setImgSize((Math.round(fSize * 100) / 100) + ' ' + fSExt[i]);


    //                     setDisable(false);
    //                 } else {
    //                     console.log("Error:=====");
    //                     // console.log('Error:=====', result?.data);
    //                     setImg({ alt: "", src: "" })
    //                     setDetails({ ...details, image: "" });
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 //console.log('Errors', error);
    //                 setImg({ alt: "", src: "" })
    //                 setDetails({ ...details, image: "" });
    //                 setDisable(false);
    //                 toast.error("Unable to upload image");
    //             });
    //     }
    // };

    const handleImage = (e) => {
        if (e?.target?.files[0]) {
            const allowedFormats = ['image/jpeg', 'image/png'];
            const selectedFile = e.target.files[0];

            if (allowedFormats.includes(selectedFile.type)) {
                setImg({
                    src: URL.createObjectURL(selectedFile),
                    alt: selectedFile.name,
                });
                setDisable(true);
                const myurl = `${CONTIGO_API_URL}api/upload-image`;
                var bodyFormData = new FormData();
                bodyFormData.append("image", selectedFile);

                axios({
                    method: "post",
                    url: myurl,
                    data: bodyFormData,
                })
                    .then((result) => {
                        console.log("=====", result);

                        if (result?.data?.success === true) {
                            setDetails({ ...details, image: result?.data?.data?.url });
                            setImg({ alt: result?.data?.data?.filepath_url, src: result?.data?.data?.url })
                            var fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
                            var fSize = selectedFile.size;
                            var i = 0; while (fSize > 900) { fSize /= 1024; i++; }

                            setImgSize((Math.round(fSize * 100) / 100) + ' ' + fSExt[i]);

                            setDisable(false);
                        } else {
                            console.log("Error:=====");
                            setImg({ alt: "", src: "" })
                            setDetails({ ...details, image: "" });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setImg({ alt: "", src: "" })
                        setDetails({ ...details, image: "" });
                        setDisable(false);
                        toast.error("Unable to upload image");
                    });
            } else {
                toast.error("Invalid file format. Please select a JPEG or PNG image.");
            }
        }
    };


    const handleChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value?.trimStart() })
    }

    const addFacility = (e) => {
        e.preventDefault();
        if (validation()) {
            setDisable(true)
            const { name, description, image } = details;
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('name', details.name);
            bodyFormData.append('description', details.description);
            bodyFormData.append('image', details.image || `${process.env.REACT_APP_API_URL}uploads/87e2b03737bc7e1c90918f33e83273d5.png`);
            bodyFormData.append('id', location?.state?.id ?? '');

            axios({
                method: 'post',
                url: `${CONTIGO_API_URL}api/nursing-user/manage-facility`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_BUSINESS')}` }
            }).then((response) => {
                console.log(response.data, "@@$$")
                setTimeout(() => {
                    nav('/amenities');
                }, 1500);
                toast.success(response?.data?.message)
                setDisable(false);
                setDetails({ name: "", description: "", image: "" });
            }).catch((error) => {
                console.log("error", error);
                toast.error(error?.response?.data?.message)
                setDisable(false)
            })

        }
    }

    const handleChangeWithoutNumber = (e) => {
        const sanitizedValue = e.target.value.replace(/[^a-zA-Z\s]/g, '');
        setDetails({ ...details, [e.target.name]: sanitizedValue?.trimStart() })
    }



    const validation = () => {
        let isValid = true;
        let err = {};


        if (!details.name) {
            isValid = false;
            err['name_err'] = "Please enter the name of facility"
        }

        if (!details.description) {
            isValid = false;
            err['description_err'] = "Please enter the facility description"
        }

        // if (!details.image) {
        //     isValid = false;
        //     err['image_err'] = "Please select the facility image"
        // }


        setError(err);
        return isValid
    }


    return (
        <div className='cu-site cu-dashboard-site'>
            <Header />
            <main className="cu-dashboard-content">
                <Sidebar />
                <div className="cu-dashboard-content-area" data-scrollbar>
                    <div className="cu-dashboard-breadcrumb">
                        <Link to="/facility" className="cu-dashboard-breadcrumb-link">
                            <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.07 1.45621L1 6.88284L7.07 12.3095M18 6.88284H1.17" stroke="black" stroke-width="1.5"
                                    stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </Link>
                        {/* <h2 className="cu-dashboard-breadcrumb-title">Add Facility</h2> */}
                        <h2 className="cu-dashboard-breadcrumb-title">
                            {location.state && Object.keys(location.state).length > 0
                                ? 'Edit Amenities'
                                : 'Add Amenities'}
                        </h2>
                    </div>
                    <div className="cu-add-service-content">
                        <div className="row">
                            <div className="col col-12 col-md-12 col-lg-10 col-xl-6">
                                <div className="cu-inquiry-form-content">
                                    <form className="row">
                                        <div className="col-12">
                                            <label htmlFor="inputNameFacility" className="form-label">Name Of Amenity</label>
                                            <input type="text" className="form-control" id="inputNameFacility" onChange={handleChangeWithoutNumber} name='name' value={details.name} />
                                            <span className='cu-input-error'>{error.name_err}</span>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="inputFacilityDescription" className="form-label">Amenity
                                                Description</label>
                                            <input type="text" className="form-control" id="inputFacilityDescription" name='description' value={details.description} onChange={handleChange} />
                                            <span className='cu-input-error'>{error.description_err}</span>
                                        </div>
                                        <div className="col col-12 col-md-12">
                                            <div className="cu-upload-img-conetnt">
                                                <label htmlFor="inputTiming" className="form-label">Amenity Images</label>
                                                <div className="cu-upload-img__inner">
                                                    <input type="file" className="form-control" onChange={handleImage} />
                                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M22.1799 17.1367L19.0499 9.8267C17.9899 7.3467 16.0399 7.2467 14.7299 9.6067L12.8399 13.0167C11.8799 14.7467 10.0899 14.8967 8.84993 13.3467L8.62993 13.0667C7.33993 11.4467 5.51993 11.6467 4.58993 13.4967L2.86993 16.9467C1.65993 19.3467 3.40993 22.1767 6.08993 22.1767H18.8499C21.4499 22.1767 23.1999 19.5267 22.1799 17.1367ZM7.46993 8.1767C8.26558 8.1767 9.02864 7.86063 9.59125 7.29802C10.1539 6.73541 10.4699 5.97235 10.4699 5.1767C10.4699 4.38105 10.1539 3.61799 9.59125 3.05538C9.02864 2.49277 8.26558 2.1767 7.46993 2.1767C6.67428 2.1767 5.91121 2.49277 5.34861 3.05538C4.786 3.61799 4.46993 4.38105 4.46993 5.1767C4.46993 5.97235 4.786 6.73541 5.34861 7.29802C5.91121 7.86063 6.67428 8.1767 7.46993 8.1767Z"
                                                            stroke="#7D7D7D" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                    <p className="cu-upload-img-label">Drag & Drop or <span>Image</span> to Upload
                                                    </p>
                                                    <p className="cu-upload-img-notes">JPG & PNG Only</p>
                                                </div>
                                                {img?.src ? <div className="cu-upload-img-process-content">
                                                    <div className="cu-upload-img-process-head">
                                                        <div className="cu-upload-image">
                                                            {/* <img src="assets/images/upload-img.png" alt="upload-img"
                                                                className="img-fluid" /> */}
                                                            {/* {!img?.src ? (
                                                                <></>
                                                            ) : (
                                                            )} */}
                                                            <img className="img-round" src={details.image} style={{ width: "auto", borderRadius: "6px" }} alt="upload-img" />
                                                        </div>
                                                        <div className="cu-upload-img-detail">
                                                            {/* <h5 className="cu-upload-img-title">image1001</h5> */}

                                                            {img?.alt > 0 ? (
                                                                <h5 className="cu-upload-img-title">image name</h5>
                                                            ) : (
                                                                <h5 className="cu-upload-img-title">{img?.alt}</h5>
                                                            )}
                                                            <p className="cu-upload-img-text">
                                                                {img?.alt && imgSize ? (
                                                                    <span className="cu-upload-img-size">{imgSize}</span>
                                                                ) : null}
                                                                {/* <span className="cu-upload-img-progress">44%</span> */}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="progress">
                                                        <div className="progress-bar" role="progressbar" style={{ width: "25%" }}
                                                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div> */}
                                                </div>
                                                    : ''}

                                                {/* <span className='cu-input-error'>{error.image_err}</span> */}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="cu-inqury-form-btn">
                                                {/* <button type="button" className="btn btn-info" onClick={addFacility} disabled={disable}>
                                                    {disable ? "Processing..." : "Add Facility"}
                                                </button> */}

                                                {location.state && Object.keys(location.state).length > 0 && (
                                                    <button type="button" className="btn btn-info" onClick={addFacility} disabled={disable}>
                                                        {disable ? "Processing..." : "Edit Amenity"}
                                                    </button>
                                                )}

                                                {!location.state && (
                                                    <button type="button" className="btn btn-info" onClick={addFacility} disabled={disable}>
                                                        {disable ? "Processing..." : "Add Amenity"}
                                                    </button>
                                                )}

                                                <Link to="/facility" className="btn btn-outline-info">Cancel</Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default AddNewFacility